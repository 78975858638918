/* @flow */

import type { TransferHistory } from "shop-state/types";
import React from "react";
import cn from "classnames";
import Spinner from "components/Spinner";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import { formatDate } from "helpers/utils";

import styles from "./styles.scss";

type TableLargeProps = {
  data: $ReadOnlyArray<TransferHistory>,
};

const TableLargeHead = () => {
  const t = useTranslate();

  return (
    <thead>
      <tr>
        <th>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.HISTORY.DATE")}</th>
        <th>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.HISTORY.DISTRIBUTED")}</th>
        <th>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.HISTORY.RECIPIENTS")}</th>
        <th>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.COMMENT")}</th>
      </tr>
    </thead>
  );
};

const TableLarge = ({ data }: TableLargeProps): React$Node => {
  const { formatPoints } = useFormat(false);

  return (
    <div className={styles.tableLarge}>
      <table className={styles.table}>
        <TableLargeHead />
        <tbody>
          {data.map((x, i) => {
            return (
              <tr key={`${i}-${x.date}`}>
                <td>{formatDate(x.date)}</td>
                <td>{formatPoints(x.sumpoints)}</td>
                <td>{x.countmembers}</td>
                <td className={styles.maxWidthChar}>{x.comment}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const TableLargeHint = (): React$Node => (
  <div className={cn(styles.tableHint, styles.tableHintLarge)}>
    <Spinner />
  </div>
);

export default TableLarge;
