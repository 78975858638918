/* @flow */

import React from "react";

import cn from "classnames";
import styles from "./styles.scss";

const AnniversaryBadge = (): React$Node => (
  <div className={cn("awardit-anniversaryBadgeWrapper", styles.anniversaryBadgeWrapper)}>
    <img
      className="awardit-anniversaryBadge"
      src="/assets/icons/ahlsell/20years.svg"
      alt="Ahlsell anniversary badge"
    />
  </div>
);

export default AnniversaryBadge;
