/* @flow */

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import { useData } from "crustate/react";
import Wrapper from "components/Wrapper";
import NewsListItem, { DummyNewsListItem } from "components/NewsListItem";
import { formatTimestampToLocaleDate } from "helpers/locale-utils";
import { NewsPostsData } from "data";
import { stutter } from "helpers/animation";
import ErrorView from "components/ErrorView";
import { Title } from "components/UiComponents";
import HeroIntro from "components/HeroIntro";

import styles from "./styles.scss";
import cn from "classnames";

const NUM_DUMMY_CARDS = 6;

const NewsListView = (): React$Node => {
  const { info, content: { newslistview: content, heroIntroView } } = useContext(StoreInfoContext);
  const dateFormat = formatTimestampToLocaleDate(info.locale);
  const newsPosts = useData(NewsPostsData);
  const _stutter = stutter(100, 100);

  if (newsPosts.state === "ERROR") {
    return <ErrorView />;
  }

  // TODO: add "load more"-button and make use of content.loadMoreText

  return (
    <>
      <Helmet title={content.pageTitle ?? ""} />
      {Boolean(heroIntroView.toggle) &&
        <HeroIntro title={content.heading ?? ""} />
      }
      <Wrapper variant="pageWrapper" className="awardit-newsListView awardit-pageWrapper">
        {Boolean(heroIntroView.toggle) === false &&
          <div className={styles.intro}>
            {content.heading &&
              <Title>{content.heading}</Title>
            }
          </div>
        }
        <div className={cn(styles.items, "awardit-newsListViewItems")}>
          {newsPosts.state === "LOADED" && newsPosts.data.size > 0 && newsPosts.data.items
            .map(x => ({ ...x, publishedAt: dateFormat(x.publishedAt) }))
            .map((x, i) => (
              <div
                key={x.title}
                style={{
                  animationDelay: _stutter(i),
                }}
                className={cn(styles.item, "awardit-newsListViewItem")}
              >
                <NewsListItem key={x.title} item={x} />
              </div>
            ))
          }

          {newsPosts.state === "LOADED" && newsPosts.data.size < 1 && content.noItemsText &&
            <div className={styles.no_news}>
              <h2>{content.noItemsText}</h2>
            </div>
          }

          {newsPosts.state === "LOADING" &&
            [...new Array(NUM_DUMMY_CARDS)].map((x, i) => (
              <div
                key={"dummyNews_" + i}
                style={{ animationDelay: _stutter(i) }}
                className={styles.item}
              >
                <DummyNewsListItem />
              </div>
            ))
          }
        </div>
      </Wrapper>
    </>
  );
};

export default NewsListView;
