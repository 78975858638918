/* @flow */

import type { FilterState } from "@crossroads/ui-components";

import type {
  FilterRange,
  ProductSort,
  ProductSortInput,
  ProductFilter,
  ProductFilterBucket,
  ProductFilterPrice,
  ProductFilterRange,
} from "shop-state/types";

const ALLOWED_SORT_OPTIONS = ["name", "position", "price", "release_date", "relevance"];
const ONLY_DESC = ["position", "release_date", "relevance"];

export const constructSortableValues = (
  values: Array<ProductSort>,
  t: string => string,
  allowedSortOptions: Array<string> = ALLOWED_SORT_OPTIONS,
  onlyDesc: Array<string> = ONLY_DESC
): Array<{| code: string, label: string |}> => {
  return values.reduce((acc, curr) => {
    if (allowedSortOptions.includes(curr.code)) {
      const codeUpperCase = curr.code.toUpperCase();

      if (!onlyDesc.includes(curr.code)) {
        acc.push({
          code: `${curr.code}_asc`,
          label: t(`FILTER.FILTERBAR.SORT_${codeUpperCase}_UP`),
        });
      }

      acc.push({
        code: `${curr.code}_desc`,
        label: t(`FILTER.FILTERBAR.SORT_${codeUpperCase}_DOWN`),
      });
    }

    return acc;
  }, []);
};

export const sortValueToInput = (value: string): ProductSortInput => {
  const [code, order] = value.split("_");

  return {
    code,
    order: order === "asc" ? "ASC" : "DESC",
  };
};

export const normalizeSetRange = (
  minValue: number,
  maxValue: number,
  minDiff: number,
  currentRange: FilterRange,
  setRange: (value: FilterRange) => void
): (
  (v: FilterRange, overrideMinDiff: boolean) => void
) => (v: FilterRange, overrideMinDiff: boolean = false) => { // eslint-disable-line max-params
  if (minValue === maxValue) {
    return;
  }

  const values = {
    min: v.min < 0 ? 0 : v.min,
    max: v.max > maxValue ? maxValue : v.max,
  };

  if (values.min !== currentRange.min) {
    if (values.min <= values.max - (overrideMinDiff ? 1 : minDiff)) {
      setRange(values);
    }
    else {
      setRange({ min: values.max - (overrideMinDiff ? 1 : minDiff), max: values.max });
    }
  }
  else if (values.max >= values.min + (overrideMinDiff ? 1 : minDiff)) {
    setRange(values);
  }
  else {
    setRange({ min: values.min, max: values.min + (overrideMinDiff ? 1 : minDiff) });
  }
};

export const getAvailableFilters = (filters: Array<ProductFilter>): {|
  buckets: Array<ProductFilterBucket>,
  prices: Array<ProductFilterPrice>,
  ranges: Array<ProductFilterRange>,
|} => {
  const buckets: Array<ProductFilterBucket> = filters.reduce((acc, curr) => {
    if (curr.__typename === "ProductFilterBucket") {
      return [...acc, {
        ...curr,
        values: curr.values.sort((a, b) => a.value.localeCompare(b.value)),
      }];
    }

    return acc;
  }, []);

  const prices: Array<ProductFilterPrice> = filters.reduce((acc, curr) => {
    if (curr.__typename === "ProductFilterPrice") {
      return [...acc, curr];
    }

    return acc;
  }, []);

  const ranges: Array<ProductFilterRange> = filters.reduce((acc, curr) => {
    if (curr.__typename === "ProductFilterRange") {
      return [...acc, curr];
    }

    return acc;
  }, []);

  return {
    buckets,
    prices,
    ranges,
  };
};

export const purgeBucketFromFilterState = (code: string, filterState: FilterState): FilterState => {
  return {
    ...filterState,
    filters: {
      ...filterState.filters,
      buckets: filterState.filters.buckets.filter(f => f.code !== code),
    },
  };
};
