/* @flow */

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useData } from "crustate/react";
import { PhotoServiceListData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import { Title, Items, Item } from "components/UiComponents";
import UploadImagesOverlay from "components/UploadedImages/UploadImagesOverlay";
import { DummyCard } from "components/ProductCard";
import Button from "components/Button";

import cn from "classnames";
import styles from "../styles.scss";

const UploadImageSection = (): React$Node => {
  const t = useTranslate();
  const photoServiceListData = useData(PhotoServiceListData);
  const [uploadImgOpen, setUploadImgOpen] = useState(false);

  const {
    routes: { photoServiceView },
    content: { uploadedImagesView: imagesview },
  } = useContext(StoreInfoContext);

  const onSuccess = () => {
    setUploadImgOpen(false);
  };

  if (
    !photoServiceView ||
    photoServiceView.toggle === null ||
    photoServiceView.toggle === undefined ||
    photoServiceView.toggle === false
  ) {
    return null;
  }

  return (
    <div
      key="uploadImageSection"
      className={cn(
        "awardit-homeViewUploadedImages",
        styles.uploadImageSection,
        styles.section
      )}
    >
      <UploadImagesOverlay
        open={uploadImgOpen}
        setOpen={setUploadImgOpen}
        onSuccess={onSuccess}
      />
      <div className={cn("awardit-homeViewUploadedImagesTop", styles.top)}>
        <Title className={styles.title} elem="h2">
          {(photoServiceView) &&
            photoServiceView.title ?
            photoServiceView.title :
            t("IMAGE_UPLOADER.LATEST")}
        </Title>
        <div className={cn("awardit-homeViewUploadedImagesTopCta", styles.uploadImage)}>
          <Link
            to={photoServiceView && photoServiceView.url ? photoServiceView.url : ""}
            className={cn("link", styles.ctaLink)}
          >
            <span>{t("IMAGE_UPLOADER.SHOW_ALL")}</span>
          </Link>
          <Button
            type="button"
            className={styles.btn}
            variant="primary"
            onClick={() => setUploadImgOpen(true)}
          >
            {t("IMAGE_UPLOADER.UPLOAD_IMAGE")}
          </Button>
        </div>
      </div>
      {(imagesview &&
        imagesview.frontpageInfo !== null &&
        imagesview.frontpageInfo !== undefined) &&
        <>
          {/* eslint-disable react/no-danger */}
          <div
            className={styles.imageViewDesc}
            dangerouslySetInnerHTML={{ __html: imagesview.frontpageInfo }}
          />
          {/* eslint-enable react/no-danger */}
        </>
      }
      <Items>
        {(photoServiceListData &&
          photoServiceListData.state === "LOADED" &&
          photoServiceListData.data.length > 0) ?
          photoServiceListData.data.slice(0, 4).map((elem, i) => (elem ?
            <Item key={`${elem.title}-${i}`} className={styles.uploadedImageContainer}>
              <div className={styles.innerImageContainer}>
                <img src={elem.thumb ? elem.thumb : elem.url} />
              </div>
              <p className={styles.imageContainerTitle}>{elem.title}</p>
            </Item> :
            <Item key={i}><DummyCard /></Item>
          )) : <p className={styles.imageContainerTitle}>{t("IMAGE_UPLOADER.EMPTY")}</p>
        }
      </Items>
    </div>
  );
};

export default UploadImageSection;
