/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import SuccessfulIcon from "icons/successful.svg";
import UnsuccessfulIcon from "icons/unsuccessful.svg";
import { StoreInfoContext } from "entrypoint/shared";
import { usePointsLabel, useSpendingLimit } from "helpers/points";

import styles from "./styles.scss";

type Props = {
  className?: string,
  missingPoints: number,
  canAfford: boolean,
  pointsOnlyProductMessage: boolean,
};

const InsufficientFundsBanner =
({ canAfford, className, missingPoints, pointsOnlyProductMessage }: Props): React$Node => {
  const t = useTranslate();
  const label = usePointsLabel();
  const spendingLimit = useSpendingLimit();
  const { formatPoints } = useFormat();
  const { content: { appheader } } = useContext(StoreInfoContext);

  const insufficientFundsBody = pointsOnlyProductMessage ?
    t("CART.INSUFFICIENT_FUNDS_POINTS_ONLY_BODY", { pointsLabel: label() }) :
    t("CART.INSUFFICIENT_FUNDS_BODY", {
      pointsLabel: label(),
      spendingLimit: spendingLimit.minPercent,
    });

  return (
    <div className={cn(className, styles.block, { [styles.warning]: !canAfford })}>
      <div className={styles.top}>
        {canAfford ?
          <SuccessfulIcon className={styles.icon} /> :
          <UnsuccessfulIcon className={styles.icon} />
        }
        {canAfford ?
          <h2>{t("CART.INSUFFICIENT_FUNDS_HEADER_POSITIVE")}</h2> :
          <h2>{appheader.insufficientFundsTitle ? appheader.insufficientFundsTitle : t("CART.INSUFFICIENT_FUNDS_HEADER_NEGATIVE")}</h2>
        }
      </div>
      {!canAfford && (
        <div className={styles.middle}>
          <p>{appheader.insufficientFundsBody ?
            appheader.insufficientFundsBody : insufficientFundsBody}
          </p>
        </div>
      )}
      {!canAfford && missingPoints > 0 &&
        <div className={styles.bottom}>
          <p className={styles.bottomLabel}>{t("CART.INSUFFICIENT_FUNDS_BOTTOM_LABEL", { points: formatPoints(missingPoints) })}</p>
        </div>
      }
    </div>
  );
};

export default InsufficientFundsBanner;
