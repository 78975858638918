
/* @flow */

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import cn from "classnames";

import styles from "./styles.scss";

const Earn = (): React$Node => {
  const { routes, content: { earnview } } = useContext(StoreInfoContext);
  const t = useTranslate();

  return (
    <>
      {routes.earnView?.url && (
        <NavLink
          exact
          to={routes.earnView.url}
          activeClassName={styles.active}
          className={cn("awardit-subMenuShopItem", styles.item)}
        >
          {t("EARN.START")}
        </NavLink>
      )}
      {earnview.cardLinkTitle1 && earnview.cardLinkUrl1 &&
        <NavLink
          to={earnview.cardLinkUrl1}
          activeClassName={styles.active}
          className={cn("awardit-subMenuShopItem", styles.item)}
        >
          {earnview.cardLinkTitle1}
        </NavLink>
      }

      {earnview.cardLinkTitle2 && earnview.cardLinkUrl2 &&
        <NavLink
          to={earnview.cardLinkUrl2}
          activeClassName={styles.active}
          className={cn("awardit-subMenuShopItem", styles.item)}
        >
          {earnview.cardLinkTitle2}
        </NavLink>
      }

      {earnview.cardLinkTitle3 && earnview.cardLinkUrl3 &&
        <NavLink
          to={earnview.cardLinkUrl3}
          activeClassName={styles.active}
          className={cn("awardit-subMenuShopItem", styles.item)}
        >
          {earnview.cardLinkTitle3}
        </NavLink>
      }

      {earnview.cardLinkTitle4 && earnview.cardLinkUrl4 &&
        <NavLink
          to={earnview.cardLinkUrl4}
          activeClassName={styles.active}
          className={cn("awardit-subMenuShopItem", styles.item)}
        >
          {earnview.cardLinkTitle4}
        </NavLink>
      }
    </>
  );
};

export default Earn;
