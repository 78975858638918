/* @flow */

import React, { useState } from "react";

import Field, { CheckboxField } from "components/Field";
import Dropdown from "components/Dropdown";
import { DropdownItem } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

type Props = {
  chk: boolean,
  reqflag: boolean,
  productid: string,
  strname: string | null,
  stname: string | null,
  pricename: string | null,
  numname: string | null,
  options: [string] | null,
  setState: (value: string, str: string) => void,
};

const ReportRow = ({
  reqflag,
  strname,
  productid,
  stname,
  pricename,
  numname,
  chk,
  options,
  setState,
}: Props): React$Node => {
  const [dropdownVal, setDropdownVal] = useState();
  const t = useTranslate();
  const tempOptions = options !== null && options.length > 0 ? options.map(option => {
    return option.toLowerCase();
  }) : [];
  const isImageInput = options !== null && options.length > 0 ? tempOptions.includes("imageinput") : false;

  const getImageString = async (file: File | null, name: string) => {
    const formData = new FormData();

    if (file) {
      formData.append("image", file);

      try {
        const response = await fetch("/UploadImage", {
          method: "POST",
          credentials: "same-origin",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const imageLink = `<a href="${data}">${t("REPORTS.DOCUMENT")}</a>`;

        if (data) {
          setState(imageLink, name);
        }
      }
      catch (e) {
        console.error("An error occurred while uploading the image:", e);
      }
    }
    else {
      setState("", name);
    }
  };

  return (
    <>
      {strname !== null && !options && chk === true && (
        <div className={styles.rowCheckbox}>
          <CheckboxField name={`row-strval-${productid}`} required={reqflag}>
            {strname}
          </CheckboxField>
        </div>
      )}
      {strname !== null &&
      chk === false &&
      options !== null &&
      options.length > 0 &&
      isImageInput === false && (
        <div className={styles.rowSelect}>
          <Dropdown
            label={strname}
            name={strname}
            value={dropdownVal}
            className={styles.dropdown}
            onChange={value => {
              const val = value !== null ? value.toString() : "";
              setDropdownVal(value);
              setState(val, `row-strval-${productid}`);
            }}
          >
            {options.map(item => (
              <DropdownItem key={item} value={item}>
                {item}
              </DropdownItem>
            ))}
          </Dropdown>
        </div>
      )}
      {strname !== null && !options && chk === false && (
        <div className={styles.row}>
          <Field label={strname} name={`row-strval-${productid}`} required={reqflag} />
        </div>
      )}
      {strname !== null && isImageInput === true && chk === false && (
        <div className={styles.fileInput}>
          <label htmlFor={`row-strval-${productid}`}>{strname}</label>
          <input
            required={reqflag}
            type="file"
            name={`row-strval-${productid}`}
            onChange={e => getImageString(e.target.files[0], `row-strval-${productid}`)}
          />
          <div className={styles.bgColor} />
        </div>
      )}
      {stname !== null && (
        <div className={styles.row}>
          <Field label={stname} name={`row-stval-${productid}`} required={reqflag} />
        </div>
      )}
      {pricename !== null && (
        <div className={styles.row}>
          <Field label={pricename} name={`row-priceval-${productid}`} required={reqflag} />
        </div>
      )}
      {numname !== null && (
        <div className={styles.row}>
          <Field label={numname} name={`row-numval-${productid}`} required={reqflag} />
        </div>
      )}
    </>
  );
};

export default ReportRow;
