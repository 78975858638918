/* @flow */

import type { Quote, Customer, QuoteItem, QuoteItemConfigurable } from "shop-state/types";
import { useContext } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";

export type PointsPrice = {
  // currencyRequired: boolean,
  +id: string,
  +label: string,
  +points: {
    +value: { +incVat: number, +exVat: number },
    +min: { +incVat: number, +exVat: number },
    +max: { +incVat: number, +exVat: number },
  },
  +currency: {
    +value: { +incVat: number },
    +min: { +incVat: number },
    +max: { +incVat: number },
  },
};

export type Price = {
  +incVat: number,
  +exVat: number,
  +vat: number,
};

export type SpendingLimit = {
  +minPercent: number,
  +maxPercent: number,
  +incVat: boolean,
};

export const pointsPriceByID = <T: { +id: string }>(
  pointsPrices: $ReadOnlyArray<T>, id: string): T | null => {
  if (!pointsPrices) {
    return null;
  }

  for (const pointPrice of pointsPrices) {
    if (pointPrice.id === id) {
      return pointPrice;
    }
  }

  return null;
};

export const usePointsLabel = (): (uppercase: ?boolean) => string => {
  const t = useTranslate();
  const { info: { pointsTypes } } = useContext(StoreInfoContext);
  const pointsType = pointsPriceByID(pointsTypes, "awardit");
  const pointsLabel = pointsType?.label;

  const label = (uppercase: ?boolean) => {
    if (pointsLabel) {
      return uppercase === true ? pointsLabel.charAt(0).toUpperCase() + pointsLabel.slice(1) :
        pointsLabel;
    }

    return uppercase === true ? `${t("LOCALE.POINTS")}`.charAt(0).toUpperCase() + `${t("LOCALE.POINTS")}`.slice(1) :
      `${t("LOCALE.POINTS")}`;
  };

  return label;
};

export const useSpendingLimit = (): SpendingLimit => {
  const { info: { pointsTypes } } = useContext(StoreInfoContext);
  const pointsType = pointsPriceByID(pointsTypes, "awardit");
  const spendingLimit: SpendingLimit =
  pointsType && pointsType !== undefined &&
  pointsType !== null ? pointsType.orderSpendingLimits : {};

  return spendingLimit;
};

export const isForcedSplitPayment = (points?: ?PointsPrice): boolean => {
  if (!points) {
    return false;
  }

  return points.currency.min.incVat > 0;
};

export const isPointsOnly = (pointsPrice: PointsPrice | null): boolean => {
  if (!pointsPrice) {
    return false;
  }

  // TODO: check pointsRequire === true when API is updated
  const { currency, points } = pointsPrice;

  return currency.max.incVat === 0 && points.min.incVat === points.value.incVat;
};

export const pointsOnlyProductInCart = (
  items: Array<(QuoteItem | QuoteItemConfigurable)>): boolean => {
  if (!items) {
    return false;
  }

  return items.some(item => {
    if (!item.product || !item.product.pointsPrices) {
      return false;
    }

    for (const pointPrice of item.product.pointsPrices) {
      return isPointsOnly(pointPrice);
    }

    return false;
  });
};

export const partnerRestrictionsInCart = (
  items: Array<(QuoteItem | QuoteItemConfigurable)>): boolean => {
  if (!items) {
    return false;
  }

  return items.some(x => Object.keys(x.product.attributes).includes("awarditRestrictionPid") && x.product.attributes.awarditRestrictionPid !== null);
};

export const getSplit = ({ points, price }: { points: number, price: number }): Array<{|
  type: string,
  values: {| currency: number, points: number |},
|}> => {
  return [
    {
      type: "split",
      values: {
        points,
        currency: price,
      },
    },
  ];
};

export const getSubtotal = (quote: Quote): {| currency: number, points: number |} => {
  const subtotalPoints = quote.items.reduce((a, c) => {
    return a + ((pointsPriceByID(c.product.pointsPrices, "awardit")?.points.max.exVat || 0) * c.qty);
  }, 0);
  const subtotalPrice = quote.items.reduce((a, c) => {
    return a + ((c.product.pointsPrices.length === 0 ? c.product.price.incVat : 0) * c.qty);
  }, 0);

  return ({
    points: subtotalPoints,
    currency: subtotalPrice,
  });
};

export const getGrandtotal = (quote: Quote): {| currency: number, points: number |} => {
  const pointPayment = pointsPriceByID(quote.availablePointPayments, "awardit");

  const shippingPoints = pointPayment?.shipping?.points.value.exVat || 0;
  const shippingPrice = pointPayment?.shipping?.currency.value.incVat || 0;

  const subtotalPoints = quote.items.reduce((a, c) => {
    return a + ((pointsPriceByID(c.product.pointsPrices, "awardit")?.points.max.exVat || 0) * c.qty);
  }, 0);
  const subtotalPrice = quote.items.reduce((a, c) => {
    return a + ((c.product.pointsPrices.length === 0 ? c.product.price.incVat : 0) * c.qty);
  }, 0);

  if (subtotalPoints === 0) {
    return ({
      points: subtotalPoints,
      currency: subtotalPrice + shippingPrice,
    });
  }

  return ({
    points: subtotalPoints + shippingPoints,
    currency: subtotalPrice,
  });
};

export const getCustomerActiveBalance = (customer: Customer): number => {
  const {
    ownPoints,
    earnedPoints,
    minThreshold,
    waitingPoints,
    groupPoints,
    activePoints,
  } = customer.awardit;

  if (earnedPoints !== undefined && earnedPoints !== null &&
      minThreshold !== undefined && minThreshold !== null) {
    if ((earnedPoints < minThreshold) && minThreshold > 0) {
      return waitingPoints ?? 0;
    }
  }

  return (
    (activePoints === 0 && ownPoints === 0 && groupPoints > 0) ? groupPoints : activePoints
  );
};

export const getPriceSplit = (pointPrices?: ?PointsPrice, price?: ?Price): ?Array<{|
  type: string,
  label?: string,
  values: {| currency?: number, points?: number |},
|}> => {
  const split = [];

  if (!pointPrices && !price) {
    return null;
  }

  if (!pointPrices) {
    split.push({
      type: "currency",
      values: {
        currency: price?.incVat || 0,
      },
    });

    return split;
  }

  const { points, currency } = pointPrices;

  if (points.max.exVat >= points.value.exVat && points.value.exVat > 0) {
    split.push({
      type: "points",
      label: pointPrices.label,
      values: {
        points: points.value.exVat,
      },
    });
  }

  if (currency.max.incVat === currency.value.incVat && currency.max.incVat > 0) {
    split.push({
      type: "currency",
      values: {
        currency: currency.max.incVat,
      },
    });
  }

  return split;
};
