/* @flow */

import React from "react";
import cn from "classnames";

import styles from "./styles.scss";

type InputProps = React$ElementProps<"input"> & {
  labelClassName?: string,
  label?: string,
  id?: string,
  value: ?string,
};

const TextArea = ({
  id = null,
  className,
  labelClassName,
  name,
  label,
  errors,
  value,
  ...props
}: InputProps): React$Node => (
  <div className={styles.block}>
    {label &&
      <label id={id} className={cn(styles.label, labelClassName)}>{label}</label>
    }

    <textarea
      {...props}
      value={value}
      id={id}
      className={cn(styles.input, className)}
      name={name}
      rows="4"
    />
  </div>
);

export default TextArea;
