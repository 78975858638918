/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import Wrapper from "components/Wrapper";
import LoginForm from "components/LoginForm";
import Button from "components/Button";
import { useErrorParams } from "helpers/use-error-params";

import styles from "./styles.scss";

const LoginView = (): React$Node => {
  const { content: { loginview } } = useContext(StoreInfoContext);
  useErrorParams({ "bad_loginkey": "errorInvalidLogin" });

  return (
    <div className={cn(styles.block, "awardit-loginView")}>
      <Helmet title={loginview.pageTitle ?? ""} />
      <Wrapper className={styles.wrapper}>
        <div className={styles.container}>
          <div className={cn(styles.container__text, "awardit-loginViewText")}>
            <h1>{loginview.heading ?? ""}</h1>
            {/* eslint-disable react/no-danger */}
            {loginview.description &&
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: loginview.description }}
              />
            }
            {/* eslint-enable react/no-danger */}
            {loginview.createAccountButtonUrl && loginview.createAccountButtonText &&
            <>
              <h2 className={styles.title}>{loginview.createAccountHeading ?? ""}</h2>
              {/* eslint-disable react/no-danger */}
              {loginview.createAccountText &&
                <p
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: loginview.createAccountText }}
                />
              }
              {/* eslint-enable react/no-danger */}
              <a
                href={loginview.createAccountButtonUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="primary"
                  size="medium"
                >
                  {loginview.createAccountButtonText}
                </Button>
              </a>
              {loginview.createAccountButtonUrlTwo && loginview.createAccountButtonTextTwo &&
              <>
                {/* eslint-disable react/no-danger */}
                {loginview.createAccountTextTwo &&
                  <p
                    className={cn(styles.margin, styles.description)}
                    dangerouslySetInnerHTML={{ __html: loginview.createAccountTextTwo }}
                  />
                }
                {/* eslint-enable react/no-danger */}
                <a
                  href={loginview.createAccountButtonUrlTwo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    variant="primary"
                    size="medium"
                  >
                    {loginview.createAccountButtonTextTwo}
                  </Button>
                </a>
              </>
              }
            </>
            }
          </div>
          <div className={styles.container__login}>
            <LoginForm className={styles.login_form} />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default LoginView;
