/* @flow */

import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import CartIcon from "icons/cart.svg";

import styles from "./styles.scss";

type Props = {
  className: string,
  isOpen: boolean,
  openMiniCart: () => void,
  children?: React$Node,
};

const CartCounter = ({ className = "", isOpen, openMiniCart, children }: Props): React$Node => {
  const { content: { appheader: content } } = useContext(StoreInfoContext);
  const data = useData(QuoteData);
  const quote = data.state === "LOADED" ? data.data : {};
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    if (data.state !== "LOADED") {
      return;
    }

    setCartCount(quote.items.reduce((a, { qty }) => {
      a += qty;
      return a;
    }, 0));
  }, [data, quote]);

  if (cartCount > 0) {
    return (
      <span
        className={cn(
          styles.block,
          { [styles.active]: isOpen },
          className
        )}
        onClick={() => openMiniCart()}
      >
        <div className={styles.iconWrapper}>
          <CartIcon />
        </div>

        {content.cartButtonText &&
          <span className={styles.title}>{content.cartButtonText} ({cartCount})</span>
        }

        {children}
      </span>
    );
  }

  return (
    <div className={cn(styles.block, className)}>
      <div className={styles.iconWrapper}>
        <CartIcon />
      </div>

      {content.emptyCartText &&
        <span className={styles.title}>{content.emptyCartText}</span>
      }
    </div>
  );
};

export default CartCounter;
