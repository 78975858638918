/* @flow */

import React from "react";
import Wrapper from "components/Wrapper";
import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  children: React$Node,
  className?: string,
};

const Banner = ({ children, className }: Props): React$Node => (
  <div className={cn(styles.banner, className, "awardit-banner")}>
    <Wrapper className={styles.wrapper}>
      {children}
    </Wrapper>
  </div>
);

export default Banner;
