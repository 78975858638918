/* @flow */

import type { OptionProduct } from "shop-state/types";
import React from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import Button from "components/Button";
import { isValidHex } from "helpers/utils";

import styles from "./styles.scss";

export type ColorSelectProps = {
  items: Array<{
    key?: string,
    buyRequest: ?string,
    product: OptionProduct,
    label: string,
    price?: {
      incVat: number,
    },
  }>,
  value: ?string,
  onChange: (o: string) => void,
};

const formatConfigColors = rawColor => {
  const colors = rawColor ? rawColor.trim().split("-") : [];

  return colors.every(isValidHex) ? `#${colors.join(", #")}` : "#eee";
};

export const ColorSelect = ({ items, value, onChange }: ColorSelectProps): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.cardValue}>
      {items.map(item => (
        <div key={item.buyRequest} className={styles.col}>
          <Button
            className={cn(
              styles.button,
              { [styles.active]: value === item.buyRequest },
              { [styles.outOfStock]: item.buyRequest === null }
            )}
            value={item.buyRequest}
            disabled={item.buyRequest === null}
            onClick={e => {
              e.preventDefault();
              if (item.buyRequest) {
                onChange(item.buyRequest);
              }
            }}
          >
            <div className={styles.shadow} />

            <div
              className={styles.color}
              style={{ background: `linear-gradient(${formatConfigColors(item.product.attrLabels.color?.title || "")})` }} />
            {item.label}

            {item.buyRequest === null &&
              <div className={styles.outOfStockLabel}>
                {t("PRODUCT.OUT_OF_STOCK")}
              </div>
            }
          </Button>
        </div>
      ))}
    </div>
  );
};
