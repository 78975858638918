/* @flow */

import type { MemberTransactionData, GroupMember } from "shop-state/types";

import React, { useEffect, useState } from "react";
import { useModal } from "@crossroads/ui-components";
import { useSendMessage } from "crustate/react";
import { addMessage } from "state/messages";
import Button from "components/Button";
import TableLarge from "components/MemberGroupView/MembersSettings/Transactions/TableLarge";
import TableSmall from "components/MemberGroupView/MembersSettings/Transactions/TableSmall";
import Receipt from "components/AccountView/TransactionsView/Receipt";
import { getMemberTransactions } from "queries";
import { useClient } from "entrypoint/shared";
import { timestampToDateString } from "helpers/locale-utils";
import { useTranslate } from "@awardit/react-use-translate";
import CloseIcon from "icons/close-small.svg";

import styles from "./styles.scss";

type Props = {
  selectedMember: string,
  memberGroupList: Array<GroupMember>,
  isOpen: boolean,
  setOpen: (open: boolean) => void,
};

const Transactions = ({ selectedMember, memberGroupList, isOpen, setOpen }: Props): React$Node => {
  const client = useClient();
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const [transactionData, setTransactionData] = useState<MemberTransactionData | void>();
  const [currentTransaction, setCurrentTransaction] = useState<string | null>(null);
  const [currentReceipt, setCurrentReceipt] = useState<string | null>(null);
  const getTransactionData = async () => {
    await client(getMemberTransactions, { memberUserId: selectedMember, pageSize: 7 })
      .then(({ getMemberTransactions }) => {
        if (getMemberTransactions.result === "SUCCESS" &&
        (getMemberTransactions.data !== undefined || getMemberTransactions.data !== null)) {
          setTransactionData(getMemberTransactions.data);
        }
        else {
          sendMessage(addMessage("FALLBACK", "error"));
          setOpen(false);
        }
      }).catch(() => {
        sendMessage(addMessage("FALLBACK", "error"));
        setOpen(false);
      });
  };

  useEffect(() => {
    getTransactionData();
  }, [client]);

  const { Modal } = useModal(isOpen, setOpen);

  const memberName = () => {
    const member = memberGroupList.find(x => x.memberuserid === selectedMember);

    if (member !== undefined) {
      return `${member.firstname} ${member.lastname}`;
    }

    return "";
  };

  return (
    <>
      <Receipt
        currentReceipt={currentReceipt}
        setCurrentReceipt={setCurrentReceipt}
      />
      <Modal>
        <div className={styles.wrapper}>
          {transactionData !== undefined &&
            <div className={styles.content}>
              <div className={styles.header}>
                <h1 className={styles.title}>
                  {t("ACCOUNT.MEMBER_GROUP.TRANSACTIONS.HEADER")} -&nbsp;
                  <span className={styles.fullname}>{memberName()}</span>
                </h1>
                <Button className={styles.closeBtn} onClick={() => setOpen(false)}>
                  <CloseIcon />
                </Button>
              </div>
              <div className={styles.body}>
                {transactionData.transactions.length > 0 ?
                  <>
                    <TableSmall
                      items={transactionData}
                      current={currentTransaction}
                      onClick={x => {
                        setCurrentTransaction(currentTransaction !== x.id ? x.id : null);
                      }}
                    />
                    <TableLarge data={transactionData} />
                  </> :
                  <div className={styles.noTransactions}>
                    <h2>{t("ACCOUNT.MEMBER_GROUP.TRANSACTIONS.NO_TRANSACTIONS")}</h2>
                  </div>
                }
                <div className={styles.bottom}>
                  <p><span>{t("ACCOUNT.MEMBER_GROUP.TRANSACTIONS.REGISTRATION_DATE")}</span> {timestampToDateString(transactionData.summary.registrationDate)}</p>
                  <p><span>{t("ACCOUNT.MEMBER_GROUP.TRANSACTIONS.ACTIVATION_DATE")}</span> {timestampToDateString(transactionData.summary.activationDate)}</p>
                  <p><span>{t("ACCOUNT.MEMBER_GROUP.TRANSACTIONS.CURRENT_BALANCE")}</span> {transactionData.summary.balance}</p>
                  <p>
                    <span>
                      {t("ACCOUNT.MEMBER_GROUP.TRANSACTIONS.EXPIRING_POINTS", { date: timestampToDateString(transactionData.summary.expiringPointsDate) })}
                    </span> {transactionData.summary.expiringPoints}
                  </p>
                  <p><span>{t("ACCOUNT.MEMBER_GROUP.TRANSACTIONS.ACCUMULATED_BALANCE")}</span> {transactionData.summary.accumulatedPoints}</p>
                </div>
              </div>
            </div>
          }
          <div
            className={styles.overlay}
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default Transactions;
