/* @flow */

import React from "react";
import cn from "classnames";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";

type Props = {
  className?: string,
  subTitle?: ?string,
  title?: ?string,
  content?: ?string,
  image?: ?string | null,
};

/* eslint-disable react/no-danger */

const Hero = ({ className, subTitle, title = "", content = "", image, ...props }: Props): React$Node => {
  const defaultBG = "https://i.picsum.photos/id/122/1920/1080.jpg";
  const bg = image !== undefined ? image : defaultBG;

  return (
    <div
      {...props}
      className={cn(styles.block, className)}
      style={{
        backgroundImage: `url(${bg || ""})`,
      }}
    >
      <img className={styles.mobileBG} src={bg} />

      <Wrapper className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.titles}>
            {subTitle &&
              <h2 className={styles.subTitle}>{subTitle}</h2>
            }
            <h1 className={styles.title}>{title}</h1>
          </div>
          {content &&
            <div className="typography" dangerouslySetInnerHTML={{ __html: content }} />
          }
        </div>
      </Wrapper>
    </div>
  );
};

/* eslint-enable react/no-danger */

export default Hero;
