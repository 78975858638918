/* @flow */

import type { CmsPage } from "shop-state/types";

import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import ArrowIcon from "icons/arrow.svg";
import { useGetCmsMeta } from "helpers/get-meta";

import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  cmsPage: CmsPage,
  isLoggedIn?: boolean,
};

const CmsPageView = ({ cmsPage, isLoggedIn = true }: Props): React$Node => {
  const meta = useGetCmsMeta(cmsPage);
  const isNotLoggedIn = isLoggedIn !== null && isLoggedIn !== undefined && !isLoggedIn;

  return (
    <Wrapper
      variant="pageWrapper"
      className={cn(
        styles.wrapper,
        { [styles.loginWrapper]: isNotLoggedIn },
        "awardit-pageWrapper",
        "awardit-cmsPageViewAlt"
      )}
    >
      <Helmet
        title={meta.title}
        meta={meta.data}
        link={meta.link}
      />

      {isNotLoggedIn &&
        <div className={styles.header}>
          <Link className={styles.link} to="/login">
            <ArrowIcon />
          </Link>
        </div>
      }

      <h1>{cmsPage.title}</h1>
      {/* eslint-disable react/no-danger */}
      <div
        key={cmsPage.url}
        className={cn("cms", "typography")}
        dangerouslySetInnerHTML={{ __html: cmsPage.content }}
      />
      {/* eslint-enable react/no-danger */}
    </Wrapper>
  );
};

export default CmsPageView;
