/* @flow */

import type { FormData } from "@awardit/formaggio/src/types";

import React, { useEffect, useState, useContext } from "react";
import cn from "classnames";
import { useData, useSendMessage } from "crustate/react";
import {
  TeampotMembersData,
  TeampotSettingsData,
  TeampotStatusData,
  TeampotActivitiesData,
} from "data";
import {
  getTeampotMembers,
  getTeampotHistory,
  getTeampotSettings,
  getTeampotStatus,
} from "state/teampot";
import { postTeampotData } from "queries";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { addMessage } from "state/messages";

import { Form } from "@awardit/formaggio";
import { Checkbox, Button, Dropdown, DropdownItem } from "@crossroads/ui-components";
import Wrapper from "components/Wrapper";
import Field from "components/Field";
import History from "components/TeamPotView/History";

import { useTranslate } from "@awardit/react-use-translate";
import { usePointsLabel } from "helpers/points";
import useFormat from "helpers/use-format";

import styles from "./styles.scss";
type userType = {
  id: string,
  name: string,
};

const TeamPotView = (): React$Node => {
  const t = useTranslate();
  const label = usePointsLabel();
  const { formatPoints } = useFormat(false);
  const client = useClient();
  const sendMessage = useSendMessage();

  const teampotMembersData = useData(TeampotMembersData);
  const teampotSettingsData = useData(TeampotSettingsData);
  const teampotStatusData = useData(TeampotStatusData);
  const teampotActivitiesData = useData(TeampotActivitiesData);
  const { content: { teampotView } } = useContext(StoreInfoContext);

  const showpoints = teampotSettingsData.state === "LOADED" &&
    teampotSettingsData.data.showpoints ?
    teampotSettingsData.data.showpoints : null;
  const teampotMember = teampotMembersData.state === "LOADED" &&
    teampotMembersData.data ?
    teampotMembersData.data : [];

  const [formState, setFormState] = useState<FormData>({});
  const [selectedUsers, setSelectedUsersIDs] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [sharedPoints, setSharedPoints] = useState(0);
  const [totalSharedPoints, setTotalSharedPoints] = useState(0);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setTotalSharedPoints(sharedPoints * selectedUsers.length);
    setAllChecked(teampotMember.length > 0 && selectedUsers.length === teampotMember.length);
  }, [selectedUsers, formState]);

  const onSubmit = (e, val) => {
    e.preventDefault();

    if (!val.option) {
      sendMessage(addMessage("TEAMPOT_OPTION_ERROR", "error"));
      return;
    }

    if ((showpoints === "1" || showpoints === "3") && !val.points) {
      sendMessage(addMessage("TEAMPOT_POINTS_ERROR", "error"));
      return;
    }

    if ((showpoints === "2" || showpoints === "3") && !val.rank) {
      sendMessage(addMessage("TEAMPOT_RANK_ERROR", "error"));
      return;
    }

    const data = {
      members: selectedUsers,
      points: val.points ? parseInt(val.points, 10) : 0,
      rankpts: val.rank ? parseInt(val.rank, 10) : 0,
      option: val.option.toString(),
      commentar: val.comment ? val.comment.toString() : "",
    };

    postTeampotConfig(data);
  };

  const postTeampotConfig = async data => {
    setIsLoading(true);
    const response = await client(postTeampotData, { form: data });

    if (response.postTeampotData.result.includes("ERROR")) {
      sendMessage(addMessage({
        translationKey: "TEAMPOT_ERROR",
        variable: {
          pointsLabel: label(),
        },
      },
      "error"));
    }
    else {
      sendMessage(addMessage({
        translationKey: "TEAMPOT_SUCCESS",
        variable: {
          pointsLabel: label(),
        },
      },
      "success"));
      setFormState({});
      setTotalSharedPoints(0);
      setSharedPoints(0);
      setSelectedUsersIDs([]);
      await sendMessage(getTeampotMembers());
      await sendMessage(getTeampotHistory());
      await sendMessage(getTeampotSettings());
      await sendMessage(getTeampotStatus());
      setIsLoading(false);
    }
  };

  const timePeriodPassed = (): boolean => {
    if (teampotStatusData.state === "LOADED" &&
    teampotStatusData &&
    teampotStatusData.data.validto &&
    teampotStatusData.data.validfrom) {
      const currentDate = new Date();
      const validToDate = new Date(teampotStatusData.data.validto);
      const validFromDate = new Date(teampotStatusData.data.validfrom);

      if (currentDate.getTime() >= validToDate.getTime() ||
      currentDate.getTime() <= validFromDate.getTime()) {
        return true;
      }
    }

    return false;
  };

  const checkAllUsers = e => {
    setAllChecked(!allChecked);

    const usersIDs = teampotMember.map(user => user.memberuserid);

    setSelectedUsersIDs(e.target.checked ? [...usersIDs] : []);
  };

  const User = ({ id, name }: userType) => {
    const userInList = selectedUsers.includes(id);
    const [checked, setChecked] = useState(userInList);

    const onChange = e => {
      setChecked(!checked);
      const selected = e.target.checked;

      if (selected && !userInList) {
        setSelectedUsersIDs(prevState => ([...prevState, id]));
      }

      if (!selected && userInList) {
        const newList = selectedUsers.filter(user => user !== id);
        setSelectedUsersIDs(newList);
      }
    };

    return (
      <div className={styles.checklistItem}>
        <Checkbox
          indeterminate={checked.toString()}
          className={styles.checkboxName}
          value={checked}
          name={name}
          onChange={e => onChange(e)}
        >
          {name}
        </Checkbox>
      </div>
    );
  };

  return (
    <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
      <div className={styles.header}>
        <h2>{t("MOTIVATION_POT.MOTIVATION_POT_TITLE")}</h2>
        {/* eslint-disable react/no-danger */}
        {teampotView.introDescription ?
          <div
            dangerouslySetInnerHTML={{ __html: teampotView.introDescription }}
          /> : <p>{t("MOTIVATION_POT.SUB_TITLE", { pointsLabel: label() })}</p>
        }
        {/* eslint-enable react/no-danger */}
      </div>

      <h3 className={styles.subHeader}>{t("MOTIVATION_POT.USERS_HEADER", { pointsLabel: label() })}</h3>
      <div className={styles.wrapper}>
        <Form
          className={styles.formblock}
          value={formState}
          onChange={x => {
            setFormState({ ...formState, ...x });
            setSharedPoints(isNaN(parseInt(x.points, 10)) ?
              0 : parseInt(x.points, 10));
          }}
          onSubmit={onSubmit}
        >
          {teampotMembersData.state === "LOADED" &&
          teampotStatusData &&
          teampotStatusData.state === "LOADED" &&
          teampotActivitiesData.state === "LOADED" ?
            <>
              <Dropdown
                className={styles.selector}
                name="option"
                value={formState.option ? formState.option.toString() : ""}
                placeholder={t("MOTIVATION_POT.CHOOSE_AVTIVITY")}
                onChange={option => {
                  const optn = option !== null ? option.toString() : "";
                  setFormState({ ...formState, option: optn });
                }}
              >
                {teampotActivitiesData.data.map(elem => (
                  <DropdownItem key={`from_${elem.id}`} value={elem.id}>
                    {elem.altname}
                  </DropdownItem>
                ))}
              </Dropdown>

              <div className={styles.checklistItemAll}>
                <Checkbox
                  value={allChecked}
                  name="All"
                  onChange={e => checkAllUsers(e)}
                >
                  {t("MOTIVATION_POT.CHOOSE_ALL")}
                </Checkbox>
              </div>

              <div className={styles.checklist}>
                {teampotMember.map(user => (
                  <User
                    key={user.memberuserid}
                    id={user.memberuserid}
                    name={user.mbrname}
                  />
                ))}
              </div>

              {showpoints === "1" || showpoints === "3" ?
                <div className={styles.inputWrapper}>
                  <Field
                    label={t("MOTIVATION_POT.POINTS_OF_CHOSEN", { userAmount: selectedUsers.length, pointsLabel: label(true) })}
                    type="number"
                    name="points"
                    min="1"
                    className={styles.input}
                  />
                </div> :
                null
              }
              {showpoints === "2" || showpoints === "3" ?
                <div className={styles.inputWrapper}>
                  <Field
                    label={t("MOTIVATION_POT.RANK_OF_CHOSEN", { userAmount: selectedUsers.length })}
                    type="number"
                    name="rank"
                    className={styles.input}
                  />
                </div> :
                null
              }

              <Field
                label={t("MOTIVATION_POT.COMMENT")}
                name="comment"
                className={styles.input} />
              <Button
                variant="primary"
                size="small"
                type="submit"
                disabled={
                  !formState.option ||
                  selectedUsers.length <= 0 ||
                  sharedPoints <= 0 ||
                  parseInt(teampotStatusData.data.points, 10) - totalSharedPoints < 0 ||
                  timePeriodPassed()
                }
                loading={loading}
              >
                {t("COMMON.SEND")}
              </Button>
            </> :
            null
          }
        </Form>
        <aside className={styles.sidebar}>
          <div className={styles.sidebarWrapper}>
            <div className={styles.pointlist}>
              {teampotStatusData &&
              teampotStatusData.state === "LOADED" &&
              teampotStatusData.data ?
                <>
                  <div className={styles.pointlist__item}>
                    <span>{t("MOTIVATION_POT.POINTS_AVAILABLE", { pointsLabel: label() })}</span>
                    <span>{formatPoints(parseInt(teampotStatusData.data.points, 10))}</span>
                  </div>
                  <div className={styles.pointlist__item}>
                    <span>{t("MOTIVATION_POT.POINTS_HANDED_OUT", { pointsLabel: label(true) })}</span>
                    <span>{formatPoints(totalSharedPoints)}</span>
                  </div>
                  <div className={cn(styles.pointlist__item,
                    parseInt(teampotStatusData.data.points, 10) - totalSharedPoints < 0 ? styles.pointlist__itemError : ""
                  )}
                  >
                    <span>{t("MOTIVATION_POT.POINTS_LEFT_IN_POT", { pointsLabel: label(true) })}</span>
                    <span>{formatPoints(parseInt(teampotStatusData.data.points, 10) -
                    totalSharedPoints)}
                    </span>
                  </div>
                </> :
                null
              }
            </div>
            <h3 className={styles.sidebarHeader}>{t("MOTIVATION_POT.POINTS_HEADER", { pointsLabel: label(true) })}</h3>
            <div className={styles.pointpot}>
              {teampotStatusData &&
              teampotStatusData.state === "LOADED" &&
              teampotStatusData.data ?
                <>
                  {teampotStatusData.data.validfrom && teampotStatusData.data.validto &&
                    <div className={styles.pointlist__item}>
                      <span>{t("MOTIVATION_POT.PERIOD")}</span>
                      <span>{t("MOTIVATION_POT.VALIDATION_DATE", {
                        fromDate: teampotStatusData.data.validfrom?.slice(0, 10),
                        toDate: teampotStatusData.data.validto?.slice(0, 10),
                      })}
                      </span>
                    </div>
                  }
                  <div className={styles.pointlist__item}>
                    <span>{t("MOTIVATION_POT.TOTAL_POINTS", { pointsLabel: label(true) })}</span>
                    <span>{formatPoints(parseInt(teampotStatusData.data.startpoints, 10))}</span>
                  </div>
                  <div className={styles.pointlist__item}>
                    <span>{t("MOTIVATION_POT.POINTS_LEFT", { pointsLabel: label(true) })}</span>
                    <span>{formatPoints(parseInt(teampotStatusData.data.points, 10))}</span>
                  </div>
                </> :
                null
              }
            </div>
          </div>
        </aside>
        <History />
      </div>
    </Wrapper>
  );
};

export default TeamPotView;
