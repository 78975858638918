/* @flow */

import React from "react";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import { useHistory } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";
import cn from "classnames";

const UnsubscribeStatus = (): React$Node => {
  const t = useTranslate();
  return (
    <>
      <h2 className={styles.heading}>{t("NEWSLETTER_STATUS.UNREGISTER.HEADING")}</h2>
      <p>
        <span>{t("NEWSLETTER_STATUS.UNREGISTER.INFO_1")}</span>
        <br />
        <span>{t("NEWSLETTER_STATUS.UNREGISTER.INFO_2")}</span>
      </p>
      <Button
        variant="link"
        className={cn(styles.link, "link")}
        to={t("NEWSLETTER_STATUS.UNREGISTER.SUPPORT_LINK_URL")}
      >
        {t("NEWSLETTER_STATUS.UNREGISTER.SUPPORT_LINK_TITLE")}
      </Button>
      <p>{t("NEWSLETTER_STATUS.UNREGISTER.INFO_3")}</p>
    </>
  );
};

const NewsletterStatusView = ({ status }: { status: ?string }): React$Node => {
  const history = useHistory();
  if (!status || status !== "unsubscribe-success") {
    history.push("/");
    return null;
  }

  return (
    <Wrapper
      variant="pageWrapper"
      className={cn(styles.wrapper, "awardit-newsletterStatusView")}
    >
      <div className={styles.content}>
        {status && status === "unsubscribe-success" && (
          <UnsubscribeStatus />
        )}
      </div>
    </Wrapper>
  );
};

export default NewsletterStatusView;
