/* @flow */

import type { DistrictList } from "shop-state/types";
import type { Model } from "crustate";
import type { Response } from "./util";

import { updateData, EFFECT_ERROR } from "crustate";

type DistrictListData =
| {| state: "LOADING", data: ?DistrictList |}
| {| state: "LOADED", data: DistrictList |}
| {| state: "ERROR", error: string |};

export type DistrictListResponse = Response<typeof DISTRICT_LIST_RESPONSE, DistrictList>;
export type DistrictListRequest = {
  tag: typeof DISTRICT_LIST_REQUEST,
  partner: number | null,
};

export const DISTRICT_LIST_RESPONSE: "response/district_list" = "response/district_list";
export const DISTRICT_LIST_REQUEST: "request/district_list" = "request/district_list";

export const getDistrictList = (partner: number | null): DistrictListRequest => {
  return ({ tag: DISTRICT_LIST_REQUEST, partner });
};

export const DistrictListModel: Model<
  DistrictListData,
  { partner: number },
  DistrictListResponse | DistrictListRequest
> = {
  id: "district_list",
  init: ({ partner }) =>
    updateData(
      { state: "LOADING", data: null },
      getDistrictList(partner)
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });
      case DISTRICT_LIST_REQUEST:
        return updateData({ state: "LOADING", data: state.state !== "ERROR" ? state.data : null });
      case DISTRICT_LIST_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};
