/* @flow */

import type { Mode } from "state/view-mode";

import React, { useContext } from "react";
import { Route, Switch, useLocation } from "react-router";
import { StoreInfoContext } from "entrypoint/shared";
import { CartMini } from "@crossroads/ui-components";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import Questionnaires from "components/AppHeader/Large/SubMenu/questionnaires";
import Checkout from "components/AppHeader/Large/SubMenu/checkout";
import Shop from "components/AppHeader/Large/SubMenu/shop";
import Earn from "components/AppHeader/Large/SubMenu/earn";
import CartCounter from "components/AppHeader/CartCounter";
import cn from "classnames";
import { MODE } from "state/view-mode";
import useOnFullMenuHiddenRoute from "helpers/use-on-full-menu-hidden-route";
import styles from "./styles.scss";

type Props = {
  mode: Mode,
  setMode: Mode => void,
};

export const useHideSubMenu = (): boolean => {
  const { pathname } = useLocation();
  const onFullMenuHiddenRoute = useOnFullMenuHiddenRoute();

  return pathname === "/" || pathname.includes("index.html") || onFullMenuHiddenRoute;
};

const SubMenu = ({ mode, setMode }: Props): React$Node => {
  const { routes } = useContext(StoreInfoContext);
  const { pathname } = useLocation();
  const hideSubMenu = useHideSubMenu();
  const quoteData = useData(QuoteData);
  const questionnaireViewUrl = routes.questionnaireView && routes.questionnaireView.url ? routes.questionnaireView.url : "";

  const QUESTIONNAIRE_ROUTES = [
    questionnaireViewUrl,
    questionnaireViewUrl + "/:slug",
  ];

  const CHECKOUT_ROUTES = [
    routes.checkoutView?.url,
    routes.checkoutSuccessView?.url,
  ].filter(Boolean);

  const EARN_ROUTES = [
    routes.earnView?.url,
  ].filter(Boolean);

  if (hideSubMenu) {
    return null;
  }

  return (
    <div className={cn(
      "awardit-subMenuContainer",
      styles.block,
      { [styles.onCheckout]: pathname.includes("/checkout/") },
      { [cn("awardit-subMenuContainerEarnView")]: routes.earnViewSubpage?.url || routes.earnViewSubpage2?.url }
    )}
    >
      <Switch>
        <Route path={CHECKOUT_ROUTES} component={Checkout} />
        <Route path={QUESTIONNAIRE_ROUTES} component={Questionnaires} />
        <Route path={EARN_ROUTES} component={Earn} />
        <Route
          path="/(.+)"
          render={props => (
            <Shop {...props} mode={mode} setMode={setMode} />
          )}
        />
      </Switch>

      {!pathname.includes("/checkout/") && (
        <CartCounter
          className={cn("awardit-subMenuCartCounter", styles.item, styles.item__cart, { [styles.active]: mode === MODE.CART })}
          isOpen={mode === MODE.CART}
          openMiniCart={() =>
            setMode(MODE.CART)
          }
        />
      )}
      {!pathname.includes("/checkout/") && mode === MODE.CART &&
      routes.checkoutView && routes.checkoutView.url &&
        <CartMini
          quoteData={quoteData}
          variant="b2b"
          altUrl={`${routes.checkoutView.url}/cart`}
          ctaUrl={`${routes.checkoutView.url}/shipping`}
          className={cn("awardit-cartMiniContainer", styles.desktop, styles.cart)}
          onClose={() => setMode(MODE.NORMAL)}
        />
      }
    </div>
  );
};

export default SubMenu;
