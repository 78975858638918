
/* @flow */

import type { QuizHistoryItemRes } from "shop-state/types";

import React, { useState } from "react";
import cn from "classnames";
import Button from "components/Button";

import { useTranslate } from "@awardit/react-use-translate";
import { RadioGroup, Dropdown, DropdownItem, Input, Checkbox, Radio } from "@crossroads/ui-components";
import TextArea from "components/TextArea";

import styles from "./styles.scss";

type QuizQuestion = {
  name: string,
  options: Array<string>,
  type: string,
  nr: number,
  points: number,
  id: number,
};

type Props = {
  question: QuizQuestion,
  userAnswers: Array<QuizHistoryItemRes>,
};

const Options = ({ question, userAnswers }: Props): React$Node => {
  const t = useTranslate();
  const [activeFilter, setActiveFilter] = useState();
  const currentAnswer = userAnswers.find(answer => {
    return parseInt(answer.id, 10) === question.id;
  });

  switch (question.type) {
    case "QuizOptionsList":
      return (
        <div>
          <Dropdown
            className={styles.fitlerPicker}
            value={activeFilter}
            placeholder={t("QUIZ.LIST_ALTERNATIV_PLACEHOLDER")}
            onChange={value => {
              setActiveFilter(value);
            }}
          >
            {question.options.map((q, i) => (
              <DropdownItem
                key={q}
                value={q}
                name={`QuizOptionsCheckbox-${question.id}-${i}`}
              >
                {q}
              </DropdownItem>
            ))}
          </Dropdown>
        </div>
      );
    case "QuizOptionsRadio":
      return (
        <RadioGroup
          value={currentAnswer ? currentAnswer.value[0] : ""}
          onChange={() => console.log("Change")}
        >
          {question.options.map((q, i) => (
            <Radio
              key={`${q}-${i}`}
              required
              name={`radio_${question.id}`}
              className={cn(styles.radio,
                { [styles.radioCorrect]:
                  currentAnswer &&
                  currentAnswer.answer &&
                  currentAnswer.answer.length > 0 &&
                  currentAnswer.answer.includes(q) })}
              value={q}
            >
              {q}
            </Radio>
          ))}
        </RadioGroup>
      );
    case "QuizOptionsCheckbox":
      return (
        <div>
          {question.options.map(q => (
            <Checkbox
              key={q}
              className={cn(styles.checkbox,
                { [styles.checkboxCorrect]:
                  currentAnswer &&
                  currentAnswer.answer &&
                  currentAnswer.answer.length > 0 &&
                  currentAnswer.answer.includes(q) }
              )}
              value={currentAnswer && currentAnswer.value.includes(q)}
              name={currentAnswer ? currentAnswer.name : ""}
              onChange={() => {}}
            >
              {q}
            </Checkbox>
          ))}
        </div>
      );
    case "QuizOptionsImage":
      return (
        <label className={styles.imageInput}>
          <input type="file" id="file" name="file" accept="image/*" aria-label="File browser example" />
          <span>{t("QUIZ.IMAGE_INPUT_LABEL")}</span>
          <Button variant="primary">{t("QUIZ.IMAGE_INPUT_BUTTON")}</Button>
        </label>
      );
    case "QuizOptionsFile":
      return (
        <input
          label={t("QUIZ.INPUT_LABEL")}
          value=""
        />
      );
    case "QuizOptionsText":
      return (
        <>
          <Input
            disabled
            wrapperClassName={styles.inputHistory}
            value={currentAnswer ? currentAnswer.value[0] : "No answer"}
            label={`${t("QUIZ.INPUT_LABEL")}`}
            name={`QuizOptionsText-${question.id}`}
          />
          {currentAnswer &&
          currentAnswer.answer &&
          currentAnswer.answer.length > 0 &&
          currentAnswer.answer.map((item, i) => (
            <p key={`${item}-${i}`} className={styles.correctInput}>
              {currentAnswer.answer}
            </p>
          ))}
        </>
      );
    case "QuizOptionsTextarea":
      return (
        <>
          <TextArea
            disabled
            className={styles.textAreaHistory}
            label={`${t("QUIZ.INPUT_LABEL")}`}
            name={`QuizOptionsTextarea-${question.id}`}
            value={currentAnswer ? currentAnswer.value[0] : "No answer"}
          />
          {currentAnswer &&
          currentAnswer.answer &&
          currentAnswer.answer.length > 0 &&
          currentAnswer.answer.map((item, i) => (
            <p key={`${item}-${i}`} className={styles.correctInput}>
              {item}
            </p>
          ))}
        </>
      );
    default:
      break;
  }

  return null;
};

export default Options;

