/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import RemoveIcon from "icons/close-small.svg";
import { useHistory } from "react-router-dom";
import { QuoteData } from "data";
import { pointsOnlyProductInCart, usePointsLabel, useSpendingLimit } from "helpers/points";

import styles from "./styles.scss";

type Props = {
  deleting: boolean,
  close: (animate: boolean) => void,
};

const InsufficientFundsMessage: React$AbstractComponent<Props, HTMLDivElement> =
React.forwardRef<Props, HTMLDivElement>(({ deleting, close }: Props, ref) => {
  const t = useTranslate();
  const { push } = useHistory();
  const { routes, content: { appheader } } = useContext(StoreInfoContext);
  const quoteState = useData(QuoteData);
  const label = usePointsLabel();
  const spendingLimit = useSpendingLimit();
  const items = quoteState.data ? quoteState.data.items : [];
  const pointsOnlyProductMessage = pointsOnlyProductInCart(items);

  const insufficientFundsBody = pointsOnlyProductMessage ?
    t("MESSAGE.INSUFFICIENT_FUNDS.POINTS_ONLY_BODY", { pointsLabel: label() }) :
    t("MESSAGE.INSUFFICIENT_FUNDS.BODY", { pointsLabel: label(), spendingLimit: spendingLimit.minPercent });

  const openCart = () => {
    close(false);
    push(`${(routes.checkoutView && routes.checkoutView.url) ?? ""}/cart`);
  };

  return (
    <div
      ref={ref}
      open={!deleting}
      className={cn(
        styles.message,
        { [styles.deleting]: deleting }
      )}
    >
      <div className={styles.itemInner}>
        <div className={styles.itemPadding}>
          <RemoveIcon className={styles.icon} onClick={close} />
          <h2>{appheader.insufficientFundsTitle ? appheader.insufficientFundsTitle : t("MESSAGE.INSUFFICIENT_FUNDS.HEADING")}</h2>
          <p>
            {(appheader.insufficientFundsBody) ?
              appheader.insufficientFundsBody :
              insufficientFundsBody}
          </p>
          <div className={styles.buttons}>
            <button
              type="button"
              className={styles.button}
              onClick={close}
            >
              {t("MESSAGE.INSUFFICIENT_FUNDS.BUTTON_LEFT")}
            </button>
            <button
              type="button"
              className={styles.button}
              onClick={openCart}
            >
              {t("MESSAGE.INSUFFICIENT_FUNDS.BUTTON_RIGHT")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default InsufficientFundsMessage;
