/* @flow */

import type { TinkTransactions } from "shop-state/types";

import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: TinkTransactions }
  | { state: "FETCHING", data: TinkTransactions }
  | { state: "REMOVING", data: TinkTransactions }
  | { state: "ERROR", error: mixed };

export type TinkTransactionsResponse =
  Response<typeof TINK_TRANSACTIONS_INIT_RESPONSE, TinkTransactions>
  | Response<typeof TINK_TRANSACTIONS_FETCH_RESPONSE, TinkTransactions>;

export type TinkTransactionsRequest = {
  tag: typeof TINK_TRANSACTIONS_INIT_REQUEST,
  page: number,
  pageSize: number,
} | {
  tag: typeof TINK_TRANSACTIONS_FETCH_REQUEST,
  page: number,
  pageSize: number,
};

export const PAGE_SIZE = 25;

export const TINK_TRANSACTIONS_INIT_REQUEST: "request/init/tink_transactions" = "request/init/tink_transactions";
export const TINK_TRANSACTIONS_INIT_RESPONSE: "response/init/tink_transactions" = "response/init/tink_transactions";
export const TINK_TRANSACTIONS_FETCH_REQUEST: "request/fetch/tink_transactions" = "request/fetch/tink_transactions";
export const TINK_TRANSACTIONS_FETCH_RESPONSE: "response/fetch/tink_transactions" = "response/fetch/tink_transactions";

export const load = (
  page?: number = 1,
  pageSize?: number = PAGE_SIZE
): TinkTransactionsRequest => ({
  tag: TINK_TRANSACTIONS_FETCH_REQUEST,
  page,
  pageSize,
});

export const TinkTransactionsModel: Model<
  Data, {}, TinkTransactionsRequest | TinkTransactionsResponse | EffectErrorMessage
> = {
  id: "tink_transactions",
  init: () =>
    updateData(
      { state: "LOADING" },
      { tag: TINK_TRANSACTIONS_INIT_REQUEST, page: 1, pageSize: PAGE_SIZE }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });

      case TINK_TRANSACTIONS_INIT_RESPONSE:
        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      case TINK_TRANSACTIONS_FETCH_REQUEST:
        if (state.state === "LOADED") {
          return updateData(
            { state: "FETCHING", data: state.data },
            { tag: TINK_TRANSACTIONS_FETCH_REQUEST, page: msg.page, pageSize: msg.pageSize }
          );
        }

        break;

      case TINK_TRANSACTIONS_FETCH_RESPONSE:
        if (state.state === "FETCHING") {
          if (msg.data) {
            return updateData({ state: "LOADED", data: [...state.data, ...msg.data] });
          }
        }

        break;
      default:
    }
  },
};
