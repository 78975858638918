/* @flow */

import type { AffiliatesListItem } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING", data: ?Array<AffiliatesListItem> }
  | { state: "LOADED", data: Array<AffiliatesListItem> }
  | { state: "ERROR", error: string };

type AffiliateListSelectedRequest = {
  size: ?number,
  tag: typeof AFFILIATE_LIST_SELECTED_REQUEST,
};

export type AffiliateListSelectedEffectResponse =
  Response<typeof AFFILIATE_LIST_SELECTED_EFFECT_RESPONSE, Array<AffiliatesListItem>>;

export type AffiliateListSelectedEffectRequest = {
  size: ?number,
  tag: typeof AFFILIATE_LIST_SELECTED_EFFECT_REQUEST,
};

const AFFILIATE_LIST_SELECTED_REQUEST: "request/affiliate_list_selected" = "request/affiliate_list_selected";

export const AFFILIATE_LIST_SELECTED_EFFECT_RESPONSE: "response/affiliate_list_selected_effect" = "response/affiliate_list_selected_effect";
export const AFFILIATE_LIST_SELECTED_EFFECT_REQUEST: "request/affiliate_list_selected_effect" = "request/affiliate_list_selected_effect";

export const getAffiliateSelectedList = (size?: number): AffiliateListSelectedRequest => ({
  size,
  tag: AFFILIATE_LIST_SELECTED_REQUEST,
});

export const AffiliateListSelectedModel: Model<
  Data,
  { size?: number },
  AffiliateListSelectedRequest |
  AffiliateListSelectedEffectRequest | AffiliateListSelectedEffectResponse
> = {
  id: "affiliate_list_selected",
  init: ({ size }) => {
    return updateData(
      { state: "LOADING", data: null },
      { size, tag: AFFILIATE_LIST_SELECTED_EFFECT_REQUEST }
    );
  },
  update: (state, msg) => {
    switch (msg.tag) {
      case AFFILIATE_LIST_SELECTED_REQUEST:
        return updateData(
          { state: "LOADING", data: state.state !== "ERROR" ? state.data : null },
          { ...msg, tag: AFFILIATE_LIST_SELECTED_EFFECT_REQUEST }
        );
      case AFFILIATE_LIST_SELECTED_EFFECT_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
