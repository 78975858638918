/* @flow */

import React from "react";
import Spinner from "components/Spinner";

import styles from "./styles.scss";

const ReloadConfigLoadingView = ({ title }: { title?: string }): React$Node => {
  return (
    <div className={styles.reloadConfigView}>
      {title &&
        <h1>{title}</h1>
      }
      <Spinner className={styles.spinner} />
    </div>
  );
};

export default ReloadConfigLoadingView;
