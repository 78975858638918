/* @flow */

import type { Form } from "shop-state/types";
import type { RouterHistory as History } from "react-router-dom";
import type { FormData } from "@awardit/formaggio/src/types";

import React, { useState, useEffect } from "react";
import useDevice from "helpers/use-device";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { addMessage } from "state/messages";
import { useData, useSendMessage } from "crustate/react";
import { useClient } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import { postFormData } from "queries";
import { getFormRows } from "state/forms";
import { AllFormsData, FormRowsData } from "data";

import Wrapper from "components/Wrapper";
import Breadcrumbs from "components/Breadcrumbs";
import Button from "components/Button";
import FormComponent from "components/FormComponent";
import ErrorView from "components/ErrorView";
import LoadingView from "components/LoadingView";
import { Title } from "components/UiComponents";

import styles from "./styles.scss";

const FormView = ({ formId, history }: { formId: number, history: History }): React$Node => {
  const findForm = (forms: Array<Form>, formId: number) => {
    const form = forms.find(f => f.id === formId);
    if (form !== undefined && form !== null) {
      const currentDate = new Date();
      const validToDate = new Date(form.validto);

      if (currentDate.getTime() <= validToDate.getTime()) {
        return form;
      }
    }

    return null;
  };

  const { location } = useHistory();
  const goPrevPage = new URLSearchParams(location.search).get("goPrevPage");
  const isDesktop = useDevice("gt", 1056);
  const allFormsData = useData(AllFormsData);
  const formData = useData(FormRowsData);
  const sendMessage = useSendMessage();
  const client = useClient();
  const t = useTranslate();
  const [state, setState] = useState<FormData>({});

  const rowData = formData.state === "LOADED" ? formData.data : [];

  const currentForm = allFormsData.state === "LOADED" &&
    allFormsData.data &&
    allFormsData.data.length > 0 ?
    findForm(allFormsData.data, formId) : null;

  const currentFormId = currentForm?.id;
  const currentFormName = currentForm?.formname;
  const currentFormText = currentForm?.formtext;

  const formatObject = (obj: {}, formId) => {
    if (typeof obj === "object" && obj !== null) {
      const keys = Object.keys(obj);
      const res = {
        id: formId?.toString(),
        values: [],
      };

      for (const key of keys) {
        if (obj[key]) {
          res.values.push(`${key}:${obj[key]}`);
        }
      }

      return res;
    }
  };

  useEffect(() => {
    if (currentFormId !== null && currentFormId !== undefined) {
      sendMessage(getFormRows(currentFormId));
    }
  }, [currentFormId]);

  const postForm = async () => {
    const data = formatObject(state, currentFormId);

    if (data?.id && (data.values.length > 0 || data.values)) {
      const response = await client(postFormData, { form: {
        id: data.id,
        values: data.values,
      } });

      if (response.postFormData.result === false) {
        sendMessage(addMessage(`POST_FORM_ERROR`, "error"));
      }
      else {
        sendMessage(addMessage(`POST_FORM_SUCCESS`, "success"));
        setState({});
        if (goPrevPage === "true") {
          history.goBack();
        }
      }
    }
  };

  if (formData.state === "ERROR") {
    return (
      <ErrorView />
    );
  }

  return (
    <Wrapper>
      <Helmet title={currentFormName} />
      <>
        <Breadcrumbs current={currentFormName ? currentFormName : "..."} />
        <Title>{currentFormName ? currentFormName : "..."}</Title>
        {goPrevPage === "true" && <Button variant="link" onClick={() => history.goBack()}>{t("COMMON.GO_BACK")}</Button>}
        {formData.state === "LOADING" ?
          <LoadingView /> :
          <div className={styles.content}>
            {isDesktop ? (
              <>
                <FormComponent
                  state={state}
                  setState={x => setState(x)}
                  formData={rowData}
                  postForm={postForm}
                  className={styles.form}
                />
                {/* eslint-disable react/no-danger */}
                <div
                  dangerouslySetInnerHTML={{ __html: currentFormText }}
                  className={styles.info}
                />
              </>
            ) : (
              <>
                <div
                  dangerouslySetInnerHTML={{ __html: currentFormText }}
                  className={styles.info}
                />
                {/* eslint-enable react/no-danger */}
                <FormComponent
                  state={state}
                  setState={x => setState(x)}
                  formData={rowData}
                  postForm={postForm}
                  className={styles.form}
                />
              </>
            )}
          </div>
        }
      </>
    </Wrapper>
  );
};

export default FormView;
