/* @flow */

import React from "react";
import Wrapper from "components/Wrapper";
import Spinner from "components/Spinner";

import styles from "./styles.scss";

const FullScreenLoadingView = (): React$Node => {
  return (
    <Wrapper className={styles.fullScreen}>
      <Spinner />
    </Wrapper>
  );
};

export default FullScreenLoadingView;
