/* @flow */

import type { TinkTransactions } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";

import { StoreInfoContext } from "entrypoint/shared";
import Points from "components/AccountView/TransactionsView/Points";

import styles from "components/AccountView/TinkView/TableLarge/styles.scss";

type TableLargeProps = {
  items: TinkTransactions,
};

const TableLargeHead = () => {
  const { content: { tinkview } } = useContext(StoreInfoContext);

  return (
    <thead>
      <tr>
        <th>{tinkview.tableDate ?? ""}</th>
        <th>{tinkview.tableDescription ?? ""}</th>
        <th>{tinkview.tableTotal ?? ""}</th>
        <th>{tinkview.tableCurrency ?? ""}</th>
        <th>{tinkview.tableType ?? ""}</th>
      </tr>
    </thead>
  );
};

const TableLarge = ({ items }: TableLargeProps): React$Node => {
  return (
    <table className={cn(styles.table, styles.table__large)}>
      <TableLargeHead />
      <tbody>
        {items.map((x, i) => (
          <tr key={i}>
            <td>{x.date}</td>
            <td>{x.description}</td>
            <td className={cn({ [styles.negative]: x.amount < 0 })}>
              <Points points={x.amount} />
            </td>
            <td>{x.currency}</td>
            <td>{x.type}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const TableLargeHint = ({ numItems }: { numItems: number }): React$Node => (
  <table className={cn(styles.table, styles.table__large, styles.dummy)}>
    <TableLargeHead />
    <tbody>
      {[...new Array(numItems)].map((x, i) => (
        <tr key={i}>
          <td /><td /><td /><td /><td />
        </tr>
      ))}
    </tbody>
  </table>
);

export default TableLarge;
