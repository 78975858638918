/* @flow */

import type { AwarditAgreement } from "shop-state/types";

import React, { useState, useEffect, useContext } from "react";
import cn from "classnames";
import { Lightbox } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { awarditAgreement, memberTagList, addTagToMember } from "queries";
import Button from "components/Button";
import CloseIcon from "icons/close-large.svg";

import styles from "./styles.scss";

type AgreementProps = {
  agreementModalOpen: boolean,
  setAgreementModalOpen: (open: boolean | null) => void,
  cantAccept?: boolean,
};

const Agreement = ({
  agreementModalOpen,
  setAgreementModalOpen,
  cantAccept }: AgreementProps): React$Node => {
  const t = useTranslate();
  const client = useClient();
  const [agreement, setAgreement] = useState<AwarditAgreement | null>(null);
  const [memberTags, setMemberTags] = useState(false);

  const { routes, info: { code } } = useContext(StoreInfoContext);
  const [agreementExists, setAgreementExists] = useState<boolean>(false);
  const [error, setError] = useState("");

  useEffect(() => {
    client(awarditAgreement).then(({ awarditAgreement }) => setAgreement(awarditAgreement));
    client(memberTagList).then(({ memberTagList }) => {
      // 5323494 = "Chef", 5323495 = "Endast chef", 5323496 = "Alla"
      // Members that has the tag "Chef" and no "Endast chef" & "Alla"
      // tags can make a selection regarding points
      if (memberTagList.find(tag => Object.values(tag).includes(5323494)) &&
      memberTagList.find(tag => Object.values(tag).includes(5323495)) === undefined &&
      memberTagList.find(tag => Object.values(tag).includes(5323496)) === undefined) {
        setMemberTags(true);
      }
    });
  }, [client]);

  useEffect(() => {
    if (agreement !== null && (agreement.content !== undefined && agreement.content !== null)) {
      setAgreementExists(true);
    }
  }, [agreement]);

  // Add "Alla" or "Endast chef" tag to the "Release Points"-user
  const postMemberTag = async value => {
    const data = await client(addTagToMember, { id: Number(value) });
    if (data.addTagToMember.result === "SUCCESS") {
      setError("");
      setMemberTags(false);
      setAgreementModalOpen(false);
    }
    else {
      setError(t("TERMSVIEW.ERROR_POST_MEMBERTAG"));
    }
  };

  /* eslint-disable react/no-danger */
  return (
    <> { agreementExists ?
      <Lightbox
        className={styles.agreement}
        open={agreementModalOpen}
        setOpen={() => setAgreementModalOpen(null)}
      >
        <div className={styles.top}>
          <div className={styles.left}>
            <div className={styles.header}>
              <h2>{routes.termsView && routes.termsView.toggle !== undefined &&
              routes.termsView.toggle && routes.termsView.title ?
                routes.termsView.title :
                t("TERMSVIEW.FALLBACK_TITLE")}
              </h2>
            </div>

            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: agreement?.content }}
            />
            {/* For Release Points users with the tag "Chef" must choose between to options:
            5323496 = "Alla"
            5323495 = "Endast chef"
            TODO - Do some research too see why the modal reset scroll position
            to the top as soon as the state changes */}
            {code === "releasefinans_sek" && memberTags &&
            <>
              <div className={styles.agreementMemberTag}>
                <h3 className={styles.agreementHeading}>{t("TERMSVIEW.MEMBERTAG_TITLE")}</h3>
                <label className={styles.agreementRadio}>
                  <input
                    checked
                    type="radio"
                    name="membertag"
                    value="5323496"
                  />
                  <span>{t("TERMSVIEW.MEMBERTAG_EMPLOYEE_OPTION")}</span>
                </label>
                <label className={styles.agreementRadio}>
                  <input
                    type="radio"
                    name="membertag"
                    value="5323495"
                  />
                  <span>{t("TERMSVIEW.MEMBERTAG_MANAGER_OPTION")}</span>
                </label>
              </div>
              {error && <p className={styles.agreementError}>{error}</p>}
            </>
            }
          </div>

          <div className={styles.right}>
            <Button
              variant="link"
              className={cn("link", styles.close, styles.noPrint)}
              onClick={() => setAgreementModalOpen(true)}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>

        {cantAccept === undefined || cantAccept === false ?
          <div className={styles.agreementBottom}>
            <Button
              className={styles.agreementButton}
              onClick={() => {
                if (code === "releasefinans_sek" && memberTags) {
                  const radioValue = document.querySelector("input[name='membertag']:checked");
                  if (radioValue !== null && radioValue instanceof HTMLInputElement) {
                    postMemberTag(radioValue.value);
                  }
                }
                else {
                  setAgreementModalOpen(false);
                }
              }}
            >
              <p>{t("COMMON.APPROVE")}</p>
            </Button>
          </div> : null
        }
      </Lightbox> : null
    }
    </>
  );
  /* eslint-enable react/no-danger */
};

export default Agreement;
