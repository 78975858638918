/* @flow */

import type { CSSModule } from "css-module";
import type { Product,
  ProductCardProduct,
  Customer,
  Quote,
  StoreInfo,
  StoreContent,
  RouteConfiguration,
  Routes,
  QuoteItemProduct,
  OrderItemProduct,
} from "shop-state/types";
import type { Option } from "@crossroads/ui-components";
import type { Data as TCustomerData } from "state/customer";

import {
  getSelectedConfigurable,
  getAttributesConfigurable,
} from "@crossroads/ui-components";
import { useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { CustomerData, QuoteData } from "data";
import { minPoints } from "state/quote";
import { pointsPriceByID } from "helpers/points";

type SortItem = {
  value: any,
  sort: number,
};

type EarnView = $PropertyType<StoreContent, 'earnview'>;

type Error = {
  field: string,
  error: string,
};

declare type DOMEvent<T> = {
  target: T,
} & Event;

export const focusInvalidField = (e: SyntheticEvent<HTMLFormElement>, errors: Array<Error>) => {
  const field = e.currentTarget.querySelector(`[name='${errors[0].field}']`);

  if (field) {
    field.focus();
  }
};

export const useSkipPayment = (): boolean => {
  const customerData = getCustomerData(useData(CustomerData));
  const quoteData = useData(QuoteData);
  const quote = quoteData.data || null;

  if (!quote || quoteData.state === "LOADING") {
    return false;
  }

  const pointPayment = pointsPriceByID(quote.availablePointPayments, "awardit");

  if (pointPayment && pointPayment.points.min.exVat === pointPayment.points.available.exVat) {
    return true;
  }

  if (minPoints(quote) === 0 && parseInt(customerData?.awardit.activePoints ?? 0, 10) === 0) {
    return true;
  }

  if (pointPayment && pointPayment.points.min.exVat > 0 &&
    pointPayment.points.min.exVat === pointPayment.points.value.exVat) {
    return true;
  }

  return false;
};

export const usePriceToPoints = (): (points: number) => number => {
  const customerData = useData(CustomerData);

  let rate;

  if (customerData.data && typeof customerData.data.awardit.rate === "number") {
    rate = customerData.data.awardit.rate;
  }

  return (price: number) => price * rate;
};

export const usePointsToPrice = (): (points: number) => number => {
  const customerData = useData(CustomerData);

  let rate;

  if (customerData.data && typeof customerData.data.awardit.rate === "number") {
    rate = customerData.data.awardit.rate;
  }

  return (points: number) => points / rate;
};

export const productIsNotLackingPrice = (product: Product | ProductCardProduct): boolean => {
  return (
    product.price.incVat > 0 &&
    product.pointsPrices.length > 0 &&
    product.pointsPrices.every(p => p.points.value.incVat > 0)
  );
};

export const loadScript = (isLoaded: () => boolean, src: string, cb: () => void): void => {
  if (!process.browser) {
    return;
  }

  if (isLoaded()) {
    cb();

    return;
  }

  const head = document.querySelector("head");
  const script = document.createElement("script");

  script.src = src;

  /* eslint-disable unicorn/prefer-add-event-listener */
  script.onload = () => {
    script.onload = null;

    cb();
  };
  /* eslint-enable unicorn/prefer-add-event-listener */

  if (head) {
    /* eslint-disable unicorn/prefer-node-append */
    head.appendChild(script);
    /* eslint-enable unicorn/prefer-node-append */
  }
};

export const isValidHex = (hex: string): boolean => /^[0-9A-F]{6}$/i.test(hex);

/**
 * Calculate the stepsize to use with the rangeslider
 */
export const getStepSize = (value: number, percent: number): number => {
  const stringValue = String(value);
  const numDigits = stringValue.length;
  const prefix = value < 10 ?
    1 :
    Math.ceil(Number.parseInt(stringValue.slice(0, 2), 10) * (0.01 * percent));

  return Number.parseInt(`${prefix}${(new Array(numDigits - 1)).join("0")}`, 10);
};

/**
 * Get price for selected product and option
 */
export const getPrice = (product: Product, selected: Option): number => {
  const configAttributes = product.type === "configurable" ? getAttributesConfigurable(product) : {};
  const item = getSelectedConfigurable(selected, configAttributes);

  switch (product.type) {
    case "configurable":
      if (item) {
        const selectedOption = product.options.items.find(x => x.buyRequest === item.buyRequest);

        if (selectedOption) {
          return selectedOption.price.exVat;
        }
      }

      break;
    case "bundle":
      let price = 0;

      product.bundleOptions.forEach(option => {
        option.selections.forEach(selection => {
          if (selected[option.optionId] === selection.selectionId) {
            price += selection.price.exVat;
          }
        });
      });

      return price;
    default:
  }

  return product.price.exVat;
};

export const streetArrayToObject = (arr: Array<string>): {...} => {
  return arr.reduce((a, c, i) => {
    a[i] = c;
    return a;
  }, {});
};

export const getPriceDistribution = (
  quoteAwarditPoints: number,
  grandTotalIncVat: number,
  grandTotalVat: number,
  pointRate: number
): {|
  pointsValueIncVat: number,
  pointsValueExVat: number,
  totalIncVat: number,
  totalExVat: number,
|} => {
  const pointsValueIncVat = quoteAwarditPoints / pointRate;
  const totalIncVat = grandTotalIncVat + pointsValueIncVat;
  const totalExVat = totalIncVat - grandTotalVat;
  const pointsValueExVat = pointsValueIncVat * (totalExVat / totalIncVat);

  return {
    pointsValueIncVat,
    pointsValueExVat,
    totalIncVat,
    totalExVat,
  };
};

export const canAffordCart = (quote?: ?Quote): boolean => {
  if (!quote) {
    return true;
  }

  const pointPayment = pointsPriceByID(quote.availablePointPayments, "awardit");

  if (!pointPayment) {
    return false;
  }

  if (pointPayment.rejectionReasons.length === 0) {
    return true;
  }

  return false;
};

export const missingUserPoints = (quote: Quote): number => {
  if (!quote) {
    return 0;
  }

  const pointPayment = pointsPriceByID(quote.availablePointPayments, "awardit");

  if (!pointPayment) {
    return 0;
  }

  const balanceRejection = pointPayment.rejectionReasons.find(r => r.error === "PointsQuoteRejectionReasonBalance") || {};

  const { requested, balance } = balanceRejection.error === "PointsQuoteRejectionReasonBalance" ? balanceRejection : {};

  const missingPoints = requested - balance;

  return missingPoints;
};

export const getCustomerData = (d: TCustomerData): ?Customer => {
  if (d.data !== null) {
    return d.data;
  }

  return null;
};

export const navRoutes = (
  navRoutes: Array<string>, storeInfo: StoreInfo
): Array<RouteConfiguration> => {
  const { routes } = storeInfo;

  return navRoutes
    .map(x => routes[x])
    .filter(Boolean)
    .filter(x => x.toggle !== undefined && x.toggle && x.url && x.show)
    .sort((a, b) => {
      if (isNaN(a.order) && isNaN(b.order)) {
        return 0;
      }

      if (isNaN(a.order)) {
        return 1;
      }

      if (isNaN(b.order)) {
        return -1;
      }

      return parseInt(a.order, 10) < parseInt(b.order, 10) ? -1 : 1;
    });
};

export const formatDate = (d: string): string => {
  const date = new Date(d);

  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
};

export const getNumberBasedOnBrowserWidth = (
  styles: CSSModule,
  config: { [string]: number },
  defaultAmount: number,
  browserWidth: number): number => {
  const currentBreakpoint = Object.keys(config).find(breakpoint => {
    return browserWidth <= parseInt(styles[breakpoint], 10);
  });

  return currentBreakpoint ? config[currentBreakpoint] : defaultAmount;
};

export const getBrowserBreakpointValue = (
  styles: CSSModule,
  breakpoint: string
): number => {
  return parseInt(styles[breakpoint], 10);
};

export const roundDownToMultiple = (number: number, multiple: number = 5): number => {
  return number - (number % multiple);
};

export const shuffleArray = <T>(data: Array<T>): Array<T> => data
  .map(value => ({ value, sort: Math.random() }))
  .sort((a: SortItem, b: SortItem) => a.sort - b.sort)
  .map(({ value }) => value);

export const isShopOrAccountRoute =
(pathname: string, routes: Routes, onFullMenuHiddenRoute: boolean): boolean => {
  const accountUrl = routes.accountView && routes.accountView.url ?
    routes.accountView.url : " ";
  const leaderboardUrl = routes.leaderboardView && routes.leaderboardView.url ?
    routes.leaderboardView.url : " ";
  const reportsUrl = routes.reportsView && routes.reportsView.url ?
    routes.reportsView.url : " ";
  const questionnaireUrl = routes.questionnaireView && routes.questionnaireView.url ?
    routes.questionnaireView.url : " ";

  if (pathname.includes(accountUrl)) {
    return true;
  }

  return pathname !== "/" &&
  !pathname.includes("index.html") &&
  !pathname.includes(leaderboardUrl) &&
  !pathname.includes(reportsUrl) &&
  !pathname.includes(questionnaireUrl) &&
  !onFullMenuHiddenRoute;
};

export const getEstimatedDeliveryText =
(product: Product | QuoteItemProduct | OrderItemProduct): string => {
  /* eslint-disable react-hooks/rules-of-hooks */
  const t = useTranslate();
  /* eslint-enable react-hooks/rules-of-hooks */

  if (product.attributes.estimatedDeliveryDays) {
    return product.attributes.estimatedDeliveryDays;
  }

  switch (product.type) {
    case "virtual":
      return t("PRODUCT.ESTIMATED_DELIVERY.VIRTUAL");
    default:
    case "simple":
      return t("PRODUCT.ESTIMATED_DELIVERY.NORMAL");
  }
};

export const tryParseJSONObject = (jsonString: string): any | boolean => {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return o;
    }
  }
  catch (e) { }

  return false;
};

export const getSubPageCards = (
  earnview: EarnView,
  pathname: string,
  earnViewSubpage: string
): Array<| any | {| description: string, heading: string, image: string, link: string |}> => {
  const topLevelCards = [];

  // Check to know which earnViewSubpage thas is active (there are only two atm)
  const subPageIndex = pathname.includes(earnViewSubpage) ? "" : 2;

  // Four cards is maximum
  for (let index = 1; index <= 4; index++) {
    const topLevelCard = {};
    if (
      earnview["subPage" + subPageIndex + "cardHeading" + index] &&
      earnview["subPage" + subPageIndex + "cardLinkUrl" + index]
    ) {
      topLevelCard.heading = earnview["subPage" + subPageIndex + "cardHeading" + index];
      topLevelCard.link = earnview["subPage" + subPageIndex + "cardLinkUrl" + index];
      topLevelCard.image = earnview["subPage" + subPageIndex + "cardImage" + index];
      topLevelCard.description = earnview["subPage" + subPageIndex + "cardDescription" + index];
      topLevelCards.push(topLevelCard);
    }
  }

  return topLevelCards;
};

export const getPageType = (routes: Routes, path: string): string => {
  switch (path) {
    case routes.homeView && routes.homeView.url:
      return "HOMEVIEW";
    case routes.allProductsView && routes.allProductsView.url:
      return "ALLPRODUCTSVIEW";
    case routes.loginView && routes.loginView.url:
      return "LOGINVIEW";
    case routes.redeemView && routes.redeemView.url:
      return "REDEEMVIEW";
    case routes.earnView && routes.earnView.url:
      return "EARNVIEW";
    default:
      return "";
  }
};
