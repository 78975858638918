/* @flow */

import type { FormData as FormDataType } from "@awardit/formaggio/src/types";

import React, { useState } from "react";
import Wrapper from "components/Wrapper";
import { useTranslate } from "@awardit/react-use-translate";
import { Form, isEmail, isPhone, isRequired, rules } from "@awardit/formaggio";
import Field, { CheckboxField, TextAreaField, RadioField } from "components/Field";
import { Dropdown, DropdownItem, RadioGroup } from "@crossroads/ui-components";
import Button from "components/Button";
import cn from "classnames";
import type { FormRow } from "shop-state/types";

import styles from "./styles.scss";

type FormProps = {
  formData: Array<FormRow>,
  state: FormDataType,
  setState: (x: FormDataType) => void,
  postForm: () => Promise<void>,
};

const FormComponent = ({ formData, state, setState, postForm }: FormProps): React$Node => {
  const [submitting, setSubmitting] = useState(false);
  const [displayErrors, setDisplayErrors] = useState(false);
  const validationRules = [];
  const t = useTranslate();

  formData.forEach(item => {
    if (item.required === true) {
      validationRules.push(isRequired(`varid${item.id}`));
    }

    if (item.name === "Telefonnummer") {
      validationRules.push(isPhone(`varid${item.id}`));
    }

    if (item.name === "E-post") {
      validationRules.push(isEmail(`varid${item.id}`));
    }
  });

  const validateMyForm = rules(validationRules);
  const formErrors = validateMyForm(state);

  const getImageString = async (file: File | null, name: string) => {
    const formData = new FormData();

    if (file) {
      formData.append("image", file);

      const response = await fetch("/UploadImage", {
        method: "POST",
        credentials: "same-origin",
        body: formData,
      });
      const data = await response.json();

      if (data.filename) {
        const object = {
          [name]: data.filename,
        };

        setState({ ...state, ...object });
      }
    }
    else {
      setState({ ...state, ...{ [name]: "" } });
    }
  };

  const submit = async e => {
    e.preventDefault();
    setSubmitting(true);
    await postForm();
    setSubmitting(false);
  };

  const hasErrors = () => {
    setDisplayErrors(true);
  };

  return (
    <Wrapper className={cn(styles.wrapper, styles.left)}>
      {submitting === false &&
        <Form
          errors={formErrors}
          value={state}
          onChange={x => setState({ ...state, ...x })}
          onError={hasErrors}
          onSubmit={submit}
        >
          {
            formData.map((item, i) => {
              switch (item.type) {
                case "FormOptionsText":
                  return (
                    <Field
                      key={`${i}`}
                      label={item.name}
                      name={`varid${item.id}`}
                      wrapperClassName={cn(styles.textField)}
                    />
                  );
                case "FormOptionsTextArea":
                  return (
                    <TextAreaField
                      key={`${i}`}
                      label={item.name}
                      name={`varid${item.id}`}
                      className={cn(styles.textField)}
                      value={state[`varid${item.id}`] ?? ""}
                    />
                  );
                case "FormOptionsCheckbox":
                  return (
                    <div className={styles.checkbox}>
                      <CheckboxField
                        key={`${i}`}
                        name={`varid${item.id}`}
                        value={state[`varid${item.id}`] ?? false}
                      >
                        {item.name}
                      </CheckboxField>
                    </div>
                  );
                case "FormOptionsSelect":
                  return (
                    <div className={styles.dropdownField}>
                      <Dropdown
                        key={`${i}`}
                        className={cn(styles.dropdown,
                          { [styles.dropdown__maxHeight]: item.options.length > 5 }
                        )}
                        placeholder={item.name}
                        value={state[`varid${item.id}`] ? state[`varid${item.id}`].toString() : ""}
                        onChange={e => {
                          setState({ ...state, [`varid${item.id}`]: e });
                        }}
                      >
                        {item.options.map((option, i) => (
                          <DropdownItem key={i} value={option}>
                            {option}
                          </DropdownItem>
                        ))}
                      </Dropdown>
                      {formErrors.map(error => {
                        if (error.field === `varid${item.id}` && displayErrors === true) {
                          return <span className={styles.error}>{t("VALIDATION.REQUIRED")}</span>;
                        }

                        return null;
                      })}
                    </div>
                  );
                case "FormOptionsRadio":
                  return (
                    <div className={styles.radioGroupField}>
                      <RadioGroup
                        key={`${i}`}
                        value={state[`varid${item.id}`] ? state[`varid${item.id}`].toString() : ""}
                        className={styles.radioGroup}
                        onChange={e => {
                          setState({ ...state, [`varid${item.id}`]: e.currentTarget.value });
                        }}
                      >
                        <legend>{item.name}</legend>
                        {item.options.map((option, i) => (
                          <RadioField
                            key={i}
                            name={`radio_${i}`}
                            className={styles.radio}
                            value={option}
                          >
                            {option}
                          </RadioField>
                        ))}
                      </RadioGroup>
                      {formErrors.map(error => {
                        if (error.field === `varid${item.id}` && displayErrors === true) {
                          return <span className={styles.error}>{t("VALIDATION.REQUIRED")}</span>;
                        }

                        return null;
                      })}
                    </div>
                  );
                case "FormOptionsImage":
                  return (
                    <div className={styles.fileInput}>
                      <label htmlFor={`varid${item.id}`}>{item.name}</label>
                      <input
                        key={`${i}`}
                        required={item.required}
                        type="file"
                        name={`varid${item.id}`}
                        accept="image/jpeg, image/jpg, image/png"
                        onChange={e => getImageString(e.target.files[0], `varid${item.id}`)}
                      />
                      <div className={styles.bgColor} />
                    </div>
                  );
                case "FormOptionsFile":
                  return (
                    <div className={styles.fileInput}>
                      <label htmlFor={`varid${item.id}`}>{item.name}</label>
                      <input
                        key={`${i}`}
                        type="file"
                        label={item.name}
                        name={`varid${item.id}`}
                        required={item.required}
                        onChange={e => getImageString(e.target.files[0], `varid${item.id}`)}
                      />
                    </div>
                  );
                default:

                  break;
              }

              return null;
            })
          }
          <Button type="submit" disabled={submitting} variant="primary">Skicka</Button>
        </Form>
      }
    </Wrapper>
  );
};

export default FormComponent;
