/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { CustomerRequest, CustomerResponse } from "state/customer";

import {
  CUSTOMER_INIT_REQUEST,
  CUSTOMER_INIT_RESPONSE,
  CUSTOMER_LOGOUT_REQUEST,
  CUSTOMER_LOGOUT_RESPONSE,
  CUSTOMER_SAVE_BILLING_ADDRESS_REQUEST,
  CUSTOMER_SAVE_BILLING_ADDRESS_RESPONSE,
  CUSTOMER_RESET_PASSWORD_REQUEST,
  CUSTOMER_RESET_PASSWORD_RESPONSE,
} from "@crossroads/shop-state/customer";

import {
  CUSTOMER_LOGOUT_REDIRECT_REQUEST,
  CUSTOMER_LOGOUT_REDIRECT_RESPONSE,
  CUSTOMER_NEED_VERIFY_RESPONSE,
  CUSTOMER_EMAIL_VERIFY_REQUEST,
  CUSTOMER_EMAIL_VERIFY_RESPONSE,
  CUSTOMER_LOGIN_REQUEST,
  CUSTOMER_LOGIN_RESPONSE,
} from "state/customer";

import { loadQuote } from "@crossroads/shop-state/quote";

import { addMessage, clearMessages } from "state/messages";

import {
  customer as customerQuery,
  logout,
  login,
  resetPassword,
  createCustomerAddress,
  updateCustomerAddress,
  updateCustomer,
  sendVerificationEmail,
  partnerPoints,
} from "queries";

type History = {
  push: (path: string) => void,
};

const registerClient = (
  storage: Storage,
  client: Client<{}>,
  history: History) => {
  storage.addEffect({
    effect: async () => {
      try {
        const { customer: data, memberPoints, memberTargetList } = await client(customerQuery);

        if (data) {
          storage.broadcastMessage(loadQuote());
          const { pointsPerPartner } = await client(partnerPoints);

          return ({
            tag: CUSTOMER_INIT_RESPONSE,
            data: {
              ...data,
              memberPoints,
              memberTargetList,
              pointsPerPartner,
            },
          }: CustomerResponse);
        }

        return ({
          tag: CUSTOMER_INIT_RESPONSE,
          data: null,
        }: CustomerResponse);
      }
      catch (e) {
        console.error(e);

        return ({
          tag: CUSTOMER_INIT_RESPONSE,
          data: null,
        }: CustomerResponse);
      }
    },
    subscribe: { [CUSTOMER_INIT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: CustomerRequest, path) => {
      if (msg.tag === CUSTOMER_LOGIN_REQUEST) {
        const data = await client(login, { username: msg.email, password: msg.password });

        if (data && data.loginCustomer.result !== "success") {
          if (data && data.loginCustomer.result === "requestVerification") {
            storage.replyMessage(({
              tag: CUSTOMER_NEED_VERIFY_RESPONSE,
              memberuserid: data.loginCustomer.memberuserid,
              email: data.loginCustomer.mailto,
            }: CustomerResponse), path);
          }
          else {
            storage.broadcastMessage(addMessage(data.loginCustomer.result, "error"));
          }
        }
        else {
          storage.broadcastMessage(clearMessages());
        }

        if (data.loginCustomer.customer) {
          const { memberPoints, memberTargetList } = await client(customerQuery);
          const { pointsPerPartner } = await client(partnerPoints);
          storage.broadcastMessage(loadQuote());

          storage.replyMessage(({
            tag: CUSTOMER_LOGIN_RESPONSE,
            data: {
              ...data.loginCustomer.customer,
              memberPoints,
              memberTargetList,
              pointsPerPartner,
            },
          }: CustomerResponse), path);
        }
        else {
          storage.replyMessage(({
            tag: CUSTOMER_LOGIN_RESPONSE,
            data: null,
          }: CustomerResponse), path);
        }
      }
    },
    subscribe: { [CUSTOMER_LOGIN_REQUEST]: true },
  });

  storage.addEffect({
    effect: (msg: CustomerRequest, path) => {
      if (msg.tag === CUSTOMER_RESET_PASSWORD_REQUEST) {
        return client(resetPassword, { email: msg.email })
          .then(data => {
            if (data && data.resetPassword.result !== "success") {
              storage.broadcastMessage(addMessage("errorResetPassword", "error"));

              storage.replyMessage(({
                tag: CUSTOMER_RESET_PASSWORD_RESPONSE,
              }: CustomerResponse), path);
            }
            else {
              storage.broadcastMessage(clearMessages());
              storage.broadcastMessage(addMessage("MAIL_SENT", "success"));

              storage.replyMessage(({
                tag: CUSTOMER_RESET_PASSWORD_RESPONSE,
              }: CustomerResponse), path);

              history.push("/");
            }
          });
      }
    },
    subscribe: { [CUSTOMER_RESET_PASSWORD_REQUEST]: true },
  });

  storage.addEffect({
    effect: (msg: CustomerRequest, path) => {
      return client(logout)
        .then(() => {
          storage.replyMessage(({
            tag: CUSTOMER_LOGOUT_RESPONSE,
          }: CustomerResponse), path);

          history.push("/login");
        });
    },
    subscribe: { [CUSTOMER_LOGOUT_REQUEST]: true },
  });

  storage.addEffect({
    effect: (msg: CustomerRequest, path) => {
      if (msg.tag === CUSTOMER_LOGOUT_REDIRECT_REQUEST) {
        return client(logout)
          .then(() => {
            storage.replyMessage(({
              tag: CUSTOMER_LOGOUT_REDIRECT_RESPONSE,
            }: CustomerResponse), path);

            // msg.redirectURL originates from the SSO-logout input
            // field in java-admin and it is approved in that application,
            // because of that we can trust the link and do a full redirect
            if (msg.redirectURL.startsWith("http")) {
              window.location = msg.redirectURL;
            }
            else {
              window.location = window.location.origin + msg.redirectURL;
            }
          });
      }
    },
    subscribe: { [CUSTOMER_LOGOUT_REDIRECT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: CustomerRequest, path) => {
      if (msg.tag === CUSTOMER_EMAIL_VERIFY_REQUEST) {
        const data = await client(sendVerificationEmail, { memberuserid: msg.memberuserid });

        if (data.sendVerificationEmail.result === true) {
          storage.replyMessage(({
            tag: CUSTOMER_EMAIL_VERIFY_RESPONSE,
            memberuserid: msg.memberuserid,
          }: CustomerResponse), path);
        }
      }
    },
    subscribe: { [CUSTOMER_EMAIL_VERIFY_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: CustomerRequest, path) => {
      if (msg.tag !== CUSTOMER_SAVE_BILLING_ADDRESS_REQUEST) {
        return;
      }

      await client(updateCustomer, {
        customer: {
          firstname: msg.address.firstname,
          lastname: msg.address.lastname,
        },
      });

      // Create new address if no ID was provided
      const id = msg.address && msg.address.id;
      if (msg.address) {
        delete msg.address.id;
      }

      const query = id !== null ? updateCustomerAddress : createCustomerAddress;

      const data = await client(query, { id, address: msg.address });

      const d = data[id !== null ? "updateCustomerAddress" : "createCustomerAddress"];

      if (d && (d.result !== "success" && d.result !== "notModified")) {
        storage.broadcastMessage(addMessage(d.result, "error"));
      }
      else {
        storage.broadcastMessage(clearMessages());
      }

      const { customer, memberPoints, memberTargetList } = await client(customerQuery);

      storage.replyMessage(({
        tag: CUSTOMER_SAVE_BILLING_ADDRESS_RESPONSE,
        data: {
          ...customer,
          memberPoints,
          memberTargetList,
        },
      }: CustomerResponse), path);

      return d;
    },
    subscribe: { [CUSTOMER_SAVE_BILLING_ADDRESS_REQUEST]: true },
  });
};

export default registerClient;
