/* @flow */

import React from "react";
import cn from "classnames";
import { useFormField } from "@awardit/formaggio";
import { Dropdown as UIDropdown, InputErrors } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

export type Value = string | number;

type Props = {
  name: string,
  children: React$Node,
  className?: string,
  placeholder?: string,
  onChange: (Value | null) => void,
  value: ?Value,
  variant?: string,
  disabled?: boolean,
  label?: string,
};

const Dropdown = ({ className, label, name, children, ...rest }: Props): React$Node => {
  const t = useTranslate();
  const { submitted, dirty, errors } = useFormField(name, "");

  const inputErrors = submitted || dirty ?
    errors.map(x => t(`VALIDATION.${x.error}`)) :
    [];

  return (
    <div
      className={cn(
        styles.block,
        className,
        { [styles.error]: inputErrors.length > 0 }
      )}
      style={inputErrors.length > 0 ? { "--border": "var(--negative)" } : {}}
    >
      {label && <label className={styles.label}>{label}</label>}
      <UIDropdown
        {...rest}
        className={styles.dropdown}
      >
        {children}
      </UIDropdown>
      {inputErrors && errors.length > 0 &&
        <InputErrors
          className={styles.inputErrors}
          errors={inputErrors}
        />
      }
    </div>
  );
};

export default Dropdown;
