/* @flow */

import type { FormDataValue } from "@awardit/formaggio/src/types";
import type { Gender, RegFormConfigField, DistrictItem } from "shop-state/types";

import React, { useContext, useMemo, useEffect, useState } from "react";
import { useData } from "crustate/react";
import cn from "classnames";
import inputStyles from "ui-components/Input.scss";
import { useTranslate } from "@awardit/react-use-translate";
import { SearchableDropdown, DropdownItem } from "@crossroads/ui-components";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import Field from "components/Field";
import { GENDERS } from "components/AccountView/ExtraCustomerInfo";
import Dropdown from "components/Dropdown";
import { formatAccountFormKey, getTranslationFromKey } from "helpers/forms";
import { MemberGroupIsOwnerData } from "data";
import { membergroupList } from "queries";

import styles from "./styles.scss";

type Props = {
  formFields: Array<{...RegFormConfigField, key: string }>,
  gender?: ?Gender | string,
  districtList: ?Array<DistrictItem>,
  district?: ?FormDataValue,
  retailer?: ?FormDataValue,
  country?: ?FormDataValue,
  setValue: (value: {[string]: FormDataValue}) => void,
};

const AccountInfo = ({
  formFields,
  gender,
  districtList,
  district,
  retailer,
  country,
  setValue }: Props): React$Node => {
  const { content: { address }, info: { countries, locale } } = useContext(StoreInfoContext);
  const t = useTranslate();
  const client = useClient();
  const userMemberGroupData = useData(MemberGroupIsOwnerData);
  const isOwner = userMemberGroupData.state === "LOADED" && userMemberGroupData.data.owner;

  const [memberGroupList, setMemberGroupList] = useState([]);

  useEffect(() => {
    client(membergroupList).then(({ membergroupList }) => {
      setMemberGroupList(membergroupList);
    });
  }, []);

  const sortedCountries = useMemo(() => {
    return countries.slice().sort((a, b) => {
      return a.name.localeCompare(
        b.name,
        locale.split("_")[0],
        { sensitivity: "base" }
      );
    });
  }, [countries, locale]);

  const genders = useMemo(() => GENDERS.map(x => ({
    code: x,
    name: t(`ACCOUNT.GENDER.${x.toUpperCase()}`),
  })), [t]);

  const setTitle = (field: {...RegFormConfigField, key: string }, altKey?: string) => {
    const title = (altKey === null || altKey === undefined) ?
      t(getTranslationFromKey(field.key)) :
      t(getTranslationFromKey(altKey));
    return `<p>${field.name ?? title} ${field.req === "required" ? "*" : ""}</p>`;
  };

  return (
    <div className={styles.accountForm}>
      {formFields.map((field, i) => {
        const labelText = setTitle(field);

        if (field.show === false || field.val === 3) {
          return null;
        }

        switch (field.key) {
          case "gender":
            return (
              <div key={`${field.key}-${i}`} className={styles.field}>
                {/* eslint-disable react/no-danger */}
                <div
                  dangerouslySetInnerHTML={{ __html: labelText }}
                  className={inputStyles.label}
                />
                {/* eslint-enable react/no-danger */}
                <Dropdown
                  key={`${field.key} - ${i}`}
                  placeholder={field.name}
                  className={styles.dropdown}
                  name={formatAccountFormKey(field.key)}
                  disabled={field.val === 1}
                  value={gender}
                  onChange={val => setValue({ [field.key]: val })}
                >
                  {genders.map((x, i) => (
                    <DropdownItem key={`${x.code} - ${i}`} value={x.code}>
                      {x.name}
                    </DropdownItem>
                  ))}
                </Dropdown>
              </div>
            );
          case "country":
            return (
              <div key={`${field.key}-${i}`} className={styles.field}>
                {/* eslint-disable react/no-danger */}
                <div
                  dangerouslySetInnerHTML={{ __html: labelText }}
                  className={inputStyles.label}
                />
                {/* eslint-enable react/no-danger */}
                <SearchableDropdown
                  placeholder={address.search ?? ""}
                  className={cn(styles.dropdown,
                    { [styles.dropdown__maxHeight]: sortedCountries.length > 5 }
                  )}
                  name={formatAccountFormKey(field.key)}
                  value={country !== null && country !== undefined ? country.toString() : null}
                  disabled={field.val === 1}
                  onChange={val => setValue({ [field.key]: val })}
                >
                  {sortedCountries.map((x, i) => (
                    <DropdownItem key={`${x.code} - ${i}`} value={x.code}>
                      {x.name}
                    </DropdownItem>
                  ))}
                </SearchableDropdown>
              </div>
            );
          case "reg3":
            if (districtList === null || districtList === undefined) {
              return null;
            }

            return (
              <div key={`${field.key}-${i}`} className={styles.dropdownField}>
                {/* eslint-disable react/no-danger */}
                <div
                  className={styles.label}
                  dangerouslySetInnerHTML={{ __html: labelText }}
                />
                {/* eslint-enable react/no-danger */}
                <Dropdown
                  className={cn(styles.dropdown,
                    { [styles.dropdown__maxHeight]: districtList.length > 5 }
                  )}
                  name={formatAccountFormKey(field.key)}
                  placeholder={t("REGISTER.DISTRICT")}
                  value={district !== null && district !== undefined ? district.toString() : null}
                  disabled={field.val === 1}
                  onChange={val => {
                    const value = val !== null ? val.toString() : "";
                    setValue({ "district": value });
                  }}
                >
                  {districtList.map((option, i) => (
                    <DropdownItem key={i} value={option.salesgroupid}>
                      {option.salesgroupid}
                    </DropdownItem>
                  ))}
                </Dropdown>
              </div>
            );
          case "retailer":
            const { options } = field;
            if (field.show === false || options === null || options.length <= 0) {
              return null;
            }

            return (
              <div key={`${field.key}-${i}`} className={styles.dropdownField}>
                {/* eslint-disable react/no-danger */}
                <div
                  className={styles.label}
                  dangerouslySetInnerHTML={{ __html: labelText }}
                />
                {/* eslint-enable react/no-danger */}
                <Dropdown
                  className={cn(styles.dropdown,
                    { [styles.dropdown__maxHeight]: options.length > 5 }
                  )}
                  name={formatAccountFormKey(field.key)}
                  placeholder={t("REGISTER.SHOP")}
                  value={retailer !== null && retailer !== undefined ? retailer.toString() : null}
                  disabled={field.val === 1}
                  onChange={val => setValue({ [field.key]: val })}
                >
                  {options.map((option, i) => (
                    <DropdownItem key={i} value={option.id}>
                      {option.name}
                    </DropdownItem>
                  ))}
                </Dropdown>
              </div>
            );
          default:
            return (
              <div key={`${field.key}-${i}`} className={styles.field}>
                {/* Prevent users in a membergroup that are not owners to be able to
                edit organization and company input fields, field.val === 1 means read only */}
                {/* eslint-disable react/no-danger */}
                <Field
                  key={`${field.key}-${i}`}
                  disabled={((memberGroupList.length > 0 && !isOwner) && (field.key === "orgnr" || field.key === "comp")) || field.val === 1}
                  name={formatAccountFormKey(field.key)}
                  maxLength={field.max !== 0 ? field.max : null}
                  label={<div dangerouslySetInnerHTML={{ __html: labelText }} />}
                  pattern={
                    (field.pattern !== null && field.pattern.length > 0) ? field.pattern : null
                  }
                />
                {/* eslint-enable react/no-danger */}
              </div>
            );
        }
      })}
    </div>
  );
};

export default AccountInfo;
