/* @flow */

import React from "react";

type QueryValue = string | Array<?string> | null;
type QueryObject = { [string]: QueryValue };

export const useParseTags = (params: QueryObject): ?Array<string> => {
  if (!params || !params.tags || params.tags === null || params.tags === undefined) {
    return null;
  }

  if (typeof params.tags === "string") {
    return params.tags.split(";");
  }
};
