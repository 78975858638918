/* @flow */

import React from "react";
import cn from "classnames";
import UnsuccessfulIcon from "icons/unsuccessful.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
  warningStyling: boolean,
  heading: string,
  body: string,
};

const SystemMessageBanner =
({ warningStyling, className, heading, body }: Props): React$Node => (
  <div className={cn(className, styles.block, { [styles.warning]: warningStyling })}>
    <div className={styles.top}>
      {warningStyling &&
        <UnsuccessfulIcon className={styles.icon} />
      }
      {heading &&
        <h2>{heading}</h2>
      }
    </div>
    {body && (
      <div className={styles.middle}>
        <p>{body}</p>
      </div>
    )}
  </div>
);

export default SystemMessageBanner;
