/* @flow */

import type { QuizItem } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

type Data =
| { state: "LOADING", data: ?Array<QuizItem> }
| { state: "LOADED", data: Array<QuizItem> }
| { state: "ERROR", error: string };

export type QuizEffectResponse =
Response<typeof QUIZ_EFFECT_RESPONSE, Array<QuizItem>>;

export type QuizEffectRequest = {
  tag: typeof QUIZ_EFFECT_REQUEST,
  id: number,
};

type QuizRequest = {
  tag: typeof QUIZ_REQUEST,
  id: number,
};

export const QUIZ_EFFECT_RESPONSE: "response/quiz_effect" = "response/quiz_effect";
export const QUIZ_EFFECT_REQUEST: "request/quiz_effect" = "request/quiz_effect";
export const QUIZ_REQUEST: "request/quiz" = "request/quiz";

export const getQuiz = (id: number): QuizRequest => ({
  tag: QUIZ_REQUEST,
  id,
});

export const QuizModel: Model<
  Data,
  { id: number },
  QuizEffectRequest | QuizEffectResponse | QuizRequest
> = {
  id: "quiz",
  init: ({ id }) =>
    updateData(
      { state: "LOADING", data: null },
      { tag: QUIZ_EFFECT_REQUEST, id }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: "Error in quiz state" });

      case QUIZ_REQUEST:
        return updateData({ state: "LOADING", data: state.state === "ERROR" ? null : state.data }, { tag: QUIZ_EFFECT_REQUEST, id: msg.id });

      case QUIZ_EFFECT_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
