/* @flow */

import type { TeampotItemRes, HistoryGroupedItem, TeamPotSettings, TeamPotStatus, TeamPotActivities, TeamPotHistoryItem } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

type GetHistoryItemsProps = {
  date: string,
};
type MembersData =
| { state: "LOADING", data: ?Array<TeampotItemRes> }
| { state: "LOADED", data: Array<TeampotItemRes> }
| { state: "ERROR", error: string };

type HistoryData =
| { state: "LOADING", data: ?Array<HistoryGroupedItem> }
| { state: "LOADED", data: Array<HistoryGroupedItem> }
| { state: "ERROR", error: string };

type TeampotHistoryData =
| { state: "LOADING", data: ?Array<TeamPotHistoryItem> }
| { state: "LOADED", data: Array<TeamPotHistoryItem> }
| { state: "ERROR", error: string };

type SettingsData =
| { state: "LOADING", data: ?TeamPotSettings }
| { state: "LOADED", data: TeamPotSettings }
| { state: "ERROR", error: string };

type StatusData =
| { state: "LOADING", data: ?TeamPotStatus }
| { state: "LOADED", data: TeamPotStatus }
| { state: "ERROR", error: string };

type ActivitiesData =
| { state: "LOADING", data: ?Array<TeamPotActivities> }
| { state: "LOADED", data: Array<TeamPotActivities> }
| { state: "ERROR", error: string };
export type TeampotMembersResponse =
  Response<typeof TEAMPOT_MEMBERS_RESPONSE, Array<TeampotItemRes>>;

export type TeampotMembersRequest = {
  tag: typeof TEAMPOT_MEMBERS_REQUEST,
};
export type TeampotHistoryResponse =
  Response<typeof TEAMPOT_HISTORY_RESPONSE, Array<HistoryGroupedItem>>;

export type TeampotHistoryRequest = {
  tag: typeof TEAMPOT_HISTORY_REQUEST,
};
export type TeampotHistoryItemsResponse =
  Response<typeof TEAMPOT_HISTORY_ITEMS_RESPONSE, Array<TeamPotHistoryItem>>;

export type TeampotHistoryItemsRequest = {
  tag: typeof TEAMPOT_HISTORY_ITEMS_REQUEST,
  date: string,
};
export type TeampotSettingsResponse =
  Response<typeof TEAMPOT_SETTINGS_RESPONSE, TeamPotSettings>;

export type TeampotSettingsRequest = {
  tag: typeof TEAMPOT_SETTINGS_REQUEST,
};
export type TeampotStatusResponse =
  Response<typeof TEAMPOT_STATUS_RESPONSE, TeamPotStatus>;
export type TeampotStatusRequest = {
  tag: typeof TEAMPOT_STATUS_REQUEST,
};

export type TeampotActivitiesResponse =
  Response<typeof TEAMPOT_ACTIVITIES_RESPONSE, Array<TeamPotActivities>>;
export type TeampotActivitiesRequest = {
  tag: typeof TEAMPOT_ACTIVITIES_REQUEST,
};

export const TEAMPOT_MEMBERS_RESPONSE: "response/teampot_members" = "response/teampot_members";
export const TEAMPOT_MEMBERS_REQUEST: "request/teampot_members" = "request/teampot_members";

export const TEAMPOT_HISTORY_RESPONSE: "response/teampot_history" = "response/teampot_history";
export const TEAMPOT_HISTORY_REQUEST: "request/teampot_history" = "request/teampot_history";

export const TEAMPOT_SETTINGS_RESPONSE: "response/teampot_settings" = "response/teampot_settings";
export const TEAMPOT_SETTINGS_REQUEST: "request/teampot_settings" = "request/teampot_settings";

export const TEAMPOT_STATUS_RESPONSE: "response/teampot_status" = "response/teampot_status";
export const TEAMPOT_STATUS_REQUEST: "request/teampot_status" = "request/teampot_status";

export const TEAMPOT_ACTIVITIES_RESPONSE: "response/teampot_activities" = "response/teampot_activities";
export const TEAMPOT_ACTIVITIES_REQUEST: "request/teampot_activities" = "request/teampot_activities";

export const TEAMPOT_HISTORY_ITEMS_RESPONSE: "response/teampot_history_items" = "response/teampot_history_items";
export const TEAMPOT_HISTORY_ITEMS_REQUEST: "request/teampot_history_items" = "request/teampot_history_items";

export const getTeampotMembers = (): TeampotMembersRequest => ({
  tag: TEAMPOT_MEMBERS_REQUEST,
});

export const getTeampotHistory = (): TeampotHistoryRequest => ({
  tag: TEAMPOT_HISTORY_REQUEST,
});

export const getTeampotSettings = (): TeampotSettingsRequest => ({
  tag: TEAMPOT_SETTINGS_REQUEST,
});

export const getTeampotStatus = (): TeampotStatusRequest => ({
  tag: TEAMPOT_STATUS_REQUEST,
});

export const TeampotMembersModel: Model<
  MembersData,
  {},
  TeampotMembersResponse |
  TeampotMembersRequest
> = {
  id: "teampot-members",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      { tag: TEAMPOT_MEMBERS_REQUEST }),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });
      case TEAMPOT_MEMBERS_REQUEST:
        return updateData({ state: "LOADING", data: state.state === "ERROR" ? null : state.data }, { tag: TEAMPOT_MEMBERS_REQUEST });
      case TEAMPOT_MEMBERS_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};

export const getHistoryItems = ({ date }: GetHistoryItemsProps): TeampotHistoryItemsRequest => ({
  tag: TEAMPOT_HISTORY_ITEMS_REQUEST,
  date,
});

export const TeampotHistoryItemsModel: Model<
  TeampotHistoryData,
  {date: string},
  TeampotHistoryItemsResponse |
  TeampotHistoryItemsRequest
> = {
  id: "teampot-history-items",
  init: ({ date }) =>
    updateData(
      { state: "LOADING", data: null },
      { tag: TEAMPOT_HISTORY_ITEMS_REQUEST, date }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: "Error in quiz state" });

      case TEAMPOT_HISTORY_ITEMS_REQUEST:
        return updateData({ state: "LOADING", data: state.state === "ERROR" ? null : state.data }, { tag: TEAMPOT_HISTORY_ITEMS_REQUEST, date: msg.date ?? "" });

      case TEAMPOT_HISTORY_ITEMS_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};

export const TeampotHistoryModel: Model<
  HistoryData,
  {},
  TeampotHistoryResponse |
  TeampotHistoryRequest
> = {
  id: "teampot-history",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      { tag: TEAMPOT_HISTORY_REQUEST }),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });
      case TEAMPOT_HISTORY_REQUEST:
        return updateData({ state: "LOADING", data: state.state === "ERROR" ? null : state.data }, { tag: TEAMPOT_HISTORY_REQUEST });
      case TEAMPOT_HISTORY_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};

export const TeampotSettingsModel: Model<
  SettingsData,
  {},
  TeampotSettingsResponse |
  TeampotSettingsRequest
> = {
  id: "teampot-settings",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      { tag: TEAMPOT_SETTINGS_REQUEST }),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });
      case TEAMPOT_SETTINGS_REQUEST:
        return updateData({ state: "LOADING", data: state.state === "ERROR" ? null : state.data }, { tag: TEAMPOT_SETTINGS_REQUEST });
      case TEAMPOT_SETTINGS_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};

export const TeampotStatusModel: Model<
  StatusData,
  {},
  TeampotStatusResponse |
  TeampotStatusRequest
> = {
  id: "teampot-status",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      { tag: TEAMPOT_STATUS_REQUEST }),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });
      case TEAMPOT_STATUS_REQUEST:
        return updateData({ state: "LOADING", data: state.state === "ERROR" ? null : state.data }, { tag: TEAMPOT_STATUS_REQUEST });
      case TEAMPOT_STATUS_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};

export const TeampotActivitiesModel: Model<
  ActivitiesData,
  {},
  TeampotActivitiesResponse |
  TeampotActivitiesRequest
> = {
  id: "teampot-activities",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      { tag: TEAMPOT_ACTIVITIES_REQUEST }),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });
      case TEAMPOT_ACTIVITIES_REQUEST:
        return updateData({ state: "LOADING", data: state.state === "ERROR" ? null : state.data }, { tag: TEAMPOT_ACTIVITIES_REQUEST });
      case TEAMPOT_ACTIVITIES_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
