/* @flow */

import { useEffect, useState } from "react";
import useBrowserDimensions from "helpers/use-browser-dimensions";

const useIsSmall = (small: number | string): boolean => {
  const dimensions = useBrowserDimensions();
  const [isSmall, setIsSmall] = useState(false);

  useEffect(() => {
    const v = typeof small === "string" ?
      Number.parseInt(small, 10) :
      small;

    setIsSmall(dimensions.width < v);
  }, [dimensions, small]);

  return isSmall;
};

export default useIsSmall;
