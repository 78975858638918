/* @flow */

import React from "react";
import { Link } from "react-router-dom";
import { Button as ButtonComponent } from "@crossroads/ui-components";

type Props = {
  children: React$Node,
  to?: string,
  className?: string,
  disabled?: boolean,
};

const Button = ({ to = "", children, className, ...props }: Props): React$Node => {
  const disabled = typeof props.disabled !== "undefined" && props.disabled;

  if (to.length > 0 && !disabled) {
    return (
      /^https?:\/\//.test(to) || /^mailto?:/.test(to) || /^tel?:/.test(to) ?
        <a {...props} className={className} href={to}>
          <ButtonComponent {...props}>
            {children}
          </ButtonComponent>
        </a> :
        <Link to={to} style={{ textDecoration: "none" }}>
          <ButtonComponent {...props} className={className}>
            {children}
          </ButtonComponent>
        </Link>
    );
  }

  return <ButtonComponent {...props} className={className}>{children}</ButtonComponent>;
};

export default Button;
