/* @flow */

import type { PaginatedNewsPosts } from "shop-state/types";

import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

type Data =
  | { state: "LOADING", data: ?PaginatedNewsPosts }
  | { state: "LOADED", data: PaginatedNewsPosts }
  | { state: "ERROR", error: mixed };

export type NewsPostsResponse = Response<typeof NEWS_POSTS_RESPONSE, ?PaginatedNewsPosts>;

export type NewsPostsRequest = {
  tag: typeof NEWS_POSTS_REQUEST,
  page: number,
  size: number,
};

export const NEWS_POSTS_RESPONSE: "response/news_posts" = "response/news_posts";
export const NEWS_POSTS_REQUEST: "request/news_posts" = "request/news_posts";

export const getNewsPosts = (page: number, size: number): NewsPostsRequest => ({
  tag: NEWS_POSTS_REQUEST,
  page,
  size,
});

export const NewsPostsModel: Model<Data, {
  page: number,
  size: number,
}, NewsPostsRequest | NewsPostsResponse | EffectErrorMessage> = {
  id: "news_posts",
  init: ({ page, size }) =>
    updateData(
      { state: "LOADING", data: null },
      getNewsPosts(page, size)
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });
      case NEWS_POSTS_REQUEST:
        return updateData({ state: "LOADING", data: state.state !== "ERROR" ? state.data : null });
      case NEWS_POSTS_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
