/* @flow */

import type { Form } from "shop-state/types";

import React, { useState, useEffect, useContext } from "react";
import Wrapper from "components/Wrapper";
import Breadcrumbs from "components/Breadcrumbs";
import FormComponent from "components/FormComponent";
import LeadsInfo from "components/LeadsInfo";
import ErrorView from "components/ErrorView";
import useDevice from "helpers/use-device";
import { Helmet } from "react-helmet-async";
import { useData, useSendMessage } from "crustate/react";
import { addMessage } from "state/messages";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { postFormData } from "queries";
import { getFormRows } from "state/forms";
import { AllFormsData, FormRowsData } from "data";

import styles from "./styles.scss";

const LeadsView = (): React$Node => {
  const findForm = (forms: Array<Form>, formName: string) => {
    const form = forms.find(f => f.formname.includes(formName));

    if (form !== undefined && form !== null) {
      const currentDate = new Date();
      const validToDate = new Date(form.validto);

      if (currentDate.getTime() <= validToDate.getTime()) {
        return form;
      }
    }

    return null;
  };

  const isDesktop = useDevice("gt", 1056);
  const { routes } = useContext(StoreInfoContext);
  const allFormsData = useData(AllFormsData);
  const formData = useData(FormRowsData);
  const sendMessage = useSendMessage();
  const client = useClient();
  const [state, setState] = useState({});

  const rowData = formData.state === "LOADED" ? formData.data : [];
  const leadsForm = allFormsData.state === "LOADED" &&
    allFormsData.data &&
    allFormsData.data.length > 0 ?
    findForm(allFormsData.data, "Leads") : null;

  const leadsFormId = leadsForm?.id;
  const leadsFormText = leadsForm?.formtext;

  const formatObject = (obj: {}) => {
    if (typeof obj === "object" && obj !== null) {
      const keys = Object.keys(obj);
      const res = {
        id: leadsFormId?.toString(),
        values: [],
      };

      for (const key of keys) {
        if (obj[key]) {
          res.values.push(`${key}:${obj[key]}`);
        }
      }

      return res;
    }
  };

  useEffect(() => {
    if (leadsFormId !== null && leadsFormId !== undefined) {
      sendMessage(getFormRows(leadsFormId));
    }
  }, [leadsFormId]);

  const postForm = async () => {
    const data = formatObject(state);

    if ((data?.id) && (data.values.length > 0 || data.values)) {
      const response = await client(postFormData, { form: {
        id: data.id,
        values: data.values,
      } });

      if (response.postFormData.result === false) {
        sendMessage(addMessage(`POST_FORM_ERROR`, "error"));
      }
      else {
        sendMessage(addMessage(`POST_FORM_SUCCESS`, "success"));
        setState({});
      }
    }
  };

  return (
    <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
      <Helmet
        title={(routes.leadsView && routes.leadsView.title) ?? ""}
      />
      {leadsFormId === null || leadsFormId === undefined ?
        <ErrorView /> :
        <>
          <Breadcrumbs current={(routes.leadsView && routes.leadsView.title) ?? ""} />
          {/* eslint-disable react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: leadsFormText }} />
          {/* eslint-enable react/no-danger */}
          <div className={styles.content}>
            {isDesktop ?
              <>
                <FormComponent
                  state={state}
                  setState={x => setState(x)}
                  formData={rowData}
                  postForm={postForm}
                  className={styles.form}
                />
                <LeadsInfo className={styles.info} />
              </> :
              <>
                <LeadsInfo className={styles.info} />
                <FormComponent
                  state={state}
                  setState={x => setState(x)}
                  formData={rowData}
                  postForm={postForm}
                  className={styles.form}
                />
              </>
            }
          </div>
        </>
      }
    </Wrapper>
  );
};

export default LeadsView;
