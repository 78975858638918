/* @flow */

import type { CurrentPageInfoPageType, InfoBlock } from "shop-state/types";
import type { Model } from "crustate";

import { updateData, updateNone } from "crustate";

type CurrentInfoData = {
  allproductsview?: $ReadOnlyArray<InfoBlock>,
  homeview?: $ReadOnlyArray<InfoBlock>,
  offerlistview?: $ReadOnlyArray<InfoBlock>,
  redeemview?: $ReadOnlyArray<InfoBlock>,
  earnview?: $ReadOnlyArray<InfoBlock>,
  newsview?: $ReadOnlyArray<InfoBlock>,
  currentinfo1?: $ReadOnlyArray<InfoBlock>,
  currentinfo2?: $ReadOnlyArray<InfoBlock>,
  currentinfo3?: $ReadOnlyArray<InfoBlock>,
  currentinfo4?: $ReadOnlyArray<InfoBlock>,
  currentinfo5?: $ReadOnlyArray<InfoBlock>,
  currentinfo6?: $ReadOnlyArray<InfoBlock>,
};

type Data =
  | { state: "INIT", data: null }
  | { state: "LOADING", data: CurrentInfoData | null }
  | { state: "LOADED", data: CurrentInfoData }
  | { state: "ERROR", data: null, error: string };

export type CurrentPageInfoResponse = {|
  data: $ReadOnlyArray<InfoBlock>,
  page: CurrentPageInfoPageType,
  tag: typeof CURRENT_PAGE_INFO_RESPONSE,
|} | {|
  tag: typeof CURRENT_PAGE_INFO_RESPONSE,
  data: null,
  error: string,
|};

export type CurrentPageInfoRequest = {
  tag: typeof CURRENT_PAGE_INFO_REQUEST,
  page: CurrentPageInfoPageType,
  prio?: string,
};

export const CURRENT_PAGE_INFO_RESPONSE: "response/current_page_info" = "response/current_page_info";
export const CURRENT_PAGE_INFO_REQUEST: "request/current_page_info" = "request/current_page_info";

export const getCurrentPageInfo = (
  page: CurrentPageInfoPageType,
  prio?: string
): CurrentPageInfoRequest => ({
  tag: CURRENT_PAGE_INFO_REQUEST,
  page,
  prio,
});

export const CurrentPageInfoModel: Model<
  Data,
  {},
  CurrentPageInfoRequest |
  CurrentPageInfoResponse
> = {
  id: "curent_page_info",
  init: () =>
    updateData(
      { state: "INIT", data: null }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case CURRENT_PAGE_INFO_REQUEST:
        return updateData({ state: "LOADING", data: state.data }, msg);
      case CURRENT_PAGE_INFO_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", data: null, error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: {
            ...state.data,
            [msg.page.toLowerCase()]: msg.data,
          } });
        }

        return updateNone();
      default:
    }
  },
};
