/* @flow */

/*
 * Extending quote and adding Awardit set point action
 */

import type { Quote } from "shop-state/types";

import type { Model } from "crustate";
import type { Data as QuoteData, QuoteRequest as Request, QuoteResponse as Response } from "@crossroads/shop-state/quote";

import { updateData, updateNone } from "crustate";
import { QuoteModel as BaseModel } from "@crossroads/shop-state/quote";
import { pointsPriceByID } from "helpers/points";

export type QuoteRequest = Request | {
  +tag: typeof QUOTE_SET_DISCOUNT_CODE_REQUEST,
  +code: string,
} | {
  +tag: typeof QUOTE_REMOVE_DISCOUNT_CODE_REQUEST,
} | {
  +tag: typeof QUOTE_SET_POINTS_PAYMENT_REQUEST,
  +id: string,
  +points: number,
};

export type QuoteResponse = Response | {
  +tag: typeof QUOTE_SET_DISCOUNT_CODE_RESPONSE,
  +data: Quote,
} | {
  +tag: typeof QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE,
  +data: Quote,
} | {
  +tag: typeof QUOTE_SET_POINTS_PAYMENT_RESPONSE,
  +data: Quote,
};

export type Data = QuoteData
  | {| +state: typeof QUOTE_SETTING_DISCOUNT_CODE, +data: Quote |}
  | {| +state: typeof QUOTE_REMOVING_DISCOUNT_CODE, +data: Quote |}
  | {| +state: typeof QUOTE_SETTING_POINTS_PAYMENT, +data: Quote |};

export const QUOTE_SET_DISCOUNT_CODE_REQUEST: "quote/set_discount_code/request" = "quote/set_discount_code/request";
export const QUOTE_SET_DISCOUNT_CODE_RESPONSE: "quote/set_discount_code/response" = "quote/set_discount_code/response";

export const QUOTE_REMOVE_DISCOUNT_CODE_REQUEST: "quote/remove_discount_code/request" = "quote/remove_discount_code/request";
export const QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE: "quote/remove_discount_code/response" = "quote/remove_discount_code/response";

export const QUOTE_SET_POINTS_PAYMENT_REQUEST: "quote/set_points_payment/request" = "quote/set_points_payment/request";
export const QUOTE_SET_POINTS_PAYMENT_RESPONSE: "quote/set_points_payment/response" = "quote/set_points_payment/response";

export const QUOTE_SETTING_DISCOUNT_CODE: "SETTING_DISCOUNT_CODE" = "SETTING_DISCOUNT_CODE";
export const QUOTE_REMOVING_DISCOUNT_CODE: "REMOVING_DISCOUNT_CODE" = "REMOVING_DISCOUNT_CODE";
export const QUOTE_SETTING_POINTS_PAYMENT: "SETTING_POINTS_PAYMENT" = "SETTING_POINTS_PAYMENT";

export const setQuoteDiscountCode = (code: string): {|
  tag: typeof QUOTE_SET_DISCOUNT_CODE_REQUEST,
  code: string,
|} => ({
  tag: QUOTE_SET_DISCOUNT_CODE_REQUEST,
  code,
});

export const removeQuoteDiscountCode = (): {|
  tag: typeof QUOTE_REMOVE_DISCOUNT_CODE_REQUEST,
|} => ({
  tag: QUOTE_REMOVE_DISCOUNT_CODE_REQUEST,
});

export const setPointsPayment = (id: string, points?: number): {|
  tag: typeof QUOTE_SET_POINTS_PAYMENT_REQUEST,
  id: string,
  points: void | number,
|} => ({
  tag: QUOTE_SET_POINTS_PAYMENT_REQUEST,
  id,
  points,
});

export const QuoteModel: Model<Data, {}, QuoteRequest | QuoteResponse> = {
  id: "quote",
  init: BaseModel.init,
  update: (state, msg) => {
    switch (msg.tag) {
      case QUOTE_SET_DISCOUNT_CODE_REQUEST:
        if (state.state === "LOADED") {
          return updateData({
            state: QUOTE_SETTING_DISCOUNT_CODE,
            data: (state.data: Quote),
          }, msg);
        }

        console.error("Invalid state transition");
        return updateNone();

      case QUOTE_REMOVE_DISCOUNT_CODE_REQUEST:
        if (state.state === "LOADED") {
          return updateData({
            state: QUOTE_REMOVING_DISCOUNT_CODE,
            data: (state.data: Quote),
          }, msg);
        }

        console.error("Invalid state transition");
        return updateNone();

      case QUOTE_SET_POINTS_PAYMENT_REQUEST:
        if (state.state !== "LOADING") {
          return updateData({
            state: QUOTE_SETTING_POINTS_PAYMENT,
            data: state.data,
          }, msg);
        }

        console.error("Invalid state transition: Cannot set point payment without a loaded quote");
        return updateNone();
      case QUOTE_SET_DISCOUNT_CODE_RESPONSE:
        if (state.state === QUOTE_SETTING_DISCOUNT_CODE) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        console.error("Invalid state transition");
        return updateNone();

      case QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE:
        if (state.state === QUOTE_REMOVING_DISCOUNT_CODE) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        console.error("Invalid state transition");
        return updateNone();

      case QUOTE_SET_POINTS_PAYMENT_RESPONSE:
        return updateData({ state: "LOADED", data: msg.data });

      default:
        if (state.state !== QUOTE_SETTING_DISCOUNT_CODE &&
          state.state !== QUOTE_SETTING_POINTS_PAYMENT &&
          state.state !== QUOTE_REMOVING_DISCOUNT_CODE) {
          return BaseModel.update(state, msg);
        }
    }
  },
};

export const AWARDIT_MIN_PERCENT_BY_POINTS = 50;

export const minPoints = (q: ?Quote): number => {
  if (!q) {
    return 0;
  }

  const availablePointPayment = pointsPriceByID(q.availablePointPayments, "awardit");

  if (!availablePointPayment) {
    return 0;
  }

  return availablePointPayment.points.min.exVat;
};

export const getQuoteData = (d: Data): ?Quote => {
  return d.data || null;
};
