/* @flow */

import type { RegFormConfig } from "shop-state/types";

export const createArrayFromObj = (obj: RegFormConfig): Array<any> => {
  const arr = [];
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      arr.push({ key, ...obj[key] });
    }
  }

  return arr;
};

export const mapKeysToQueryParams = (key: string): string => {
  switch (key) {
    case "fname":
      return "first_name";
    case "lname":
      return "last_name";
    case "email":
      return "email";
    case "cell":
      return "phone";
    case "comp":
      return "company_name";
    case "orgnr":
      return "company_number";
    case "street":
      return "company_street_nr";
    case "zip":
      return "company_postal_code";
    case "city":
      return "company_city";
    case "tmih":
      return "totmemberinhouse";
    case "reg3":
      return "district";
    case "pnr":
      return "personnummer";
    default:
      return key;
  }
};

/* https://awardit.atlassian.net/l/cp/WtJzPqdb */

export const getTranslationFromKey = (key: string): string => {
  switch (key) {
    case "fname":
      return "REGISTER.FIRSTNAME";
    case "lname":
      return "REGISTER.LASTNAME";
    case "email":
      return "REGISTER.EMAIL";
    case "cell":
      return "REGISTER.PHONE";
    case "comp":
      return "REGISTER.COMPANY";
    case "orgnr":
      return "REGISTER.ORG_NUMBER";
    case "tmih":
      return "REGISTER.TOTAL_MEMBERS";
    case "street":
      return "REGISTER.STREET_ADDRESS";
    case "zip":
      return "REGISTER.ZIPCODE";
    case "city":
      return "REGISTER.CITY";
    case "care":
    case "care2":
      return "REGISTER.OTHER";
    case "pnr":
      return "REGISTER.PNR";
    case "gender":
      return "REGISTER.GENDER";
    case "muid":
      return "REGISTER.USER_ID";
    case "retailer":
      return "REGISTER.SHOP";
    case "reg3":
      return "REGISTER.DISTRICT";
    case "country":
      return "REGISTER.COUNTRY";
    case "birth":
      return "REGISTER.DATE_OF_BIRTH";
    case "passw":
      return "REGISTER.PASSWORD";
    case "passwCheck":
      return "REGISTER.REPEAT_PASSWORD";
    case "accept":
      return "REGISTER.TERMS";
    default:
      return key;
  }
};

export const formatRegisterFormRequest = (obj: {...}): { [key: string]: any } => {
  const newObj = {};
  for (const key in obj) {
    if (obj[key]) {
      let newKey;
      switch (key) {
        case "key-fname":
          newKey = "firstname";
          break;
        case "key-lname":
          newKey = "lastname";
          break;
        case "key-email":
          newKey = "emailid1";
          break;
        case "key-cell":
          newKey = "cellphonenum";
          break;
        case "key-comp":
          newKey = "company";
          break;
        case "key-orgnr":
          newKey = "organisationsnummer";
          break;
        case "key-tmih":
          newKey = "totmemberinhouse";
          break;
        case "key-street":
          newKey = "streetaddress";
          break;
        case "key-zip":
          newKey = "zipcode";
          break;
        case "key-city":
          newKey = "streetcode";
          break;
        case "key-care":
          newKey = "careof";
          break;
        case "key-pnr":
          newKey = "personnummer";
          break;
        case "key-gender":
          newKey = "gender";
          break;
        case "key-muid":
          newKey = "memberuserid";
          break;
        case "key-retailer":
          newKey = "regioncode2";
          break;
        case "key-reg3":
          newKey = "district";
          break;
        case "key-country":
          newKey = "country";
          break;
        case "key-birth":
          newKey = "birth";
          break;
        case "key-passw":
          newKey = "password";
          break;
        case "key-passwCheck":
          newKey = "passwordCheck";
          break;
        case "key-accept":
          newKey = "terms";
          break;
        default:
          newKey = null;
          break;
      }

      if (newKey !== null) {
        newObj[newKey] = obj[key];
      }
    }
  }

  return newObj;
};

export const formatAccountFormKey = (key: string): string => {
  switch (key) {
    case "fname":
      return "firstname";
    case "lname":
      return "lastname";
    case "cell":
      return "cellphonenum";
    case "comp":
      return "company";
    case "tmih":
      return "totmemberinhouse";
    case "care":
      return "careof";
    case "birth":
      return "dob";
    case "muid":
      return "memberuserid";
    case "passw":
      return "password";
    case "reg3":
      return "district";
    case "pnr":
      return "ssn";
    default:
      return key;
  }
};
