/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import cn from "classnames";
import { Foldable } from "@crossroads/ui-components";
import Spinner from "components/Spinner";
import ChevronIcon from "icons/chevron-mini.svg";
import type { LeaderboardFlags, LeaderboardItem } from "shop-state/types";

import styles from "./styles.scss";

type TableSmallProps = {
  items: $ReadOnlyArray<LeaderboardItem>,
  onClick: LeaderboardItem => void,
  current?: string | null,
  flags: LeaderboardFlags,
  missingDataMessage: React$Node | null,
};

const TableSmallHead = ({ flags }: { flags: LeaderboardFlags }) => {
  const { content: { leaderboardview } } = useContext(StoreInfoContext);
  return (
    <div className={styles.thead}>
      {leaderboardview.placementTableHeader &&
        <div className={styles.th}>{leaderboardview.placementTableHeader}</div>
      }
      {!flags && Boolean(leaderboardview.nameIsEnabled) && (
        <div className={styles.th}>{leaderboardview.nameTableHeader ?? ""}</div>
      )}
      {!flags && Boolean(leaderboardview.companyIsEnabled) && (
        <div className={styles.th}>{leaderboardview.companyTableHeader ?? ""}</div>
      )}
      {flags && flags.showname && (
        <div className={styles.th}>{leaderboardview.nameTableHeader ?? ""}</div>
      )}
      {flags && flags.showcompany && (
        <div className={styles.th}>{leaderboardview.companyTableHeader ?? ""}</div>
      )}
      <div className={styles.th_spacer} />
    </div>
  );
};

const TableSmall =
({ items, onClick, current, flags, missingDataMessage }: TableSmallProps): React$Node => {
  const { content: { leaderboardview } } = useContext(StoreInfoContext);

  return (
    <div className={cn(styles.table, styles.tableSmall)}>
      <TableSmallHead flags={flags} />
      <div className={styles.tbody}>
        {items.map((x, i) => (
          <React.Fragment key={`${i}-${x.name}`}>
            <div
              className={cn(styles.tr, { [styles.open]: current === x.name })}
              onClick={() => {
                onClick(x);
              }}
            >
              <div>{x.position}</div>
              {!flags && Boolean(leaderboardview.nameIsEnabled) && (
                <div className={styles.name}>{x.name}</div>
              )}
              {!flags && Boolean(leaderboardview.companyIsEnabled) && (
                <div className={styles.company}>{x.company}</div>
              )}
              {flags && flags.showname && (
                <div className={styles.name}>{x.name}</div>
              )}
              {flags && flags.showcompany && (
                <div className={styles.company}>{x.company}</div>
              )}
              <div className={styles.chevron}>
                <ChevronIcon />
              </div>
            </div>
            <Foldable open={current === x.name} className={styles.expandable}>
              <table>
                <tbody>
                  <tr>
                    <td>{leaderboardview.placementTableHeader ?? ""}</td>
                    <td>{x.position}</td>
                  </tr>
                  {!flags && Boolean(leaderboardview.nameIsEnabled) && (
                    <tr>
                      <td>{leaderboardview.nameTableHeader ?? ""}</td>
                      <td>{x.name}</td>
                    </tr>
                  )}
                  {!flags && Boolean(leaderboardview.companyIsEnabled) && (
                    <tr>
                      <td>{leaderboardview.companyTableHeader ?? ""}</td>
                      <td>{x.company ? x.company : "-"}</td>
                    </tr>
                  )}
                  {flags && flags.showname && (
                    <tr>
                      <td>{leaderboardview.nameTableHeader ?? ""}</td>
                      <td>{x.name}</td>
                    </tr>
                  )}
                  {flags && flags.showavatar && (
                    <tr>
                      <td>{leaderboardview.avatarTableHeader ?? ""}</td>
                      <td>
                        <img
                          className={styles.avatarImg}
                          src={x.avatar !== null ? x.avatar : "/avatars/avatar.jpg"}
                          alt="user-avatar" />
                      </td>
                    </tr>
                  )}
                  {flags && flags.showcompany && (
                    <tr>
                      <td>{leaderboardview.companyTableHeader ?? ""}</td>
                      <td>{x.company ? x.company : "-"}</td>
                    </tr>
                  )}
                  <tr>
                    <td>{leaderboardview.pointsTableHeader ?? ""}</td>
                    <td>{x.points}</td>
                  </tr>
                </tbody>
              </table>
            </Foldable>
          </React.Fragment>
        ))}
      </div>

      {(missingDataMessage !== null) && (
        <div className={styles.missingData}>
          <p>{missingDataMessage}</p>
        </div>
      )}

      <div className={styles.spacer} />
    </div>
  );
};

export const TableSmallHint = (): React$Node => (
  <div className={cn(styles.tableHint, styles.tableHintSmall)}>
    <Spinner />
  </div>
);

export default TableSmall;
