/* @flow */

import type { Model } from "crustate";
import { updateData } from "crustate";

export type Mode =
  "normal" |
  "filter" |
  "categories" |
  "brands" |
  "search" |
  "cart" |
  "language" |
  "redeem" |
  "recruit" |
  "tink" |
  "cookie_consent" |
  "earn";

export type ViewModeRequest = {
  tag: typeof VIEW_MODE_REQUEST,
  mode: Mode,
};

export const MODE = {
  NORMAL: "normal",
  FILTER: "filter",
  CATEGORIES: "categories",
  BRANDS: "brands",
  SEARCH: "search",
  CART: "cart",
  LANGUAGE: "language",
  REDEEM: "redeem",
  RECRUIT: "recruit",
  TINK: "tink",
  COOKIE_CONSENT: "cookie_consent",
  EARN: "earn",
};

export const VIEW_MODE_REQUEST: "request/view_mode" = "request/view_mode";

export const setMode = (mode: Mode): ViewModeRequest => ({
  tag: VIEW_MODE_REQUEST,
  mode,
});

const initialState = MODE.NORMAL;

export const ViewModeModel: Model<Mode, {}, ViewModeRequest> = {
  id: "view_mode",
  init: () => updateData(initialState),
  update: (state, msg) => {
    switch (msg.tag) {
      case VIEW_MODE_REQUEST:
        return updateData(msg.mode);
      default:
    }
  },
};
