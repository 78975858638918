/* @flow */

import type { ProductCardProduct } from "shop-state/types";

import React, { memo } from "react";
import { useData } from "crustate/react";
import cn from "classnames";
import { useVisibilityObservable } from "@crossroads/ui-components";
import ProductCard, { DummyCard } from "components/ProductCard";
import { CustomerData } from "data";

import styles from "./styles.scss";

type Props = {
  children?: React$Node,
  products?: $ReadOnlyArray<?ProductCardProduct>,
  listName?: string,
  heading?: string,
  productsPerRow?: 4 | 2,
  breadcrumbLink?: {
    search?: string,
    brand?: string,
  },
  loading?: boolean,
};

type ItemProps = {
  product: ProductCardProduct,
  idx?: number,
  loading?: boolean,
  listName?: string,
};

type DummyItemProps = {
  idx?: number,
};

export const ListItem: React$AbstractComponent<ItemProps, mixed> =
memo<ItemProps>(({ product, idx, listName, loading }: ItemProps) => {
  const el = useVisibilityObservable();

  return (
    <div ref={el} className={cn("awardit-listItem", styles.item)} data-idx={idx}>
      <div className={cn("awardit-productCardListItem", styles.itemInner)}>
        {typeof loading !== "undefined" && loading === true ?
          <DummyCard /> :
          <ProductCard product={product} list={listName} position={idx} />
        }
      </div>
    </div>
  );
});

export const ListItemDummy: React$AbstractComponent<DummyItemProps, mixed> =
memo<DummyItemProps>(({ idx }: DummyItemProps) => {
  const el = useVisibilityObservable();

  return (
    <div ref={el} className={styles.item} data-idx={idx}>
      <div className={styles.itemInner}>
        <DummyCard />
      </div>
    </div>
  );
});

const ProductList = ({
  products,
  children,
  heading,
  productsPerRow = 4,
  listName,
  loading }: Props): React$Node => {
  const customer = useData(CustomerData);
  const memberTargetList = customer.state === "LOGGED_IN" &&
  customer.data &&
  customer.data.memberTargetList &&
  customer.data.memberTargetList.list.length > 0 ?
    customer.data.memberTargetList.list :
    [];

  return (
    <div>
      {heading &&
        <h2>{heading}</h2>
      }
      <div className={cn(
        styles.block,
        [styles["block__perRow" + productsPerRow]]
      )}
      >
        {products ? products.filter(p =>
          !memberTargetList ||
          memberTargetList.includes(p?.attributes.awarditTargetId) ||
          !p?.attributes.awarditTargetId)
          .map((x, i) =>
            x ?
              <ListItem key={x.sku} product={x} listName={listName} loading={loading} idx={i} /> :
              <ListItemDummy key={i} />
          ) : children}
      </div>
    </div>
  );
};

export default ProductList;
