/* @flow */

import type { FormData } from "@awardit/formaggio/src/types";
import type { PostQuizAnswerData, QuizAnswerItem, QuizItem } from "shop-state/types";

import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useData, useSendMessage } from "crustate/react";
import { addMessage } from "state/messages";
import { QuizData, QuizListData, QuizHistoryListData } from "data";
import { getQuizHistoryList } from "state/quiz-history";
import { postQuizAnswer } from "queries";
import { useClient, StoreInfoContext } from "entrypoint/shared";

import SingleView from "./SingleView";
import MultipleView from "./MultipleView";
import LoadingView from "components/LoadingView";
import Wrapper from "components/Wrapper";
import Breadcrumbs from "components/Breadcrumbs";
import HeroIntro from "components/HeroIntro";

import styles from "./styles.scss";
export type QuizViewProps = {
  currentQuizId: number,
};

const QuizView = ({ currentQuizId }: QuizViewProps): React$Node => {
  const [answers, setAnswers] = useState<Array<QuizAnswerItem>>([]);
  const quizData = useData(QuizData);
  const quizListData = useData(QuizListData);
  const [quiz, setQuiz] = useState<Array<QuizItem>>([]);
  const { location, push } = useHistory();
  const quizShowMode = new URLSearchParams(location.search).get("type");
  const [state, setState] = useState<FormData>({});
  const [step, setStep] = useState<number>(1);
  const sendMessage = useSendMessage();
  const client = useClient();
  const currentQuestion = quiz.length >= step ? quiz[step - 1] : null;
  const { routes, content: { heroIntroView } } = useContext(StoreInfoContext);
  const quizHistoryListData = useData(QuizHistoryListData);
  const [quizLoading, setQuizLoading] = useState<boolean>(false);
  const quizHistoryUrl = routes.quizHistory && routes.quizHistory.url ? routes.quizHistory.url : "";
  const quizHistoryTitle = routes.quizHistory && routes.quizHistory.title ? routes.quizHistory.title : "";

  const currentQuiz = quizListData &&
  quizListData.state === "LOADED" &&
  quizListData.data.length > 0 ?
    quizListData.data.find(elem => parseInt(elem.id, 10) === currentQuizId) :
    null;

  useEffect(() => {
    if (quizData.state === "LOADED") {
      if (quizShowMode && quizShowMode.includes("RANDOM")) {
        setQuiz(quizData.data.sort(() => Math.random() - 0.5));
      }
      else {
        setQuiz(quizData.data);
      }
    }
  }, [quizData]);

  useEffect(() => {
    setTimeout(() => {
      if (answers.length > 0 && answers.length === quiz.length) {
        const currentQuizHistory = quizHistoryListData.state === "LOADED" &&
        quizHistoryListData.data.length > 0 ?
          quizHistoryListData.data.find(elem =>
            elem.offerid === currentQuizId) :
          null;
        const quizhistoryUrl = currentQuizHistory && `${quizHistoryUrl}/${currentQuizId}/${currentQuizHistory.id}`;

        push(quizhistoryUrl ? quizhistoryUrl : quizHistoryUrl);
        setQuizLoading(true);
      }
    }, 2000);
  }, [quizHistoryListData, sendMessage]);

  const setOptionsAnswer = (x: Array<string>, id: number) => {
    const answerIndex = answers.findIndex(answer => answer.id === id);
    const newAnswers = [...answers];

    if (x.length > 0) {
      if (answerIndex > -1) {
        newAnswers[answerIndex].answer = x;
        setAnswers(newAnswers);
      }
      else {
        setAnswers([...newAnswers, { id, answer: x }]);
      }
    }
  };

  const submitAnswers = (e, val) => {
    e.preventDefault();
    const answersToPost: Array<QuizAnswerItem> = [];
    const formIds: Array<string> = Object.keys(val);

    formIds.forEach(key => {
      const inputValues = key.split("-");

      if (inputValues[0] === "QuizOptionsCheckbox") {
        if (val[key] === true) {
          const checkboxAnswerArr = key.split("-");
          const answerIndex = answersToPost.findIndex(answer =>
            answer.id === parseInt(checkboxAnswerArr[1], 10));

          const answerToPush = quiz.find(item =>
            item.id === parseInt(inputValues[1], 10))?.options[parseInt(checkboxAnswerArr[2], 10)];

          if (answerToPush === undefined) {
            return;
          }

          if (answerIndex > -1) {
            answersToPost[answerIndex].answer = [...answersToPost[answerIndex].answer,
              answerToPush];
          }
          else {
            answersToPost.push({ id: parseInt(checkboxAnswerArr[1], 10),
              answer: [answerToPush] });
          }
        }
      }
      else {
        const keyValue: string = typeof val[key] === "string" ? val[key] : "";

        answersToPost.push({ id: parseInt(inputValues[1], 10), answer: [keyValue] });
      }

      setAnswers([...answers, ...answersToPost]);
    });
    setQuizLoading(true);
  };

  useEffect(() => {
    if (quizLoading && answers.length > 0 && answers.length === quiz.length) {
      postQuizData();
    }
  }, [quizLoading, answers]);

  const saveQuizAnswers = (val: FormData) => {
    const tempAnswers: Array<QuizAnswerItem> = [...answers];
    const formIds: Array<string> = Object.keys(val);

    formIds.forEach((key, i) => {
      const inputValues = key.split("-");
      const answerIndex = tempAnswers.findIndex(answer =>
        answer.id === parseInt(inputValues[1], 10));

      if (inputValues[0] === "QuizOptionsCheckbox") {
        const answerToPush = quiz.find(item =>
          item.id === parseInt(inputValues[1], 10))?.options[parseInt(inputValues[2], 10)];

        if (answerToPush === undefined) {
          return;
        }

        if (answerIndex > -1) {
          const answerToDeletIndex = tempAnswers[answerIndex].answer.findIndex(answer =>
            answer === answerToPush);

          if (Object.values(val)[i] === true && answerToDeletIndex === -1) {
            tempAnswers[answerIndex].answer = [...tempAnswers[answerIndex].answer,
              answerToPush];
            setAnswers(tempAnswers);
          }

          if (Object.values(val)[i] === false) {
            const answerToDeletIndex = tempAnswers[answerIndex].answer.findIndex(answer =>
              answer === answerToPush);
            tempAnswers[answerIndex].answer.splice(answerToDeletIndex, 1);
            setAnswers(tempAnswers);
          }
        }
        else {
          tempAnswers.push({ id: parseInt(inputValues[1], 10),
            answer: [answerToPush] });
          setAnswers(tempAnswers);
        }
      }

      if (answerIndex > -1 && inputValues[0] !== "QuizOptionsCheckbox") {
        const keyValue: string = typeof val[key] === "string" ? val[key] : "";

        tempAnswers[answerIndex].answer = [keyValue];
        setAnswers(tempAnswers);
      }

      if (answerIndex === -1 && inputValues[0] !== "QuizOptionsCheckbox") {
        const keyValue: string = typeof val[key] === "string" ? val[key] : "";

        tempAnswers.push({ id: parseInt(inputValues[1], 10), answer: [keyValue] });
        setAnswers(tempAnswers);
      }
    });
  };

  const postQuizData = async () => {
    const data: PostQuizAnswerData = {
      id: parseInt(currentQuizId, 10),
      starttime: quiz[0].starttime,
      data: answers,
    };

    const response = await client(postQuizAnswer, { form: data });

    if (response.postQuizAnswer.result.includes("ERROR")) {
      sendMessage(addMessage(`QUIZ_ERROR_${response.postQuizAnswer.code}`, "error"));
      push(quizHistoryUrl);
    }
    else {
      sendMessage(addMessage("SEND_QUIZ_SUCCESS", "success"));
      setState({});
      sendMessage(getQuizHistoryList());
    }
  };

  return (
    <>
      {Boolean(heroIntroView.toggle) &&
      <HeroIntro title={currentQuiz?.name} />
      }
      <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
        <Breadcrumbs
          current={currentQuiz?.name}
          links={[{ url: quizHistoryUrl, name: quizHistoryTitle }]}
        />
        {quizData.state === "LOADED" && quiz.length > 0 ?
          <div className={styles.quizBlock}>
            {/* eslint-disable react/no-danger */}
            {currentQuiz && currentQuiz.descr &&
              <div
                className={styles.quizDesc}
                dangerouslySetInnerHTML={{ __html: currentQuiz.descr }}
              />
            }
            {/* eslint-enable react/no-danger */}
            {quizShowMode && quizShowMode.includes("MULTIPLE") ?
              <MultipleView
                state={state}
                setState={setState}
                quiz={quiz}
                setOptionsAnswer={setOptionsAnswer}
                answers={answers}
                submitAnswers={submitAnswers}
                step={step}
                setStep={setStep}
                saveQuizAnswers={saveQuizAnswers}
                currentQuestion={currentQuestion}
                postQuizData={postQuizData}
              /> :
              <SingleView
                state={state}
                setState={setState}
                quiz={quiz}
                setOptionsAnswer={setOptionsAnswer}
                answers={answers}
                submitAnswers={submitAnswers}
                loading={quizLoading}
              />
            }
          </div> :
          <LoadingView />
        }
      </Wrapper>
    </>
  );
};

export default QuizView;
