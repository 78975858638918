/* @flow */

import React, { useState, useCallback, useEffect, useContext } from "react";
import { useData, useSendMessage } from "crustate/react";
import { ReportFormData, VerifyListData, ReportListData } from "data";
import { loadVerifyList } from "state/verify-list";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { postVerificationForm } from "queries";
import { useTranslate } from "@awardit/react-use-translate";
import useDevice from "helpers/use-device";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";
import { Button, Dropdown, DropdownItem, Pagination } from "@crossroads/ui-components";
import TableLarge, { TableLargeHint } from "components/ReportsView/ApprovalView/TableLarge";
import TableSmall, { TableSmallHint } from "components/ReportsView/ApprovalView/TableSmall";
import { Title } from "components/UiComponents";
import { PAGE_SIZE } from "effects/verify-list";
import cn from "classnames";

import styles from "./styles.scss";

type PaginationButtonT = {
  page: number,
  active: boolean,
  children: React$Node,
};

const ReportsHistoryView = (): React$Node => {
  const reportData = useData(ReportFormData);
  const verifyList = useData(VerifyListData);
  const reportList = useData(ReportListData);
  const [currentDistrict, setCurrentDistrict] = useState<string>(reportData.state === "LOADED" &&
  reportData.data.id !== null ? reportData.data.id : "");
  const sendMessage = useSendMessage();
  const [submitting, setSubmitting] = useState(false);
  const { routes, content: { reportsview } } = useContext(StoreInfoContext);
  const client = useClient();
  const t = useTranslate();
  const isDesktop = useDevice("gt", 801);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const verify = verifyList.state === "LOADED" && verifyList.data ? verifyList.data : {};
  const totalPages = verifyList.state === "LOADED" && verify.totalCount ? Math.ceil(verify.totalCount / PAGE_SIZE) : 0;
  const [approvedIDs, setApprovedIDs] = useState<Array<{id: string, status: number}>>([]);

  const load = useCallback(page => {
    if (currentDistrict) {
      sendMessage(loadVerifyList({
        district: currentDistrict,
        pageNumber: (page - 1), // Page index starts at 0
        pageLimit: PAGE_SIZE,
      }));

      setCurrentPage(page);
    }
  }, [sendMessage, loadVerifyList, verifyList, submitting, currentDistrict]);

  useEffect(() => {
    setCurrentDistrict(reportData.state === "LOADED" && reportData.data.id !== null ?
      reportData.data.id : "");
  }, [reportData]);

  useEffect(() => {
    load(currentPage);
  }, [currentDistrict]);

  const PaginationButton = ({ active, children, page }: PaginationButtonT) => {
    return (
      <button
        type="button"
        disabled={active}
        className={
          cn(styles.paginationButton, { [styles.active]: active })
        }
        onClick={() => {
          load(page);
          window.scroll({ top: 0, left: 0, behavior: "auto" });
        }}
      >
        {children}
      </button>
    );
  };

  const sendFormData = async items => {
    if (reportData.state === "LOADED" && reportData.data.id) {
      const ids = [];
      const status = [];

      items.forEach(item => {
        ids.push(item.id);
        status.push(item.status);
      });

      const formData = {
        id: reportData.data.id,
        chkid: ids,
        chkstatus: status,
      };

      setSubmitting(true);

      const response = await client(postVerificationForm, { form: formData });

      if (response.postVerificationForm.result.includes("ERROR")) {
        setSubmitting(false);
      }
      else {
        setSubmitting(false);
        setApprovedIDs([]);
        load(currentPage);
      }
    }
  };

  return (
    <Wrapper variant="pageWrapper" className={cn("awardit-pageWrapper", styles.wrapper)}>
      <Helmet
        title={reportsview.approvalsTitle ?? ""}
      />

      <Breadcrumbs current={reportsview.approvalsTitle ?? ""} />

      <div className={styles.intro}>
        {reportsview.approvalsTitle && <Title>{reportsview.approvalsTitle}</Title>}
        {reportsview.approvalsDescription && <p>{reportsview.approvalsDescription}</p>}
        {routes.reportsView && routes.reportsView.url &&
          <Link className={styles.link} to={routes.reportsView.url}>{t("REPORTS.BACK")}</Link>
        }
      </div>

      {reportList.state === "LOADED" &&
      reportList.data &&
      reportList.data.length > 1 &&
        <Dropdown
          className={styles.districtSelector}
          name="productName"
          variant="primary"
          value={currentDistrict}
          onChange={value => {
            const val = value !== null ? value.toString() : value;
            if (typeof val === "string") {
              setCurrentDistrict(val);
            }
          }}
        >
          {reportList.data.map(item => (
            <DropdownItem
              key={`from_${item.id}`}
              className={styles.inputWrapper}
              value={`${item.id}`}
            >
              {`${item.name}`}
            </DropdownItem>
          ))}
        </Dropdown>
      }

      {reportsview.approvalsSubTitle && <Title elem="h2">{reportsview.approvalsSubTitle}</Title>}
      {verifyList.state === "LOADED" &&
      verify.verifyList.header &&
      verify.verifyList.table &&
        <>
          <TableLarge
            table={verify.verifyList.table}
            headers={verify.verifyList.header}
            setApprovedIDs={setApprovedIDs}
            approvedIDs={approvedIDs}
          />
          <TableSmall
            table={verify.verifyList.table}
            headers={verify.verifyList.header}
            setApprovedIDs={setApprovedIDs}
            approvedIDs={approvedIDs}
          />
          {verify.totalCount > PAGE_SIZE &&
            <div className={styles.paginationWrapper}>
              <Pagination
                className={styles.pagination}
                currentPage={currentPage}
                pageCount={totalPages}
                ButtonComponent={PaginationButton}
                maxButtonCount={isDesktop ? 10 : 5}
              />
            </div>
          }
          <div className={styles.buttonWrapper}>
            <Button
              loading={submitting}
              className={styles.submitBtn}
              variant="primary"
              disabled={approvedIDs.length <= 0}
              onClick={() => sendFormData(approvedIDs)}
            >
              {reportsview.submitInputLabel ?
                reportsview.submitInputLabel :
                t("REPORTS.APPROVAL.SUBMIT")
              }
            </Button>
          </div>
        </>
      }
      {verifyList.state === "LOADED" &&
      !verify.verifyList.table &&
      !verify.verifyList.header &&
      reportsview.approvalsNoResults &&
        <p>{reportsview.approvalsNoResults}</p>
      }
      {verifyList.state !== "LOADED" && verifyList.state !== "ERROR" &&
        <>
          <TableLargeHint />
          <TableSmallHint />
        </>
      }
      {verifyList.state === "ERROR" && <p>{t("REPORTS.ERROR")}</p>}
    </Wrapper>
  );
};

export default ReportsHistoryView;
