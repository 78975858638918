
/* @flow */

import type { QuizAnswerItem } from "shop-state/types";

import React, { useState } from "react";
import Button from "components/Button";

import { useTranslate } from "@awardit/react-use-translate";
import { RadioGroup, Dropdown, DropdownItem } from "@crossroads/ui-components";
import Field, { CheckboxField, RadioField, TextAreaField } from "components/Field";

import styles from "./styles.scss";

type QuizQuestion = {
  name: string,
  options: Array<string>,
  type: string,
  nr: number,
  points: number,
  id: number,
};

type Props = {
  question: QuizQuestion,
  answers: Array<QuizAnswerItem>,
  setAnswer: Array<string> => void,
};

const Options = ({ question, answers, setAnswer }: Props): React$Node => {
  const t = useTranslate();
  const [activeFilter, setActiveFilter] = useState<string>("");

  switch (question.type) {
    case "QuizOptionsList":
      return (
        <div>
          <Dropdown
            className={styles.fitlerPicker}
            value={activeFilter}
            placeholder={t("QUIZ.LIST_ALTERNATIV_PLACEHOLDER")}
            onChange={value => {
              const val = value !== null ? value.toString() : "";
              setActiveFilter(val);
              setAnswer([val]);
            }}
          >
            {question.options.map((q, i) => (
              <DropdownItem
                key={q}
                value={q}
                name={`QuizOptionsCheckbox-${question.id}-${i}`}
                onClick={() => setAnswer([q])}
              >
                {q}
              </DropdownItem>
            ))}
          </Dropdown>
        </div>
      );
    case "QuizOptionsRadio":
      const found = answers.find(a => a.id === question.id);
      return (
        <RadioGroup
          value={found ? found.answer[0] : ""}
          onChange={e => {
            setAnswer([e.currentTarget.value]);
          }}
        >
          {question.options.map((q, i) => (
            <RadioField
              key={`${q}-${i}`}
              required
              name={`radio_${question.id}`}
              className={styles.radio}
              value={q}
            >
              {q}
            </RadioField>
          ))}
        </RadioGroup>
      );
    case "QuizOptionsCheckbox":
      return (
        <div>
          {question.options.map((q, i) => (
            <CheckboxField
              key={`${q}-${i}`}
              className={styles.checkbox}
              name={`QuizOptionsCheckbox-${question.id}-${i}`}
            >
              {q}
            </CheckboxField>
          ))}
        </div>
      );
    case "QuizOptionsImage":
      return (
        <label className={styles.imageInput}>
          <input type="file" id="file" name="file" accept="image/*" aria-label="File browser example" />
          <span>{t("QUIZ.IMAGE_INPUT_LABEL")}</span>
          <Button variant="primary">{t("QUIZ.IMAGE_INPUT_BUTTON")}</Button>
        </label>
      );
    case "QuizOptionsFile":
      return (
        <input
          label={t("QUIZ.INPUT_LABEL")}
          value={answers.length > 0 ? answers[0] : ""}
          onChange={e => setAnswer([e.target.value])}
        />
      );
    case "QuizOptionsText":
      return (
        <Field
          required
          label={t("QUIZ.INPUT_LABEL")}
          name={`QuizOptionsText-${question.id}`}
        />
      );
    case "QuizOptionsTextarea":
      return (
        <TextAreaField
          required
          label={t("QUIZ.INPUT_LABEL")}
          name={`QuizOptionsTextarea-${question.id}`}
        />
      );
    default:
      break;
  }

  return null;
};

export default Options;

