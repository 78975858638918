/* @flow */

import type { FormData } from "@awardit/formaggio/src/types";
import type { QuizAnswerItem, QuizItem } from "shop-state/types";

import React, { useState } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";

import { Form } from "@awardit/formaggio";
import Wrapper from "components/Wrapper";
import Button from "components/Button";

import Options from "../options";

import styles from "../styles.scss";

type Props = {
  answers: Array<QuizAnswerItem>,
  submitAnswers: (e: SyntheticEvent<HTMLFormElement>, val: FormData) => void,
  state: FormData,
  setState: (val: FormData) => void,
  step: number,
  setStep: (step: number) => void,
  quiz: Array<QuizItem>,
  saveQuizAnswers: (data: FormData) => void,
  setOptionsAnswer: (x: Array<string>, id: number) => void,
  currentQuestion: QuizItem | null,
  postQuizData: () => Promise<void>,
};

const MultipleView: React$AbstractComponent<Props, mixed> = React.memo<Props>(({
  answers,
  submitAnswers,
  state,
  setState,
  step,
  setStep,
  quiz,
  saveQuizAnswers,
  setOptionsAnswer,
  currentQuestion,
  postQuizData,
}: Props) => {
  const [currentQuestionAnswered, setCurrentQuestionAnswered] = useState(false);
  const t = useTranslate();

  return (
    <div className={cn(styles.block, styles.blockMultiple)}>
      <Wrapper className={styles.wrapper}>
        <Form
          className={cn("awardit-quizForm")}
          value={state}
          onSubmit={(e, value) => {
            submitAnswers(e, value);
          }}
          onChange={x => {
            setState({ ...state, ...x });
            saveQuizAnswers(x);
            setCurrentQuestionAnswered(true);
          }}
        >

          {currentQuestion &&
            <div>
              <div className={styles.header}>
                <h2>{t("QUIZ.HEADING", { step, of: quiz.length })}</h2>
                {/* eslint-disable react/no-danger */}
                <div
                  dangerouslySetInnerHTML={{ __html: currentQuestion.name }}
                  className={styles.question} />
                {/* eslint-enable react/no-danger */}
              </div>

              <div className={styles.questions}>
                <Options
                  question={currentQuestion}
                  answers={answers || []}
                  setAnswer={x => {
                    setCurrentQuestionAnswered(true);
                    setOptionsAnswer(x, currentQuestion.id);
                  }}
                />

                <Button
                  className={styles.nextButton}
                  variant="primary"
                  disabled={!currentQuestionAnswered}
                  onClick={() => {
                    if (answers.length >= step && answers.length !== quiz.length) {
                      setStep(step + 1);
                    }

                    if (answers.length > 0 && answers.length === quiz.length) {
                      postQuizData();
                    }

                    setCurrentQuestionAnswered(false);
                  }}
                >
                  {t(`QUIZ.${step < quiz.length ? "NEXT" : "SUBMIT"}`)}
                </Button>
              </div>
            </div>
          }
        </Form>
      </Wrapper>
    </div>
  );
});

export default MultipleView;
