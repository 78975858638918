/* @flow */

// Might have borken this one idk.
// I'm not sure what the flow types are supposed to be
export const stutter = (
  initialDelay: number,
  delay: number
): ((idx: number) => string) => (idx: number): string => {
  return `${initialDelay + (delay * idx)}ms`;
};
