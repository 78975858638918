/* @flow */

import type { FormData } from "@awardit/formaggio/src/types";
import type { Validator } from "@awardit/formaggio";
import type { MemberGroupSettings } from "shop-state/types";

import React from "react";
import { useClient } from "entrypoint/shared";
import { Foldable } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import { Form, rules, isRequired, isEmail, isPhone } from "@awardit/formaggio";
import { useSendMessage } from "crustate/react";
import { addMessage, addMessageTranslated } from "state/messages";
import Field, { CheckboxField } from "components/Field";
import { membergroupAddMember } from "queries";
import Button from "components/Button";

import styles from "./styles.scss";

type Props = {
  isOpen: boolean,
  setIsOpen: boolean => void,
  addMemberFormState: FormData,
  setAddMemberFormState: FormData => void,
  initialAddMemberFormState: FormData,
  memberGroupSettings: ?MemberGroupSettings,
  setLoading: boolean => void,
  loading: boolean,
  getMembers: () => void,
};

const AddMemberForm = ({
  isOpen,
  setIsOpen,
  addMemberFormState,
  setAddMemberFormState,
  initialAddMemberFormState,
  memberGroupSettings,
  setLoading,
  loading,
  getMembers,
}: Props): React$Node => {
  const hasMemberUidField: boolean = memberGroupSettings?.showmuid === "1";
  const memberUserIdRule = hasMemberUidField ? [isRequired("memberuserid")] : [];
  const validation: Validator<FormData> = rules([
    isRequired("firstname"),
    isRequired("lastname"),
    isRequired("phonenumber"),
    isRequired("email"),
    isPhone("phonenumber"),
    isEmail("email"),
    ...memberUserIdRule,
  ]);

  const formErrors = validation(addMemberFormState);
  const sendMessage = useSendMessage();
  const client = useClient();
  const t = useTranslate();

  const submitAddMember = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const { firstname, lastname, phonenumber, email, isOwner, isActive } = addMemberFormState;
    const { memberuserid } = addMemberFormState;

    const response = await client(membergroupAddMember, {
      firstname: typeof firstname === "string" ? firstname : "",
      lastname: typeof lastname === "string" ? lastname : "",
      phonenumber: typeof phonenumber === "string" ? phonenumber : "",
      memberuserid: hasMemberUidField && typeof memberuserid === "string" ? memberuserid : "",
      email: typeof email === "string" ? email : "",
      owner: typeof isOwner === "boolean" ? isOwner : false,
      active: typeof isActive === "boolean" ? isActive : false,
    });

    const { message } = await response.membergroupAddMember;

    if (response.membergroupAddMember.result === "ERROR" && message) {
      sendMessage(addMessageTranslated(message, "error"));
      setLoading(false);
    }

    if (response.membergroupAddMember.result === "ERROR" && !message) {
      sendMessage(addMessage(`ADD_MEMBER_ERROR`, "error"));
      setLoading(false);
    }

    if (response.membergroupAddMember.result === "SUCCESS") {
      sendMessage(addMessage(`ADD_MEMBER_SUCCESS`, "success"));
      setIsOpen(false);
      setAddMemberFormState(initialAddMemberFormState);
      getMembers();
      setLoading(false);
    }
  };

  return (
    <Foldable open={isOpen} className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.formWrapper}>
          <Form
            id="addMemberForm"
            className={styles.form}
            value={addMemberFormState}
            errors={formErrors}
            onSubmit={event => submitAddMember(event)}
            onChange={x => setAddMemberFormState({ ...addMemberFormState, ...x })}
          >
            <Field variant="float" wrapperClassName={styles.field} name="firstname" label={t("ADDRESS.FIRSTNAME")} />
            <Field variant="float" wrapperClassName={styles.field} name="lastname" label={t("ADDRESS.LASTNAME")} />
            <Field variant="float" wrapperClassName={styles.field} name="phonenumber" label={t("ADDRESS.TELEPHONE")} />
            {hasMemberUidField && (
              <Field variant="float" wrapperClassName={styles.field} name="memberuserid" label={t("ACCOUNT.MEMBER_GROUP.MEMBER_ID")} />
            )}
            <Field variant="float" wrapperClassName={styles.field} name="email" label={t("ADDRESS.EMAIL")} />
            <div className={styles.checkboxWrapper}>
              <CheckboxField name="isOwner">{t("ACCOUNT.MEMBER_GROUP.OWNER")}</CheckboxField>
              <CheckboxField name="isActive">{t("ACCOUNT.MEMBER_GROUP.CAN_LOGIN")}</CheckboxField>
            </div>
            <div className={styles.submitWrapper}>
              <Button variant="primary" size="small" type="submit" disabled={loading} className={styles.submitButton}>
                {t("ACCOUNT.MEMBER_GROUP.ADD_MEMBER")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Foldable>
  );
};

export default AddMemberForm;
