/* @flow */

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";

const CategoryListView = (): React$Node => {
  const t = useTranslate();
  const { categories } = useContext(StoreInfoContext);

  return (
    <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
      <Helmet
        title={t("HEADER.CATEGORIES")}
      />

      <h1 className={styles.title}>{t("HEADER.CATEGORIES")}</h1>

      <div className={styles.separator} />

      <div className={styles.block}>
        {categories.map(category => (
          <div key={category.name} className={styles.section}>
            <Link
              key={category.url}
              className={styles.link}
              to={{
                pathname: category.url,
              }}
            >
              <h2 className={styles.sectionTitle}>{category.name}</h2>
            </Link>
            <div className={styles.links}>
              {category.children.map(subCategory => (
                <Link
                  key={subCategory.url}
                  className={styles.link}
                  to={{
                    pathname: subCategory.url,
                  }}
                >
                  {subCategory.name}
                </Link>
              ))}
            </div>
            <div className={styles.separator} />
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default CategoryListView;
