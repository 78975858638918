/* @flow */

import React from "react";

export const pixelSrc = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

const Pixel = ({ className }: { className: string }): React$Element<"img"> => (
  <img alt="" src={pixelSrc} className={className} />
);

export default Pixel;
