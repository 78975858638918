/* @flow */

import type { FormData } from "@awardit/formaggio/src/types";

import React, { useState, useContext } from "react";
import cn from "classnames";
import { Foldable } from "@crossroads/ui-components";
import { Form, rules, isRequired, match } from "@awardit/formaggio";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import { changeAwarditPassword } from "queries";
import { addMessage } from "state/messages";
import { useSendMessage } from "crustate/react";
import Field from "components/Field";
import CogIcon from "icons/cog.svg";
import SaveIcon from "icons/save.svg";

import styles from "components/AccountView/styles.scss";

// @TODO: validate min / max length and maybe complexity
const validation = rules([
  isRequired("password"),
  isRequired("password-new"),
  isRequired("password-confirm"),
  match("password-new", "password-confirm"),
]);

const Password = (): React$Node => {
  const client = useClient();
  const sendMessage = useSendMessage();
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState<FormData>({});
  const [saving, setSaving] = useState(false);

  const errors = validation(formState);

  const { content: { accountview, checkoutview } } = useContext(StoreInfoContext);

  const submit = async e => {
    e.preventDefault();
    if (saving) {
      return;
    }

    setSaving(true);

    const data = await client(changeAwarditPassword, {
      oldPassword: formState.password.toString() || "",
      newPassword: formState["password-new"].toString() || "",
    });

    if (data.changeAwarditPassword.result !== "success") {
      sendMessage(addMessage("CHANGE_PASSWORD_ERROR", "error"));
    }
    else {
      sendMessage(addMessage("CHANGE_PASSWORD_SUCCESS", "success"));
      setOpen(false);
      setFormState({});
    }

    setSaving(false);
  };

  return (
    <div className={cn(styles.password, { [styles.saving]: saving })}>
      <Form
        disabled={saving}
        errors={errors}
        value={formState}
        onChange={x => setFormState({ ...formState, ...x })}
        onSubmit={submit}
      >
        <header className={styles.boxHeader}>
          {accountview.changePasswordHeading &&
            <h2 className={styles.boxHeading}>{accountview.changePasswordHeading}</h2>
          }
          {!open && checkoutview.edit ?
            <button
              type="button" onClick={e => {
                e.preventDefault();
                setOpen(true);
              }}
            >
              <span>{checkoutview.edit}</span>
              <CogIcon />
            </button> :
            <button
              type="submit"
              disabled={saving}
            >
              <span>{saving ? accountview.saving ?? "" : accountview.saveAndClose ?? ""}</span>
              <SaveIcon />
            </button>
          }
        </header>
        <Foldable open={open}>
          <section className={cn(styles.boxBody, styles.removeBottomPadding)}>
            <div className={styles.inputSplit}>
              <div>
                <Field name="password" type="password" autoComplete="off" label={accountview.currentPassword ?? ""} />
              </div>
              <div />
            </div>
            <div className={styles.inputSplit}>
              <div>
                <Field name="password-new" type="password" autoComplete="off" label={accountview.newPassword ?? ""} />
              </div>
              <div>
                <Field name="password-confirm" type="password" autoComplete="off" label={accountview.confirmPassword ?? ""} />
              </div>
            </div>
          </section>
        </Foldable>
      </Form>
    </div>
  );
};

export default Password;
