/* @flow */

import React, { useState, useContext } from "react";
import cn from "classnames";
import { Dialogue } from "@crossroads/ui-components";
import { useData, useSendMessage } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import { TinkProvidersData, CustomerData } from "data";
import { formatDate } from "helpers/utils";
import Button from "components/Button";
import ErrorView from "components/ErrorView";
import { useTranslate } from "@awardit/react-use-translate";
import { removeTinkProvider } from "state/tink-providers";
import CloseIcon from "icons/close-small.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
  hideActive?: boolean,
};

type ProviderRowProps = {
  id: number,
  active: boolean,
  name: string,
  createdAt: string,
  status: string,
  isRemoving: boolean,
  remove: () => void,
  baseRenewLink: string,
  remainingDays: number,
  endsAt: string,
};

export const ACTIVE_DAYS_THRESHOLD = 14;

const TinkProviders = ({ className, hideActive = false }: Props): React$Node => {
  const customer = useData(CustomerData);
  const t = useTranslate();
  const formatRemainingDays = useFormatRemainingDays();
  const sendMessage = useSendMessage();
  const tinkProviders = useData(TinkProvidersData);
  const [activeItem, setActiveItem] = useState<{ id: number, name: string } | null>(null);
  const { info: { partnerId, host } } = useContext(StoreInfoContext);

  const removeProvider = (id: number) => {
    if (tinkProviders.state !== "REMOVING") {
      sendMessage(removeTinkProvider(id));
    }
  };

  if (tinkProviders.state === "IDLE" || !customer.data) {
    return null;
  }

  if (tinkProviders.state === "LOADING") {
    return (
      <div className={cn(styles.block, className)}>
        <Hint />
      </div>
    );
  }

  if (tinkProviders.state === "ERROR") {
    return <ErrorView />;
  }

  const baseRenewLink = `https://${host}/tinkback?muid=${customer.data.awarditMemberId}&puid=${partnerId}&renew=`;

  return (
    <div className={cn(styles.block, className)}>
      {tinkProviders.data
        .filter(p => !hideActive || !p.active || p.remainingDays <= ACTIVE_DAYS_THRESHOLD)
        .map((x, i) => (
          <ProviderRow
            key={i}
            isRemoving={tinkProviders.state === "REMOVING"}
            status={formatRemainingDays(x)}
            remove={() => setActiveItem({ id: x.id, name: x.name })}
            baseRenewLink={baseRenewLink}
            {...x}
          />
        ))
      }
      {activeItem &&
        <Dialogue
          className={styles.confirmDialogue}
          open={activeItem !== null}
          setOpen={() => setActiveItem(null)}
          title={t("TINK.CONFIRM.TITLE")}
          closeIcon={<div className={styles.closeIconWrapper}><CloseIcon /></div>}
          primaryAction={t("TINK.CONFIRM.OK")}
          secondaryAction={t("TINK.CONFIRM.CANCEL")}
          onPrimaryAction={() => {
            if (activeItem) {
              removeProvider(activeItem.id);
              setActiveItem(null);
            }
          }}
          onSecondaryAction={() => setActiveItem(null)}
        >
          {t("TINK.REMOVE_CONFIRM", { name: activeItem.name })}
        </Dialogue>
      }
    </div>
  );
};

const ProviderRow = ({
  id,
  name,
  createdAt,
  status,
  isRemoving,
  active,
  remove,
  baseRenewLink,
  remainingDays,
  endsAt }: ProviderRowProps) => {
  const t = useTranslate();

  return (
    <div className={styles.provider}>
      <div className={styles.info}>
        <div className={styles.name}>{name}</div>
        <div className={styles.date}>{formatDate(createdAt)}</div>
        <div className={styles.date}>{formatDate(endsAt)}</div>
        <div className={cn(
          styles.status,
          { [styles.statusInactive]: !active })}
        >
          {status}
        </div>
      </div>
      <div className={styles.actions}>
        <Button disabled={isRemoving} size="small" onClick={remove}>
          {t("TINK.REMOVE_PROVIDER")}
        </Button>
        <Button
          disabled={active && remainingDays > ACTIVE_DAYS_THRESHOLD}
          variant="ghost"
          size="small"
          to={baseRenewLink + id}
        >
          {t("TINK.RENEW_PROVIDER")}
        </Button>
      </div>
    </div>
  );
};

const Hint = () => {
  return (
    <>
      <div className={cn(styles.provider, styles.hint)} />
      <div className={cn(styles.provider, styles.hint)} />
    </>
  );
};

const useFormatRemainingDays = () => {
  const t = useTranslate();

  return ({ active, remainingDays }: { +active: boolean, +remainingDays: number }): string => {
    if (!active) {
      return t("TINK.INACTIVE");
    }

    if (remainingDays > ACTIVE_DAYS_THRESHOLD) {
      return t("TINK.ACTIVE");
    }

    return remainingDays === 1 ? t("TINK.REMAINING_DAYS.SINGULAR") : t("TINK.REMAINING_DAYS.PLURAL", { days: remainingDays });
  };
};

export default TinkProviders;
