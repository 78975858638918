/* @flow */

import type { AwarditAgreement, TermsInfo } from "shop-state/types";

import React, { useState, useEffect, useContext } from "react";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import LoadingView from "components/LoadingView";
import { useTranslate } from "@awardit/react-use-translate";
import { awarditAgreement, getTermsInfo } from "queries";
import Wrapper from "components/Wrapper";
import { Link } from "react-router-dom";
import ArrowIcon from "icons/arrow.svg";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  isLoggedIn?: boolean,
  partnerId?: number | null,
};

const TermsView = ({ isLoggedIn, partnerId }: Props): React$Node => {
  const t = useTranslate();
  const { routes, info } = useContext(StoreInfoContext);
  const client = useClient();
  const [agreement, setAgreement] = useState<AwarditAgreement | TermsInfo | null>(null);
  const [errorFetching, setErrorFetching] = useState<boolean>(false);
  const [loaded, setLoaded] = useState(false);
  const isNotLoggedIn = isLoggedIn !== null && isLoggedIn !== undefined && !isLoggedIn;

  useEffect(() => {
    if (partnerId !== null && partnerId !== undefined) {
      if (info.code === "beijer_sek" && (partnerId === 5450 || partnerId === 5451)) {
        client(getTermsInfo, { partner: partnerId })
          .then(({ getTermsInfo }) => setAgreement(getTermsInfo))
          .catch(() => {
            setErrorFetching(true);
          }).finally(() => setLoaded(true));
      }
    }

    if (partnerId === null || partnerId === undefined) {
      client(awarditAgreement)
        .then(({ awarditAgreement }) => setAgreement(awarditAgreement))
        .catch(() => {
          setErrorFetching(true);
        }).finally(() => setLoaded(true));
    }
  }, [client]);

  if (!loaded) {
    return <LoadingView />;
  }

  /* eslint-disable react/no-danger */
  return (
    <Wrapper
      variant="pageWrapper"
      className={cn(
        { [styles.loginWrapper]: isNotLoggedIn },
        "awardit-pageWrapper",
        "awardit-termsView"
      )}
    >
      <div className={styles.header}>
        {isNotLoggedIn &&
          <Link className={styles.link} to="/login">
            <ArrowIcon />
          </Link>
        }
        <h1>{routes.termsView?.title ? routes.termsView.title : t("TERMSVIEW.FALLBACK_TITLE")}</h1>
      </div>
      {agreement &&
        <div
          dangerouslySetInnerHTML={{ __html: agreement.content }}
          className={styles.content}
        />
      }
      {errorFetching &&
        <div className={styles.content}>
          <p>{t("TERMSVIEW.ERROR_FETCHING")}</p>
        </div>
      }
    </Wrapper>
  );
  /* eslint-enable react/no-danger */
};

export default TermsView;
