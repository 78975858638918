/* @flow */

type BrandType = {
  name: string,
  url: string,
  children: Array<{ name: string, url: string }>,
  awarditTargetId?: Array<number>,
};

export const mapBrands = (prefixPath: string, brands: Array<string>): Array<BrandType> => {
  const sorted = brands.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

  return sorted.map<{
    name: string,
    url: string,
    children: Array<{
      name: string,
      url: string,
    }>,
    awarditTargetId?: Array<number>,
  }>(x => ({
    name: x,
    url: `${prefixPath}/${encodeURIComponent(x)}`,
    children: [],
  }));
};

