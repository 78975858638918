/* @flow */

import React from "react";
import Wrapper from "components/Wrapper";
import Spinner from "components/Spinner";

import styles from "./styles.scss";

const LoadingView = (): React$Node => {
  return <Wrapper className={styles.block}><Spinner /></Wrapper>;
};

export default LoadingView;
