/* @flow */

import React, { useState } from "react";
import cn from "classnames";
import Spinner from "components/Spinner";
import { Checkbox } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

type TableLargeProps = {
  approvedIDs: Array<{id: string, status: number}>,
  setApprovedIDs: Array<{id: string, status: number}> => void,
  headers: Array<string>,
  table: Array<Array<string>>,
};

const TableLargeHead = ({ headers }: { headers: Array<string> }) => {
  const t = useTranslate();
  return (
    <thead>
      <tr>
        {headers.map((header, i) => (
          <th
            key={`${header}-${i}`}
            className={i === (headers.length - 1) ? styles.hiddenCell : ""}
          >
            {header}
          </th>
        ))}
        <th>
          {headers[headers.length - 1]}
        </th>

        {/* TODO Replace translation when subheader is added to headers by backend */}
        <th>{t("TINK.REMOVE_PROVIDER")}</th>
      </tr>
    </thead>
  );
};

const TableItem = ({
  row,
  handleChange,
}: {
  row: Array<string>,
  handleChange: (item: { id: string, status: number }) => void,
}) => {
  const [currentItemApproved, setCurrentItemApproved] = useState<boolean>(false);
  const [currentItemDeclined, setCurrentItemDeclined] = useState<boolean>(false);

  return (
    <tr key={row[0]}>
      {row.map((item, i) => (
        <td
          key={`${item}-${i}`}
          className={i === (row.length - 1) ? styles.hiddenCell : ""}
        >
          {item}
        </td>
      )
      )}
      <td className={styles.checkboxTableData}>
        <Checkbox
          value={currentItemApproved}
          name={row.length > 3 ? `${row[1]}-${row[3]}` : `${row[0]}-${row[1]}`}
          onChange={() => {
            setCurrentItemApproved(!currentItemApproved);
            setCurrentItemDeclined(false);
            handleChange({ id: row.length > 3 ? row[3] : `${row[0]}-${row[1]}`, status: 1 });
          }}
        />
      </td>
      <td className={styles.checkboxTableData}>
        <Checkbox
          value={currentItemDeclined}
          name={(row.length > 3 ? `${row[1]}-${row[3]}` : `${row[0]}-${row[1]}`) + "-decline"}
          onChange={() => {
            setCurrentItemDeclined(!currentItemDeclined);
            setCurrentItemApproved(false);
            handleChange({ id: row.length > 3 ? row[3] : `${row[0]}-${row[1]}`, status: 0 });
          }}
        />
      </td>
    </tr>
  );
};

const TableLarge = ({
  approvedIDs,
  setApprovedIDs,
  table,
  headers,
}: TableLargeProps): React$Node => {
  const handleChange = ({ id, status }) => {
    let newArray = [...approvedIDs];

    if (newArray.length > 0) {
      const foundItemIndex = newArray.findIndex(item => item.id === id);

      if (foundItemIndex >= 0 && newArray[foundItemIndex].status !== status) {
        const tempArr = [...approvedIDs];
        tempArr[foundItemIndex] = { id, status };
        newArray = tempArr;
        setApprovedIDs(newArray);

        return;
      }

      if (foundItemIndex >= 0 && newArray[foundItemIndex].status === status) {
        const tempArr = [...approvedIDs];
        tempArr.splice(foundItemIndex, 1);
        newArray = tempArr;
        setApprovedIDs(newArray);

        return;
      }

      if (foundItemIndex < 0) {
        newArray.push({ id, status });
        setApprovedIDs(newArray);

        return;
      }
    }

    newArray.push({ id, status });
    setApprovedIDs(newArray);
  };

  return (
    <div className={styles.tableLarge}>
      <table className={styles.table}>
        <TableLargeHead headers={headers} />
        <tbody>
          {table.map(row => (
            <TableItem key={row[0]} row={row} handleChange={item => handleChange(item)} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const TableLargeHint = (): React$Node => (
  <div className={cn(styles.tableHint, styles.tableHintLarge)}>
    <Spinner />
  </div>
);

export default TableLarge;
