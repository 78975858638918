/* @flow */

import React, { useState } from "react";
import { useData, useSendMessage } from "crustate/react";
import { getHistoryItems } from "state/teampot";
import { TeampotHistoryData, TeampotHistoryItemsData } from "data";

import Wrapper from "components/Wrapper";

import { useTranslate } from "@awardit/react-use-translate";
import TableLarge, { TableLargeHint } from "components/TeamPotView/History/TableLarge";
import TableSmall, { TableSmallHint } from "components/TeamPotView/History/TableSmall";

import styles from "../styles.scss";

const History = (): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const teampotHistoryData = useData(TeampotHistoryData);
  const teampotHistoryUsersData = useData(TeampotHistoryItemsData);
  const [current, setCurrent] = useState<string | null>(null);

  const onUsersClick = date => {
    sendMessage(getHistoryItems({ date }));
  };

  return (
    <Wrapper className={styles.historyWrapper}>
      <div className={styles.history}>
        <h3 className={styles.historyHeader}>{t("MOTIVATION_POT.PREVIOUS_HANDED_OUT_HEADER")}  </h3>
        {(teampotHistoryData.state === "LOADING") &&
          <>
            <TableSmallHint />
            <TableLargeHint />
          </>
        }
        {teampotHistoryData.state === "LOADED" &&
          <TableLarge
            items={teampotHistoryData.data}
            users={teampotHistoryUsersData.state === "LOADED" &&
              teampotHistoryUsersData.data ? teampotHistoryUsersData.data : []}
            onUsersClick={onUsersClick}
          />
        }
        {teampotHistoryData.state === "LOADED" &&
          <TableSmall
            items={teampotHistoryData.data}
            current={current}
            users={teampotHistoryUsersData.state === "LOADED" &&
              teampotHistoryUsersData.data ? teampotHistoryUsersData.data : []}
            onClick={x => setCurrent(current !== x.datum ? x.datum : null)}
            onUsersClick={onUsersClick}
          />
        }
      </div>
    </Wrapper>
  );
};

export default History;
