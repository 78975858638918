/* @flow */

import type { PointsPrice, Price } from "helpers/points";

import React, { useContext } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import { getPriceSplit } from "helpers/points";
import { StoreInfoContext } from "entrypoint/shared";

type Props = {
  price?: ?Price,
  pointsPrice?: ?PointsPrice,
  className?: string,
};

type SummaryProps = {
  pointsPrice: ?PointsPrice,
  className?: string,
};

export const PriceSplit = ({ price, pointsPrice, className }: Props): React$Node => {
  const { configuration: { showCurrencyPrice } } = useContext(StoreInfoContext);
  const t = useTranslate();
  const { formatPoints, formatPrice } = useFormat();
  const priceSplit = getPriceSplit(pointsPrice, price);

  if (!priceSplit) {
    return null;
  }

  const renderSplit = (s, i) => {
    if (s.type === "points" && typeof s.values.points === "number") {
      return (
        <div key={i}>
          {i > 0 && <span>{t("OR")}&nbsp;</span>}
          <span>{formatPoints(s.values.points)}</span>
        </div>
      );
    }

    if (s.type === "currency" && typeof s.values.currency === "number" &&
    showCurrencyPrice !== undefined &&
    showCurrencyPrice) {
      return (
        <div key={i}>
          {i > 0 && <span>{t("OR")}&nbsp;</span>}
          <span>{formatPrice(s.values.currency)}</span>
        </div>
      );
    }

    if (s.type === "points") {
      return (
        <div key={i}>
          {i > 0 && <span>{t("OR")}&nbsp;</span>}
          <span>
            {typeof s.values.points === "number" && <span>{formatPoints(s.values.points)}</span>}
            {typeof s.values.currency === "number" && <span>&nbsp;+&nbsp;{formatPrice(s.values.currency)}</span>}
          </span>
        </div>
      );
    }
  };

  return (
    <div className={className}>
      {priceSplit.map(renderSplit)}
    </div>
  );
};

export const PriceSplitSummary = ({ pointsPrice, className }: SummaryProps): React$Node => {
  const { configuration: { showCurrencyPrice } } = useContext(StoreInfoContext);
  const t = useTranslate();
  const { formatPoints, formatPrice } = useFormat();

  if (!pointsPrice) {
    return null;
  }

  const points = pointsPrice.points.value.exVat;
  const currency = pointsPrice.currency.min.incVat;
  const currencyTotal = pointsPrice.currency.max.incVat;

  // Points + currency or currency
  if (points > 0 && currency > 0 && currency !== currencyTotal) {
    return (
      <div className={cn(className)}>
        <span>
          {`${formatPoints(points)} + ${formatPrice(currency)}`}
        </span>
      </div>
    );
  }

  // Points and currency
  if (points > 0 && currency > 0) {
    return (
      <div className={cn(className)}>
        <span>
          {formatPoints(points)}
        </span>
        <span>
          {t("CHECKOUT.AND")}{" "}
          {formatPrice(currency)}
        </span>
      </div>
    );
  }

  // Points or currency
  return (
    <div className={cn(className)}>
      {points > 0 && (
        <span>
          {formatPoints(points)}
        </span>
      )}
      {pointsPrice.currency.value.incVat > 0 &&
      showCurrencyPrice !== undefined &&
      showCurrencyPrice && (
        <span>
          {points > 0 && `${t("OR")} `}
          {formatPrice(pointsPrice.currency.value.incVat)}
        </span>
      )}
    </div>
  );
};

export default PriceSplit;
