/* @flow */

import type { LeaderboardItem } from "shop-state/types";
import { Dropdown, DropdownItem, Pagination } from "@crossroads/ui-components";
import { Title } from "components/UiComponents";

import React, { useState, useEffect, useContext } from "react";
import { useData, useSendMessage } from "crustate/react";
import { getLoadLeaderboardByDate } from "state/leaderboard";
import { LeaderboardData, LeaderboardDistrictsData } from "data";
import { StoreInfoContext } from "entrypoint/shared";

import { Helmet } from "react-helmet-async";
import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";
import SortOptions from "components/LeaderboardView/SortOptions";
import TableLarge, { TableLargeHint } from "components/LeaderboardView/TableLarge";
import TableSmall, { TableSmallHint } from "components/LeaderboardView/TableSmall";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";
import cn from "classnames";

type LeaderboardItems = $ReadOnlyArray<LeaderboardItem>;
type Value = string | number;

const LeaderboardView = (): React$Node => {
  const leaderboard = useData(LeaderboardData);
  const leaderboardDistrics = useData(LeaderboardDistrictsData);
  const { content: { leaderboardview } } = useContext(StoreInfoContext);

  const leaderboardDistricsList = leaderboardDistrics.state === "LOADED" ? leaderboardDistrics.data : [];
  const leaderboardRows = leaderboard.state === "LOADED" ? leaderboard.data.leaderboard : [];
  const leaderboardCount = leaderboard.state === "LOADED" && leaderboard.data.amountofwinners ?
    leaderboard.data.amountofwinners.count : 0;
  const leaderboardUser = leaderboard.state === "LOADED" ?
    leaderboard.data.leaderboard.find(item => item.name === leaderboard.data.user.name) : null;
  const leaderboardCurrentDistric = leaderboard.state === "LOADED" && leaderboard.data.currentdistrict ?
    leaderboard.data.currentdistrict : null;

  const [currentDistrict, setCurrentDistrict] = useState<string | null>(leaderboardCurrentDistric);
  const [limit, setLimit] = useState<number>(50);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pointsDeff, setPointsDeff] = useState<number | null>(null);
  const [threshold, setThreshold] = useState({ start: 0, end: limit });
  const [itemsToShow, setItemsToShow] = useState<LeaderboardItems>([{}]);
  const [current, setCurrent] = useState<string | null>(null);

  const initDate = new Date();

  const [activeLeaderboard, setActiveLeaderboard] = useState<string>("year");
  const [selectedFromDate, setSelectedFromDate] = useState<string>("");
  const [selectedToDate, setSelectedToDate] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<Value | null>(initDate.getFullYear().toString());

  const t = useTranslate();
  const sendMessage = useSendMessage();

  const lastDateOfMonth = (date: Date) => (
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  );

  const previousMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    let dateArr = [];

    if (month === 0) {
      dateArr = [year - 1, 11];
    }
    else {
      dateArr = [year, month - 1];
    }

    if (dateArr[1] < 9) {
      return `${dateArr[0]}-0${dateArr[1] + 1}`;
    }

    return `${dateArr[0]}-${dateArr[1] + 1}`;
  };

  const getMonthLeaderboard = () => {
    const date = new Date();
    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${date.toISOString().slice(0, 7)}-01`,
      todate: `${date.toISOString().slice(0, 7)}-${lastDateOfMonth(date)}`,
      district: currentDistrict ? currentDistrict : "",
    }));
    setActiveLeaderboard("month");
  };

  const getPrevMonthLeaderboard = () => {
    const currentDate = new Date();
    const prevYearMonth = previousMonth(currentDate);
    const prevDate = new Date(prevYearMonth);

    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${prevYearMonth}-01`,
      todate: `${prevYearMonth}-${lastDateOfMonth(prevDate)}`,
      district: currentDistrict ? currentDistrict : "",
    }));
    setActiveLeaderboard("prevMonth");
  };

  const getYearLeaderboard = () => {
    const date = new Date();
    const fromDate = `${date.getFullYear()}-01-01`;
    const toDate = `${date.getFullYear()}-12-31`;

    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${fromDate}`,
      todate: `${toDate}`,
      district: currentDistrict ? currentDistrict : "",
    }));
    setActiveLeaderboard("year");
  };

  const getSelectedYearLeaderboard = (selectedYear: Value | null) => {
    if (selectedYear === null) {
      return;
    }

    const date = new Date(selectedYear);
    const fromDate = `${date.getFullYear()}-01-01`;
    const toDate = `${date.getFullYear()}-12-31`;

    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${fromDate}`,
      todate: `${toDate}`,
      district: currentDistrict ? currentDistrict : "",
    }));
  };

  const getCustomDateLeaderboard = (fromDate: string, toDate: string) => {
    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${fromDate}`,
      todate: `${toDate}`,
      district: currentDistrict ? currentDistrict : "",
    }));
  };

  useEffect(() => {
    if (leaderboard.state === "LOADED" && leaderboardUser) {
      if (leaderboardCount > 0 && leaderboardRows.length > 0) {
        setPointsDeff(leaderboardRows[leaderboardCount - 1].points -
          (leaderboardUser.points !== null ? leaderboardUser.points : 0));
      }
      else if (leaderboardRows.length > 0) {
        setPointsDeff(leaderboardRows[0].points -
          (leaderboardUser.points !== null ? leaderboardUser.points : 0));
      }
      else {
        setPointsDeff(0);
      }
    }
  }, [leaderboard]);

  useEffect(() => {
    if (leaderboard && leaderboard.state === "LOADED" && leaderboardRows.length > 0) {
      setItemsToShow(leaderboardRows);
    }

    if (limit > 0) {
      const pages = leaderboard && leaderboard.state === "LOADED" && leaderboardRows.length > 0 ?
        Math.ceil(leaderboardRows.length / limit) : 0;

      setPagesCount(pages);
      setThreshold({ start: 0, end: limit });
      setCurrentPage(1);
    }
  }, [limit, leaderboard]);

  useEffect(() => {
    if (threshold.end > 0) {
      setItemsToShow([]);
      const limitedElements = leaderboard && leaderboard.state === "LOADED" && leaderboardRows.length > 0 ?
        leaderboardRows.slice(threshold.start, threshold.end) : [];
      setItemsToShow(limitedElements);
    }
  }, [threshold]);

  useEffect(() => {
    if (leaderboard.state !== "LOADED") {
      return;
    }

    if (currentDistrict) {
      const { flags } = leaderboard.data;
      if (flags && (flags.showdatesel > 0 && flags.showdatesel < 3)) {
        getYearLeaderboard();

        return;
      }

      sendMessage(getLoadLeaderboardByDate({ district: currentDistrict }));
    }
  }, [currentDistrict, activeLeaderboard]);

  useEffect(() => {
    if (currentDistrict === null && leaderboardDistricsList.length > 0) {
      const arr = leaderboardDistricsList.filter(district => district.primary === true);
      if (arr.length !== 0) {
        const primaryDist = arr[0].id;
        setCurrentDistrict(primaryDist);
      }
    }
  }, [leaderboardDistricsList]);

  const paginationHandler = children => {
    const num = Number(children);
    const end = num * limit;
    const start = end - limit;

    setThreshold({ start, end });
    setCurrentPage(num);
    window.scroll({ top: 0, left: 0, behavior: "auto" });
  };

  const PaginationButton = ({ children }: { children: React$Node }) => {
    return (
      <button
        type="button"
        disabled={currentPage === children}
        className={
          cn(styles.paginationButton, { [styles.active]: currentPage === children })
        }
        onClick={() => paginationHandler(children)}
      >
        {children}
      </button>
    );
  };

  const renderMissingInfoMessage = () => {
    const lbData = leaderboard.state === "LOADED" ? leaderboard.data : null;

    if (lbData === null) {
      return;
    }

    if ((leaderboard.state === "LOADED" && lbData.leaderboard.length === 0)) {
      return <span>{t("LEADERBOARD.MISSING_DATA")}</span>;
    }

    if (leaderboard.state === "LOADED" && lbData.user.points === 0 && lbData.user.position === 0) {
      return <span>{t("LEADERBOARD.MISSING_USER_DATA")}</span>;
    }

    return null;
  };

  const missingDataMessage = renderMissingInfoMessage();

  return (
    <Wrapper variant="pageWrapper" className={cn("awardit-pageWrapper", styles.wrapper)}>
      <Helmet
        title={leaderboardview.pageTitle ?? ""}
      />

      <Breadcrumbs current={leaderboardview.pageTitle ?? ""} />

      <div className={styles.intro}>
        <section className={cn(styles.introSection, "awardit-leaderboardPagIntroSection")}>
          {leaderboardview.pageTitle &&
            <Title className={cn("awardit-leaderboardPageTitle")}>{leaderboardview.pageTitle ?? ""}</Title>
          }
        </section>
        {/* eslint-disable react/no-danger */}
        {leaderboardview.textarea &&
          <div className={cn("awardit-leaderboardPageDescription")} dangerouslySetInnerHTML={{ __html: leaderboardview.textarea }} />
        }
        {/* eslint-enable react/no-danger */}
      </div>

      <div className={cn(styles.leaderboardTableHead, "awardit-leaderboardPageOverview")}>
        {leaderboardview.leaderboardTitle &&
          <h2>{leaderboardview.leaderboardTitle}</h2>
        }
        <div className={styles.right}>
          {(leaderboard.state === "LOADED" &&
            leaderboard.data.currentdistrict &&
            leaderboardDistricsList.length > 0) && (
            <article>
              <p>{leaderboardview.districtText ?
                leaderboardview.districtText : t("LEADERBOARD.DISTRIKT_DROPDOWN")}
              </p>
              <Dropdown
                name="leaderboardDistricts"
                className={cn(styles.dropdown, "awardit-leaderboardPageDistrictSelect")}
                placeholder={leaderboardview.showAmoutOfColumns ?? ""}
                value={currentDistrict ? currentDistrict : leaderboard.data.currentdistrict}
                onChange={val => {
                  const value = val !== null ? val.toString() : val;
                  setCurrentDistrict(value);
                }}
              >
                {leaderboardDistricsList.map(district => (
                  <DropdownItem key={district.id} value={district.id}>
                    {district.label}
                  </DropdownItem>
                ))}
              </Dropdown>
            </article>
          )}
          {leaderboard.state === "LOADED" && leaderboard.data.flags && leaderboard.data.flags.showdatesel > 0 &&
          <div className={styles.widgetCtas}>
            <SortOptions
              datesel={leaderboard.data.flags.showdatesel}
              activeLeaderboard={activeLeaderboard}
              getMonthLeaderboard={() => getMonthLeaderboard()}
              getPrevMonthLeaderboard={() => getPrevMonthLeaderboard()}
              getYearLeaderboard={() => getYearLeaderboard()}
              getCustomDateLeaderboard={(from, to) => getCustomDateLeaderboard(from, to)}
              getSelectedYearLeaderboard={selectedYear => getSelectedYearLeaderboard(selectedYear)}
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              selectedYear={selectedYear}
              setSelectedFromDate={x => setSelectedFromDate(x)}
              setSelectedToDate={x => setSelectedToDate(x)}
              setSelectedYear={x => setSelectedYear(x)}
            />
          </div>
          }
        </div>
      </div>
      <div className={cn("awardit-leaderboardPageLeaderboardWrapper")}>
        {(leaderboard.state !== "LOADING" && missingDataMessage === null) && (
          <div className={styles.overview}>
            {leaderboardview.subTitle &&
              <h2>{leaderboardview.subTitle}</h2>
            }
            <div className={styles.overviewBar}>
              <div className={styles.userInfo}>
                {(leaderboard.state === "LOADED" && leaderboardUser &&
                  leaderboardUser.position !== null && leaderboardRows.length > 0) && (
                  <section>
                    <div className={styles.score}><p>{leaderboardUser.position}</p></div>
                    <p>
                      {t("LEADERBOARD.POSITION_OF_CONTESTANTS", {
                        amount: leaderboardRows.length,
                        contestants: leaderboardview.contestantName ? leaderboardview.contestantName : "",
                      })}
                    </p>
                  </section>
                )}
                <section>
                  <div className={styles.score}><p>{pointsDeff !== null && pointsDeff}</p></div>
                  {leaderboardview.pointsToPlace &&
                    <p>{leaderboardview.pointsToPlace}</p>
                  }
                </section>
              </div>
              <section className={cn(styles.columnFilter, "awardit-leaderboardAmountOfMembers")}>
                {leaderboardview.showAmoutOfColumns &&
                  <label htmlFor="showAmountOfMembers" className={styles.label}>
                    {leaderboardview.showAmoutOfColumns}
                  </label>
                }
                <Dropdown
                  name="showAmountOfMembers"
                  className={styles.dropdown}
                  placeholder={leaderboardview.showAmoutOfColumns ?? ""}
                  value={limit}
                  onChange={val => {
                    const value = typeof val === "string" ? Number.parseInt(val, 10) : val;
                    if (value !== null) {
                      setLimit(value);
                    }
                  }}
                >
                  <DropdownItem value={50}>50</DropdownItem>
                  <DropdownItem value={100}>100</DropdownItem>
                  <DropdownItem value={200}>200</DropdownItem>
                </Dropdown>
              </section>
            </div>
          </div>
        )}
        {(leaderboard.state !== "LOADING" && missingDataMessage !== null &&
          leaderboardview.missingDataAdditionalText !== undefined &&
          leaderboardview.missingDataAdditionalText !== null &&
          leaderboardview.missingDataAdditionalText.length > 0) && (
          <>
            {/* eslint-disable react/no-danger */}
            <div
              className={cn("awardit-missingDataAdditionalText", styles.missingDataAdditionalText)}
              dangerouslySetInnerHTML={{ __html: leaderboardview.missingDataAdditionalText }}
            />
            {/* eslint-enable react/no-danger */}
          </>
        )}
        {(leaderboard.state === "UPDATING" || leaderboard.state === "LOADING") &&
        <>
          <TableSmallHint />
          <TableLargeHint />
        </>
        }
        {leaderboard.state === "LOADED" &&
        <TableLarge
          items={itemsToShow}
          hightlightedItemsLimit={leaderboardCount}
          missingDataMessage={missingDataMessage ?? null}
          customerId={leaderboardUser && leaderboardUser.name}
          flags={leaderboard.data.flags}
        />
        }
        {leaderboard.state === "LOADED" &&
        <TableSmall
          items={itemsToShow}
          current={current}
          flags={leaderboard.data.flags}
          missingDataMessage={missingDataMessage ?? null}
          onClick={x => setCurrent(current !== x.name ? x.name : null)}
        />
        }
        <div className={cn(styles.paginationContainer, "awardit-leaderboardPagination")}>
          {pagesCount > 1 && itemsToShow.length !== 1 &&
          <Pagination
            className={styles.pagination}
            currentPage={1}
            pageCount={pagesCount}
            ButtonComponent={PaginationButton}
          />
          }
        </div>
      </div>
    </Wrapper>
  );
};

export default LeaderboardView;
