/* @flow */

import type { FormData } from "@awardit/formaggio/src/types";
import type { Customer } from "shop-state/types";

import React, { useState, useContext } from "react";
import cn from "classnames";
import { Form, rules, isEmail, isPhone, isRequired, isNumeric } from "@awardit/formaggio";
import { saveBillingAddress } from "@crossroads/shop-state/customer";
import { Foldable } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import { useSendMessage, useData } from "crustate/react";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import AccountInfo from "components/AccountView/AccountInfo";
import { updateCustomerEmail, updateCustomer } from "queries";
import { addMessage } from "state/messages";
import { formatDate } from "helpers/utils";
import { createArrayFromObj, formatAccountFormKey } from "helpers/forms";
import { AccountFormConfigData, DistrictListData } from "data";
import WarningIcon from "icons/warning.svg";
import CogIcon from "icons/cog.svg";
import SaveIcon from "icons/save.svg";

import styles from "components/AccountView/styles.scss";

type ValidationError = {
  error: string,
  field: string,
};

type PromiseTypes = {
  updateCustomerEmail: {
    result: string,
  },
} | {
  updateCustomer: {
    result: string,
  },
};

const matchRegex = (f: string, p: RegExp, msg?: string) => (t: FormData): Array<ValidationError> =>
  typeof t[f] === "string" && p.test(t[f]) ? [] : [{ error: msg ?? "MATCH_PATTERN", field: f }];

const UserDetails =
({ customer: { memberPoints, ...customer } }: { customer: Customer }): React$Node => {
  const client = useClient();
  const t = useTranslate();
  const {
    content: {
      accountview,
      checkoutview,
      address: { showCompany, showOrg },
    },
  } = useContext(StoreInfoContext);
  const [open, setOpen] = useState(false);
  const billingAdress = customer.addresses.length > 0 ? customer.addresses[0] : null;
  const formConfig = useData(AccountFormConfigData);
  const districtListData = useData(DistrictListData);
  const formArray = formConfig.state === "LOADED" ? createArrayFromObj(formConfig.data) : [];
  const districtListArr = districtListData.state === "LOADED" ? districtListData.data.getDistrictList : null;
  const rulesArray = [];

  formArray.forEach(i => {
    const key = formatAccountFormKey(i.key);

    if (i.req === "required" && i.show !== false) {
      rulesArray.push(isRequired(key));
    }

    if (i.key === "email") {
      rulesArray.push(isEmail(key));
    }

    if (i.key === "cell") {
      if (i.pattern === null || i.pattern.length <= 0) {
        rulesArray.push(isPhone(key));
      }
      else {
        rulesArray.push(
          matchRegex(key, new RegExp(i.pattern), "PHONE")
        );
      }
    }

    if (i.pattern !== null && i.pattern.length > 0 && i.key !== "cell") {
      rulesArray.push(matchRegex(key, new RegExp(i.pattern)));
    }

    if (i.digits === true) {
      rulesArray.push(isNumeric(key));
    }
  });

  const validation = rules(rulesArray);

  const [state, setState] = useState<FormData>({
    memberuserid: customer.awarditMemberId ?? "",
    firstname: customer.firstname,
    lastname: customer.lastname,
    email: customer.email ?? "",
    cellphonenum: billingAdress?.telephone ?? "",
    ssn: customer.awarditSsn,
    dob: customer.dob ? formatDate(customer.dob) : "",
    gender: customer.gender ?? "",
    street: billingAdress?.street[0] ?? "",
    zip: billingAdress?.postcode ?? "",
    city: billingAdress?.city ?? "",
    country: billingAdress ? billingAdress.country.code : "SE",
    company: billingAdress?.company ?? "",
    orgnr: billingAdress?.awarditOrgNr ?? "",
    retailer: customer.awarditRetailer,
    district: customer.awarditDistrict,
    totmemberinhouse: String(customer.awardit.numberOfEmployees),
    careof: customer.awarditCustomerExtra,
    care2: customer.awarditCustomerExtra2,
  });

  const [saving, setSaving] = useState(false);
  const sendMessage = useSendMessage();
  const errors = validation(state);

  const submit = async e => {
    e.preventDefault();

    if (saving) {
      return;
    }

    setSaving(true);

    const requests: Array<Promise<PromiseTypes>> = [];

    try {
      const customerParam: { [key: string]: string } = {};

      if (state.dob && typeof state.dob === "string") {
        customerParam.dob = state.dob;
      }

      if (state.gender && typeof state.gender === "string") {
        customerParam.gender = state.gender;
      }

      if (state.retailer && typeof state.retailer === "string") {
        customerParam.awarditRetailer = state.retailer;
      }

      if (state.district && typeof state.district === "string") {
        customerParam.awarditDistrict = state.district;
      }

      if (state.totmemberinhouse && typeof state.totmemberinhouse === "string") {
        customerParam.awarditTotmemberinhouse = state.totmemberinhouse;
      }

      if (state.careof && typeof state.careof === "string") {
        customerParam.awarditCustomerExtra = state.careof;
      }

      if (state.care2 && typeof state.care2 === "string") {
        customerParam.awarditCustomerExtra2 = state.care2;
      }

      if (state.ssn !== undefined && typeof state.ssn === "string") {
        customerParam.awarditSsn = state.ssn;
      }

      if (Object.keys(customerParam).length > 0) {
        requests.push(client(updateCustomer, { customer: customerParam }));
      }

      if (state.email) {
        requests.push(client(updateCustomerEmail, { email: state.email.toString() }));
      }

      await Promise.all(requests);

      await sendMessage(saveBillingAddress({
        id: billingAdress?.id,
        firstname: state.firstname ? state.firstname.toString() : "",
        lastname: state.lastname ? state.lastname.toString() : "",
        street: state.street ? [state.street.toString()] : [],
        city: state.city ? state.city.toString() : "",
        postcode: state.zip ? state.zip.toString() : "",
        telephone: state.cellphonenum ? state.cellphonenum.toString() : "",
        countryCode: state.country ? state.country.toString() : "",
        company: state.company ? state.company.toString() : "",
        awarditOrgNr: state.orgnr ? state.orgnr.toString() : "",
      }));
    }
    catch {
      sendMessage(addMessage("UNKNOWN", "error"));
    }
    finally {
      setSaving(false);
      setOpen(false);
    }
  };

  const slabImage = (
    memberPoints &&
    memberPoints.slabImage) ? memberPoints.slabImage : null;

  return (
    <div className={cn(styles.userDetails, { [styles.saving]: saving })}>
      <Form
        errors={errors}
        value={state}
        onChange={x => setState({ ...state, ...x })}
        onSubmit={submit}
      >
        <header className={styles.boxHeader}>
          <h2 className={styles.boxHeading}>
            {accountview.userDetailsHeading ?? ""}
            {errors.length > 0 && <WarningIcon className={styles.warningIcon} />}
          </h2>
          {!open && checkoutview.edit ? (
            <button
              type="button"
              onClick={e => {
                e.preventDefault();
                setOpen(true);
              }}
            >
              <span>{checkoutview.edit}</span>
              <CogIcon />
            </button>
          ) : (
            <button type="submit" disabled={saving} className={styles.saveButton}>
              <span>{saving ? accountview.saving ?? "" : accountview.saveAndClose ?? ""}</span>
              <SaveIcon />
            </button>
          )}
        </header>
        <section className={styles.boxBody}>
          <Foldable open={!open}>
            {memberPoints &&
              <p className="awardit-accountViewMemberPoints">
                {memberPoints.slabImage &&
                  <img className={cn(styles.slabImage, "awardit-accountViewSlabImage")} src={slabImage} />
                }
                {memberPoints.slab && <strong>{memberPoints.slab}</strong>}
              </p>
            }
            <strong>{`${customer.firstname} ${customer.lastname}`}</strong>
            {billingAdress && (
              <>
                {(Boolean(showCompany) || Boolean(showOrg)) && (
                  <p>
                    {Boolean(showCompany) && typeof state.company === "string" ? state.company : null}
                    <br />
                    {Boolean(showOrg) && typeof state.orgnr === "string" ? state.orgnr : null}
                  </p>
                )}
                <p>
                  {typeof state.street === "string" ? state.street : null}
                  <br />
                  {`${state.zip.toString() || ""} ${state.city.toString() || ""}`}
                </p>
                <p>
                  {typeof state.email === "string" ? state.email : null}
                  <br />
                  {!state.cellphonenum && (
                    <span className={styles.warning}>{t("ACCOUNT.NO_PHONE_NUMBER")}</span>
                  )}
                  {typeof state.cellphonenum === "string" ? state.cellphonenum : null}
                </p>
              </>
            )}
          </Foldable>
          <Foldable open={open}>
            {formConfig.state === "LOADED" &&
              <AccountInfo
                className={styles.accountForm}
                formFields={formArray}
                gender={String(state.gender)}
                districtList={districtListArr}
                district={state.district}
                country={state.country}
                retailer={state.retailer}
                setValue={value => {
                  setState({ ...state, ...value });
                }}
              />
            }
          </Foldable>
        </section>
      </Form>
    </div>
  );
};

export default UserDetails;
