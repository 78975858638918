/* @flow */

import type { QuestionnaireListItem as TQuestionnaireListItem } from "shop-state/types";
import type { Filter } from "state/questionnaire-list";

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useData } from "crustate/react";
import Wrapper from "components/Wrapper";
import ErrorView from "components/ErrorView";
import QuestionnaireListItem, { DummyQuestionnaireListItem } from "components/QuestionnaireListItem";
import { QuestionnaireListData } from "data";
import { useTranslate } from "@awardit/react-use-translate";
import { stutter } from "helpers/animation";
import { StoreInfoContext } from "entrypoint/shared";
import { Title } from "components/UiComponents";
import HeroIntro from "components/HeroIntro";

import styles from "./styles.scss";

const NUM_DUMMY_CARDS = 6;

const sortByStatus = (a: TQuestionnaireListItem, b: TQuestionnaireListItem) => {
  if (a.status === b.status) {
    return a.id - b.id;
  }

  if (a.status === "UNANSWERED" && b.status !== "UNANSWERED") {
    return -1;
  }

  if (a.status === "ANSWERED" && b.status === "CLOSED") {
    return -1;
  }

  return 1;
};

const QuestionnaireListView = ({ filter }: { filter?: Filter }): React$Node => {
  const {
    content: {
      questionnairelistview: content,
      heroIntroView,
    },
  } = useContext(StoreInfoContext);
  const t = useTranslate();
  const questionnaireListData = useData(QuestionnaireListData);
  const list = questionnaireListData.state === "LOADED" ? questionnaireListData.data : [];

  const sortedList = list.sort(sortByStatus);

  const _stutter = stutter(100, 100);

  const filterQuestionnaires = (x: TQuestionnaireListItem) => {
    switch (filter) {
      case "closed":
        return x.status === "CLOSED";
      case "answered":
        return x.status === "ANSWERED";
      case "open":
      default:
        return x.status !== "ANSWERED" && x.status !== "CLOSED";
    }
  };

  if (questionnaireListData.state === "ERROR" || !filter) {
    return <ErrorView />;
  }

  return (
    <>
      <Helmet
        title={content.pageTitle ?? ""}
      />
      {Boolean(heroIntroView.toggle) &&
        <HeroIntro
          title={content.heading && content.heading}
          description={content.introText && content.introText}
        />
      }
      <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
        {Boolean(heroIntroView.toggle) === false &&
        <>
          {content.heading &&
            <Title>{content.heading}</Title>
          }

          {/* eslint-disable react/no-danger */}
          {content.introText &&
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: content.introText }}
            />
          }
          {/* eslint-enable react/no-danger */}
        </>
        }
        <div className={styles.items}>
          {questionnaireListData.state === "LOADED" && sortedList
            .filter(filterQuestionnaires)
            .map((x, i) => (
              <div
                key={x.title + x.id}
                style={{
                  animationDelay: _stutter(i),
                }}
                className={styles.item}
              >
                <QuestionnaireListItem item={x} />
              </div>
            ))
          }

          {questionnaireListData.state === "LOADED" && sortedList
            .filter(filterQuestionnaires).length === 0 && (
            <div className={styles.no_news}>
              <p className={styles.description}>
                {t("QUESTIONNAIRE.EMPTY_LIST")}
              </p>
            </div>
          )}

          {questionnaireListData.state === "LOADING" &&
            [...new Array(NUM_DUMMY_CARDS)]
              .map((x, i) => (
                <div
                  key={"dummyNews_" + i}
                  style={{
                    animationDelay: _stutter(i),
                  }}
                  className={styles.item}
                >
                  <DummyQuestionnaireListItem />
                </div>
              ))
          }
        </div>
      </Wrapper>
    </>
  );
};

export default QuestionnaireListView;
