/* @flow */

import * as React from "react";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  className?: string,
  children: React.Node,
  elem?: string,
};

export const Title = ({ children, className = "", elem, ...props }: Props): React$Node => {
  const Elem = typeof elem === "undefined" ? "h1" : elem;

  return (
    <Elem {...props} className={cn(styles.title, className)}>
      {children}
    </Elem>
  );
};
