/* @flow */

import React from "react";
import cn from "classnames";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";

type Props = {
  className?: string,
  title?: ?string,
  content?: ?string,
  image?: ?string | null,
};

/* eslint-disable react/no-danger */

const AllProductsViewHero = ({ className, title = "", content = "", image, ...props }: Props): React$Node => {
  const defaultBG = "https://i.picsum.photos/id/122/1920/1080.jpg";
  const bg = image !== undefined ? image : defaultBG;

  return (
    <div
      {...props}
      className={cn(styles.block, className)}
      style={{
        backgroundImage: `url(${bg || ""})`,
      }}
    >

      <Wrapper>
        <div className={styles.container}>
          <h1 className={styles.title}>{title}</h1>
          {content &&
            <div
              className={cn("typography", styles.content)}
              dangerouslySetInnerHTML={{ __html: content }} />
          }
        </div>
      </Wrapper>
    </div>
  );
};

/* eslint-enable react/no-danger */

export default AllProductsViewHero;
