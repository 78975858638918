/* @flow */

import type { QuestionnaireQuestion } from "shop-state/types";

import React from "react";
import cn from "classnames";
import Button from "components/Button";
import { useTranslate } from "@awardit/react-use-translate";
import { Input, Radio, RadioGroup, Checkbox } from "@crossroads/ui-components";
import TextArea from "components/TextArea";

import styles from "./styles.scss";

type Props = {
  question: QuestionnaireQuestion,
  answers: Array<string>,
  setAnswer: Array<string> => void,
};

const Options = ({ question, answers, setAnswer }: Props): React$Node => {
  const t = useTranslate();

  switch (question.type) {
    case "QuestionnaireOptionsList":
      return (
        <div>
          {question.options.map(q => (
            <Button
              key={q}
              className={cn(
                styles.listoption,
                { [styles.listoption__active]: answers.length > 0 && answers[0] === q }
              )}
              onClick={() => setAnswer([q])}
            >
              {q}
            </Button>
          ))}
        </div>
      );
    case "QuestionnaireOptionsRadio":
      return (
        <RadioGroup
          value={answers.length > 0 ? answers[0] : ""}
          onChange={e => setAnswer([e.currentTarget.value])}
        >
          {question.options.map(q => <Radio key={q} className={styles.radio} value={q}>{q}</Radio>)}
        </RadioGroup>
      );
    case "QuestionnaireOptionsCheckbox":
      return (
        <div>
          {question.options.map(q => {
            const checked = answers.find(a => a === q) !== undefined;

            return (
              <Checkbox
                key={q}
                className={styles.checkbox}
                name={q}
                value={checked}
                onChange={() => {
                  if (checked) {
                    setAnswer(answers.filter(x => x !== q));
                  }
                  else {
                    setAnswer([...answers, q]);
                  }
                }}
              >
                {q}
              </Checkbox>
            );
          })}
        </div>
      );
    case "QuestionnaireOptionsText":
      return (
        <Input
          label={t("QUIZ.INPUT_LABEL")}
          value={answers.length > 0 ? answers[0] : ""}
          onChange={e => setAnswer([e.target.value])}
        />
      );
    case "QuestionnaireOptionsTextarea":
      return (
        <TextArea
          label={t("QUIZ.INPUT_LABEL")}
          value={answers.length > 0 ? answers[0] : ""}
          onChange={e => setAnswer([e.target.value])}
        />
      );
    default:
      break;
  }

  return null;
};

export default Options;
