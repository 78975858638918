/* @flow */

import React, { useContext, useState, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useData, useSendMessage } from "crustate/react";
import { Helmet } from "react-helmet-async";
import { getCurrentPageInfo } from "state/current-page-info";
import { HomeData, AffiliateListSelectedData, CustomerData, CurrentPageInfoData, ViewModeData } from "data";
import ProductCard from "components/ProductCard";
import ProductCarousel from "components/ProductCarousel";
import Wrapper from "components/Wrapper";
import PopularCategoryCarousel from "components/PopularCategoryCarousel";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import AffiliateList, { AffiliateDummyList } from "components/AffiliateList";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import HomeHero from "components/HomeHero";
import { Title, Description, Ingress, Item } from "components/UiComponents";
import InfoCard from "components/CurrentPageInfo/InfoCard";
import UploadImageSection from "components/HomeView/UploadImageSection";
import {
  getCustomerData,
  roundDownToMultiple,
  shuffleArray,
  getNumberBasedOnBrowserWidth,
  tryParseJSONObject,
} from "helpers/utils";
import useNotifyMissingAccountDetails from "helpers/use-notify-missing-account-details";
import { useBrowser } from "@awardit/react-use-browser";
import Agreement from "components/Agreement";
import Carousel from "components/Carousel";
import { logout } from "@crossroads/shop-state/customer";
import { awarditAgreementAgree } from "queries";
import { MODE } from "state/view-mode";

import styles from "./styles.scss";

const HomeView = (): React$Node => {
  const {
    routes,
    content: {
      homeview: content,
      productCarousel: { useOnHomeView },
      allproductsview: { popularCategories, popularCategoriesTitle },
    },
    configuration,
  } = useContext(StoreInfoContext);
  const home = useData(HomeData);
  const viewMode = useData(ViewModeData);
  const affiliateList = useData(AffiliateListSelectedData);
  const { width: browserWidth } = useBrowserDimensions();
  const sendMessage = useSendMessage();
  const isDesktop = browserWidth > 800;
  const customer = getCustomerData(useData(CustomerData));
  const [showTerms, setShowTerms] = useState(false);
  const isBrowser = useBrowser();
  const client = useClient();
  const currentPageInfoData = useData(CurrentPageInfoData);
  const numAffiliates = getNumberBasedOnBrowserWidth(
    styles,
    { small: 4, medium: 6 },
    8,
    browserWidth
  );
  const memberTargetList = customer &&
    customer.memberTargetList &&
    customer.memberTargetList.list.length > 0 ?
    customer.memberTargetList.list :
    [];
  const cookieConsentIsVisible = viewMode === MODE.COOKIE_CONSENT;

  useEffect(() => {
    if (isBrowser &&
      configuration.showTermsOnFirstLogin === true &&
      customer &&
      customer.awardit.agreementAccepted === false) {
      setShowTerms(true);
    }
  }, [isBrowser, customer, configuration]);

  const postAgreement = async () => {
    await client(awarditAgreementAgree);
  };

  useEffect(() => {
    sendMessage(getCurrentPageInfo("HOMEVIEW"));
    sendMessage(getCurrentPageInfo("CURRENTINFO6"));
  }, []);

  useNotifyMissingAccountDetails();

  const currentInfoOrder = content.currentInfoOrder ?
    Number.parseInt(content.currentInfoOrder, 10) :
    1;
  const earnOnlineOrder = content.earnOnlineOrder ?
    Number.parseInt(content.earnOnlineOrder, 10) :
    2;
  const howToEarnOrder = content.howToEarnOrder ?
    Number.parseInt(content.howToEarnOrder, 10) :
    3;
  const featuredProductsOrder = content.featuredProductsOrder ?
    Number.parseInt(content.featuredProductsOrder, 10) :
    4;
  const additionalHtmlOrder = content.additionalHtmlOrder ?
    Number.parseInt(content.additionalHtmlOrder, 10) :
    5;
  const additionalHtmlSecondaryOrder = content.additionalHtmlSecondaryOrder ?
    Number.parseInt(content.additionalHtmlSecondaryOrder, 10) :
    6;
  const uploadImageOrder = content.uploadImageOrder ?
    Number.parseInt(content.uploadImageOrder, 10) :
    7;
  const currentInfo6Order = content.currentInfo6Order ?
    Number.parseInt(content.currentInfo6Order, 10) :
    -1;
  const popularProductsOrder = content.popularProductsOrder ?
    Number.parseInt(content.popularProductsOrder, 10) :
    -1;
  const popularCategoriesOrder = content.popularCategoriesOrder ?
    Number.parseInt(content.popularCategoriesOrder, 10) :
    -1;
  const featuredProducts = home.state !== "LOADED" ? [...new Array(4)] :
    shuffleArray(home.data.featuredProducts.filter(p => !memberTargetList ||
      memberTargetList.includes(p.attributes.awarditTargetId) ||
      !p.attributes.awarditTargetId))
      .slice(0, roundDownToMultiple(home.data.featuredProducts.length, 4));

  const affiliateItems = (affiliateList.state === "LOADED" ? affiliateList.data : []).slice(0, numAffiliates);

  const popularCategoriesJson = popularCategories !== undefined &&
    tryParseJSONObject(popularCategories) ? JSON.parse(popularCategories) : "";

  const currentInfo6Count = content.currentInfo6Count ?
    Number.parseInt(content.currentInfo6Count, 10) : 0;
  const currentInfo6Array = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.currentinfo6 &&
    currentPageInfoData.data.currentinfo6.length > 0 ?
    (currentInfo6Count > 0 ?
      currentPageInfoData.data.currentinfo6.slice(0, currentInfo6Count) :
      currentPageInfoData.data.currentinfo6) : [];

  const howToEarnSection = (
    <div key="howToEarn" className={cn("awardit-homeViewPromotionSection", styles.promotion)}>
      <div className={cn(styles.left, !content.howToEarnImage ? styles.empty : "")}>
        {content.howToEarnImage &&
          <img src={content.howToEarnImage} />
        }
      </div>
      <div className={cn("awardit-homeViewPromotionTexts", styles.right)}>
        {content.howToEarnHeading &&
          <Title elem="h2">{content.howToEarnHeading}</Title>
        }
        {content.howToEarnDescription &&
          <Description content={content.howToEarnDescription} />
        }
        {content.howToEarnButtonLink && content.howToEarnButtonText &&
          <Link
            to={content.howToEarnButtonLink}
            className={cn("link", styles.ctaLarge)}
          >
            {content.howToEarnButtonText}
          </Link>
        }
      </div>
    </div>
  );

  const earnOnlineSection = (
    <div key="earnOnline" className={cn("awardit-homeViewAffiliateSection", styles.section)}>
      <div className="awardit-homeViewAffiliaterSectionIngress">
        {content.earnOnlineHeading &&
          <Title elem="h2">{content.earnOnlineHeading}</Title>
        }
        {content.earnOnlineDescription &&
          <Description content={content.earnOnlineDescription} />
        }
      </div>

      {affiliateList.state === "LOADED" &&
        <AffiliateList items={affiliateItems} />
      }

      {affiliateList.state === "LOADING" &&
        <AffiliateDummyList items={Array.from({
          length: numAffiliates,
        }, () => null)} />
      }

      {content.earnOnlineButtonLink && content.earnOnlineButtonText &&
        <div className={cn("awardit-homeViewAffiliateCta", styles.earnOnline)}>
          {content.earnOnlineButtonLink && (
            <Link
              to={content.earnOnlineButtonLink}
              className={cn("link", styles.cta)}
            >
              {content.earnOnlineButtonText}
            </Link>
          )}
        </div>
      }
    </div>
  );

  const featuredProductsSection = (
    featuredProducts &&
    featuredProducts.length >= 4 &&
    <div key="featuredProducts" className={cn("awardit-homeViewProductSection", styles.section)}>
      <Ingress className="awardit-homeViewProductSectionHeader">
        {content.featuredProductsHeading &&
          <Title elem="h2">{content.featuredProductsHeading}</Title>
        }
        {content.featuredProductsDescription &&
          <Description content={content.featuredProductsDescription} />
        }
      </Ingress>
      {browserWidth > 0 && home.state === "LOADED" ? (
        <Carousel
          autoplay
          className={cn(styles.carousel, "awardit-homeViewProductCardsContainer")}
          items={featuredProducts.map((p, i) => (
            <Item key={p.name} className="awardit-homeViewProductCard">
              <ProductCard product={p} list={content.featuredProductsHeading ?? ""} position={i} />
            </Item>
          ))}
          slidesToScroll={isDesktop ? 4 : 2}
          slidesToShow={isDesktop ? 4 : 2}
          timer={5000}
        />
      ) : (
        null
      )}
      {content.featuredProductsButtonLink && content.featuredProductsButtonText &&
        <div className={cn("awardit-homeViewProductSectionCta", styles.featuredCTA)}>
          {content.featuredProductsButtonLink && (
            <Link
              to={content.featuredProductsButtonLink}
              className={cn("link", styles.cta)}
            >
              {content.featuredProductsButtonText}
            </Link>
          )}
        </div>
      }
    </div>
  );

  const currentPageInfo = (
    currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.homeview &&
    currentPageInfoData.data.homeview.length > 0 &&
    <div className="awardit-homeViewCurrentPageInfoSection">
      {content.currentInfoTitle &&
      <Title elem="h2">{content.currentInfoTitle}</Title>}
      <div className={cn("awardit-homeViewCurrentPageInfoCardsContainer", styles.currentPageInfo)}>
        {currentPageInfoData.data.homeview?.map((info, i) => <InfoCard key={"offerlist_item_" + i} className={cn("awardit-currentPageInfoItem", styles.currentPageInfoItem)} info={info} idx={i} />)}
      </div>
    </div>
  );

  const additionalHtmlSection = (
    content.additionalHtml && (
      <div className={styles.additionalHtmlContainer}>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content.additionalHtml }} />
        {/* eslint-enable react/no-danger */}
      </div>
    )
  );

  const additionalHtmlSecondarySection = (
    content.additionalHtmlSecondary && (
      <div className={styles.additionalHtmlContainer}>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content.additionalHtmlSecondary }} />
        {/* eslint-enable react/no-danger */}
      </div>
    )
  );

  const currentInfo6Section = (
    currentInfo6Array &&
    currentInfo6Array.length > 0 &&
    routes.currentInfoView6?.url && (
      <div className="awardit-currenInfoCardsWrapper">
        {content.currentInfo6Title &&
          <Title className={styles.title} elem="h2">{content.currentInfo6Title}</Title>
        }
        <div className={styles.currenInfoCardsSection}>
          {currentInfo6Array.map((info, i) => (
          /* eslint-disable react/no-danger */
            <div key={"currentinfo6_item_" + i} className={styles.currenInfoCardsSectionCardWrapper} idx={i}>
              <Link
                to={{
                  pathname: `${(routes.currentInfoView6 && routes.currentInfoView6.url) ?? ""}/${info.id}`,
                  state: { hint: { title: info.title } },
                }}
                className={cn(styles.currentInfoCard, "awardit-currenInfoCard")}
              >
                {info.title && <h3>{info.title}</h3>}
                {info.image &&
                  <div className={styles.currenInfoCardImage}>
                    <img src={info.image} />
                  </div>
                }
                {info.content &&
                  <div
                    className={cn(styles.currentInfoDescription, "awardit-currentInfoDescription")}
                    dangerouslySetInnerHTML={{ __html: info.description }}
                  />
                }
              </Link>
            </div>
          /* eslint-enable react/no-danger */
          ))}
        </div>
      </div>
    )
  );

  const popularProductsSection = (
    Boolean(useOnHomeView) && (
      <Wrapper className={styles.popularProducts}>
        <ProductCarousel />
      </Wrapper>
    )
  );

  const popularCategoriesSection = (
    <Wrapper className={styles.popularCategories}>
      {popularCategoriesJson &&
        <PopularCategoryCarousel
          title={popularCategoriesTitle ?? ""}
          popularCategories={popularCategoriesJson}
        />
      }
    </Wrapper>
  );

  const main = [];

  if (earnOnlineOrder >= 0) {
    main[earnOnlineOrder] = earnOnlineSection;
  }

  if (howToEarnOrder >= 0) {
    main[howToEarnOrder] = howToEarnSection;
  }

  if (featuredProductsOrder >= 0) {
    main[featuredProductsOrder] = featuredProductsSection;
  }

  if (currentInfoOrder >= 0) {
    main[currentInfoOrder] = currentPageInfo;
  }

  if (additionalHtmlOrder >= 0) {
    main[additionalHtmlOrder] = additionalHtmlSection;
  }

  if (additionalHtmlSecondaryOrder >= 0) {
    main[additionalHtmlSecondaryOrder] = additionalHtmlSecondarySection;
  }

  if (uploadImageOrder >= 0) {
    main[uploadImageOrder] = <UploadImageSection />;
  }

  if (currentInfo6Order >= 0) {
    main[currentInfo6Order] = currentInfo6Section;
  }

  if (popularProductsOrder >= 0) {
    main[popularProductsOrder] = popularProductsSection;
  }

  if (popularCategoriesOrder >= 0) {
    main[popularCategoriesOrder] = popularCategoriesSection;
  }

  return (
    <div className={cn("awardit-homeView", styles.block)}>
      <Helmet title={content.pageTitle ?? ""} />
      <HomeHero />
      <Wrapper className={cn("awardit-homeViewWrapper", styles.wrapper)}>
        {main}
      </Wrapper>

      {showTerms && !cookieConsentIsVisible &&
        <Agreement
          agreementModalOpen={showTerms}
          setAgreementModalOpen={open => {
            if (open === true) {
              sendMessage(logout());
            }

            if (open === false) {
              postAgreement();
              setShowTerms(open);
            }
          }}
        />
      }
    </div>
  );
};

export default HomeView;
