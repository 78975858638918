/* @flow */

import type { Transaction } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import { StoreInfoContext } from "entrypoint/shared";
import { timestampToDateString } from "helpers/locale-utils";
import { Foldable, Button } from "@crossroads/ui-components";
import Points from "components/AccountView/TransactionsView/Points";
import Spinner from "components/Spinner";
import ChevronIcon from "icons/chevron-mini.svg";
import ClipIcon from "icons/clip.svg";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

type TableSmallProps = {
  items: Array<Transaction>,
  setCurrentReceipt: string => void,
  onClick: Transaction => void,
  current: string | null,
};

const TableSmallHead = () => {
  const { content: { transactionsview } } = useContext(StoreInfoContext);

  return (
    <div className={styles.thead}>
      <div className={styles.th}>{transactionsview.tableTransaction}</div>
      {Boolean(!transactionsview.hidePoints) &&
        <div className={styles.th}>{transactionsview.tablePoints}</div>
      }
      {Boolean(transactionsview.showRankpts) &&
        <div className={styles.th}>{transactionsview.tableRankpts}</div>
      }
      <div className={styles.th_spacer} />
    </div>
  );
};

const TableSmall =
({ items, setCurrentReceipt, onClick, current }: TableSmallProps): React$Node => {
  const { content: { transactionsview }, info: { code } } = useContext(StoreInfoContext);
  const t = useTranslate();

  // PartnerID's for Release Points & Michelin
  const partnerIdArray = ["releasefinans_sek", "michelin_nok", "michelin_sek", "michelin_dkk", "michelin_fin"].includes(code);
  return (
    <div className={cn(styles.table, styles.tableSmall)}>
      <TableSmallHead />
      <div className={styles.tbody}>
        {items.map((x, i) => (
          <React.Fragment key={`${x.id}-${i}`}>
            <div
              className={cn(styles.tr, { [styles.open]: current === x.id })}
              onClick={() => {
                onClick(x);
              }}
            >
              {/* eslint-disable react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: x.type === 471 ? `${t("TRANSACTIONS.REFUND")} ${x.description}` : x.description }} />
              {/* eslint-enable react/no-danger */}
              {Boolean(!transactionsview.hidePoints) && <div className={cn({ [styles.negative]: x.flag === "DOWN" })}>{x.points}</div>}
              {Boolean(transactionsview.showRankpts) && <div className={cn({ [styles.negative]: x.flag === "DOWN" })}>{x.rank}</div>}
              <div className={styles.chevron}>
                <ChevronIcon />
              </div>
            </div>
            <Foldable open={current === x.id} className={styles.expandable}>
              <table>
                <tbody>
                  <tr>
                    <td>{transactionsview.tableId}</td>
                    <td>{x.id}</td>
                  </tr>
                  <tr>
                    <td>{transactionsview.tableTransaction}</td>
                    {/* eslint-disable react/no-danger */}
                    <td dangerouslySetInnerHTML={{ __html: x.type === 471 ? `${t("TRANSACTIONS.REFUND")} ${x.description}` : x.description }} />
                    {/* eslint-enable react/no-danger */}
                  </tr>
                  {Boolean(!transactionsview.hideTransactionSource) &&
                    <tr>
                      <td>{transactionsview.tableClub}</td>
                      <td>{x.club}</td>
                    </tr>
                  }
                  {Boolean(!transactionsview.hidePoints) &&
                    <tr>
                      <td>{transactionsview.tablePoints}</td>
                      <td className={cn({ [styles.negative]: x.flag === "DOWN" })}>
                        <Points points={x.points} />
                      </td>
                    </tr>
                  }
                  {Boolean(transactionsview.showRankpts) &&
                    <tr>
                      <td>{transactionsview.tableRankpts}</td>
                      <td className={cn({ [styles.negative]: x.flag === "DOWN" })}>
                        <Points points={x.rank} />
                      </td>
                    </tr>
                  }
                  <tr>
                    <td>{transactionsview.tableDate}</td>
                    <td>{timestampToDateString(x.createdAt)}</td>
                  </tr>
                  {Boolean(!transactionsview.hidePointsExpires) &&
                    <tr>
                      <td>{transactionsview.tablePointsExpires}</td>
                      <td>{x.expiresAt !== null && timestampToDateString(x.expiresAt)}</td>
                    </tr>
                  }
                  {Boolean(!transactionsview.hideReceipt) &&
                    <tr>
                      <td>{transactionsview.tableReceipt}</td>
                      <td>
                        {((x.flag === "DEBIT" && x.type !== 468 && x.type !== 470) || (partnerIdArray && x.flag === "UP" && x.type === 464)) && (
                          <Button
                            className={styles.receiptLink}
                            onClick={() => setCurrentReceipt(x.id)}
                          >
                            <ClipIcon />
                            <span>{transactionsview.receipt}</span>
                          </Button>
                        )}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </Foldable>
          </React.Fragment>
        ))}
      </div>
      <div className={styles.spacer} />
    </div>
  );
};

export const TableSmallHint = (): React$Node => (
  <div className={cn(styles.tableHint, styles.tableHintSmall)}>
    <Spinner />
  </div>
);

export default TableSmall;
