/* @flow */

import React, { useContext, useState, useEffect } from "react";

import Wrapper from "components/Wrapper";
import { useTranslate } from "@awardit/react-use-translate";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import { Input, Dropdown, DropdownItem } from "@crossroads/ui-components";
import { Title } from "components/UiComponents";

import styles from "./styles.scss";

const CalculatorView = (): React$Node => {
  const t = useTranslate();
  const [contractCount, setContractCount] = useState(1);
  const [financeValue, setFinanceValue] = useState(0);
  const [result, setResult] = useState();
  const {
    routes: { calculatorView: calculatorViewRoute },
    content: { calculatorView },
  } = useContext(StoreInfoContext);
  const dropdownValues = [...new Array(21).keys()];
  dropdownValues.shift();

  const calculate = () => {
    const contractVal = 400 * contractCount;
    const currencyValue = financeValue * 0.085;
    const value = currencyValue + contractVal;
    setResult(Math.round(value));
  };

  useEffect(() => {
    if (financeValue > 0) {
      calculate();
    }
  }, [contractCount, financeValue]);

  return (
    <div className={styles.block}>
      <Wrapper className={styles.wrapper}>
        <Helmet
          title={calculatorViewRoute?.title}
        />
        <div>
          {calculatorView.heading &&
            <Title elem="h1" className={styles.title}>{calculatorView.heading}</Title>
          }
          {calculatorView.introText &&
            <p className={styles.description}>{calculatorView.introText}</p>
          }
        </div>
        <div>
          <Input
            className={styles.currencyInput}
            type="number"
            onChange={e => {
              if (e.target.value.length === 0 || e.target.value >= 0) {
                setResult(0);
              }

              const val = e.target.value;
              setFinanceValue(parseInt(val, 10));
            }
            } />
          <Dropdown
            className={styles.dropdown}
            value={contractCount}
            onChange={value => {
              setContractCount(parseInt(value, 10));
            }}
          >
            {dropdownValues.map(num => (
              <DropdownItem key={num} value={num}>
                {num}
              </DropdownItem>
            ))}
          </Dropdown>
        </div>
        <Input
          disabled
          value={result}
          placeholder={t("CALCULATOR.PLACEHOLDER")}
          className={styles.disabledInput}
        />
      </Wrapper>
    </div>
  );
};

export default CalculatorView;
