/* @flow */

import { useEffect, useState } from "react";
import { useBrowser } from "@awardit/react-use-browser";
import { throttle } from "diskho";

const useBrowserDimensions = (): {|
  height: any | number,
  width: any | number,
|} => {
  const isBrowser = useBrowser();
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);
  const [height, setHeight] = useState(isBrowser ? window.innerHeight : 0);

  useEffect(() => {
    if (isBrowser) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, [isBrowser]);

  useEffect(() => {
    if (isBrowser) {
      const handleResize = throttle(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }, 1000);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [width, height, isBrowser]);

  return {
    width,
    height,
  };
};

export default useBrowserDimensions;
