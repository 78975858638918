/* @flow */

import type { RegFormConfig } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

type RegisterFormConfigData =
| { state: "LOADING", data: ?RegFormConfig }
| { state: "LOADED", data: RegFormConfig }
| { state: "ERROR", error: mixed };

type AccountFormConfigData =
| { state: "LOADING", data: ?RegFormConfig }
| { state: "LOADED", data: RegFormConfig }
| { state: "ERROR", error: mixed };

export type RegisterFormConfigResponse =
    Response<typeof REGISTER_FORM_CONFIG_RESPONSE, ?RegFormConfig>;

export type RegisterFormConfigRequest = {
  tag: typeof REGISTER_FORM_CONFIG_REQUEST,
  partner: number | null,
};
export type AccountFormConfigResponse =
    Response<typeof ACCOUNT_FORM_CONFIG_RESPONSE, ?RegFormConfig>;

export type AccountFormConfigRequest = {
  tag: typeof ACCOUNT_FORM_CONFIG_REQUEST,
  partner: number | null,
};

export const REGISTER_FORM_CONFIG_RESPONSE: "response/register_form_config" = "response/register_form_config";
export const REGISTER_FORM_CONFIG_REQUEST: "request/register_form_config" = "request/register_form_config";

export const ACCOUNT_FORM_CONFIG_RESPONSE: "response/account_form_config" = "response/account_form_config";
export const ACCOUNT_FORM_CONFIG_REQUEST: "request/account_form_config" = "request/account_form_config";

export const getRegisterFormConfig = (partner: number | null): RegisterFormConfigRequest => {
  return ({
    tag: REGISTER_FORM_CONFIG_REQUEST,
    partner,
  });
};

export const getAccountFormConfig = (partner: number | null): AccountFormConfigRequest => {
  return ({
    tag: ACCOUNT_FORM_CONFIG_REQUEST,
    partner,
  });
};

export const RegisterFormConfigModel: Model<
  RegisterFormConfigData,
  { partner: number },
  RegisterFormConfigResponse |
  RegisterFormConfigRequest
> = {
  id: "register_form_config",
  init: ({ partner }) =>
    updateData(
      { state: "LOADING", data: null },
      getRegisterFormConfig(partner)
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });
      case REGISTER_FORM_CONFIG_REQUEST:
        return updateData({ state: "LOADING", data: state.state !== "ERROR" ? state.data : null });
      case REGISTER_FORM_CONFIG_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};

export const AccountFormConfigModel: Model<
  AccountFormConfigData,
  { partner: number },
  AccountFormConfigResponse |
  AccountFormConfigRequest
> = {
  id: "account_form_config",
  init: ({ partner }) =>
    updateData(
      { state: "LOADING", data: null },
      getAccountFormConfig(partner)
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });
      case ACCOUNT_FORM_CONFIG_REQUEST:
        return updateData({ state: "LOADING", data: state.state !== "ERROR" ? state.data : null });
      case ACCOUNT_FORM_CONFIG_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
