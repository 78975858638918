/* @flow */

import type { FormData } from "@awardit/formaggio/src/types";
import type { QuizItem, QuizAnswerItem } from "shop-state/types";

import React from "react";
import cn from "classnames";

import { Form } from "@awardit/formaggio";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import { useTranslate } from "@awardit/react-use-translate";

import Options from "../options";

import styles from "../styles.scss";

type SingleViewProps = {
  state: FormData,
  setState: (state: FormData) => void,
  quiz: Array<QuizItem>,
  answers: Array<QuizAnswerItem>,
  submitAnswers: (e: SyntheticEvent<HTMLFormElement>, value: FormData) => void,
  setOptionsAnswer: (x: Array<string>, id: number) => void,
  loading: boolean,
};

const SingleView = ({
  answers,
  submitAnswers,
  state,
  setState,
  quiz,
  setOptionsAnswer,
  loading }: SingleViewProps): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.block}>
      <Wrapper className={styles.wrapper}>
        <Form
          className={cn("awardit-quizForm")}
          value={state}
          onSubmit={(e, value) => submitAnswers(e, value)}
          onChange={x => {
            setState({ ...state, ...x });
          }}
        >
          {quiz.length > 0 ?
            quiz.map(item => (
              <div key={item.nr} className={styles.quizContainer}>
                {/* eslint-disable react/no-danger */}
                <div
                  dangerouslySetInnerHTML={{ __html: item.name }}
                  className={styles.question} />
                {/* eslint-enable react/no-danger */}
                <div className={styles.answers}>
                  <Options
                    question={item}
                    answers={answers || []}
                    setAnswer={x => setOptionsAnswer(x, item.id)}
                  />
                </div>
              </div>
            )) : null}
          <Button loading={loading} type="submit" variant="primary">
            {t(`QUIZ.SUBMIT`)}
          </Button>
        </Form>
      </Wrapper>
    </div>
  );
};

export default SingleView;
