/* @flow */

import type { Level, Message } from "shop-state/types";
import type { Model } from "crustate";
import { updateData } from "crustate";

export type Content =
  | string
  | {
      translationKey: string,
      variable: { [key: string]: ?string },
    };

type Data = Array<Message & { id: number }>;

export type AddMessage = { tag: typeof ADD_MESSAGE, message: Message };
export type AddMessageTranslated = { tag: typeof ADD_MESSAGE, message: Message };
export type RemoveMessage = { tag: typeof REMOVE_MESSAGE, id: number };
export type ClearMessages = { tag: typeof CLEAR_MESSAGES };

const maxId = (messages: Data) => messages.reduce((a, t) => Math.max(a, t.id), 0);

export const ADD_MESSAGE: "messages/add" = "messages/add";
export const REMOVE_MESSAGE: "messages/remove" = "messages/remove";
export const CLEAR_MESSAGES: "messages/clear" = "messages/clear";

export const addMessage = (content: Content, level: Level): AddMessage => ({
  tag: ADD_MESSAGE,
  message: { content, level, translated: false },
});

export const addMessageTranslated = (content: Content, level: Level): AddMessageTranslated => ({
  tag: ADD_MESSAGE,
  message: { content, level, translated: true },
});

export const removeMessage = (id: number): RemoveMessage => ({
  tag: REMOVE_MESSAGE,
  id,
});

export const clearMessages = (): ClearMessages => ({
  tag: CLEAR_MESSAGES,
});

export const MessagesModel: Model<Data, any, AddMessage | RemoveMessage | ClearMessages> = {
  id: "messages",
  init: () => {
    return updateData([]);
  },
  update: (state, msg) => {
    switch (msg.tag) {
      case ADD_MESSAGE:
        return updateData([
          ...state,
          {
            ...msg.message,
            id: maxId(state) + 1,
          },
        ]);
      case REMOVE_MESSAGE:
        return updateData(state.filter(x => x.id !== msg.id));
      case CLEAR_MESSAGES:
        return updateData([]);
      default:
        break;
    }
  },
};
