/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import { TransactionsData } from "data";
import Button from "components/Button";
import Points from "components/AccountView/TransactionsView/Points";
import ChevronIcon from "icons/chevron-mini.svg";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "components/AccountView/styles.scss";

const TransactionBody = () => {
  const { content: { transactionsview } } = useContext(StoreInfoContext);
  const transaction = useData(TransactionsData);
  const t = useTranslate();

  if (transaction.state !== "LOADED") {
    return null;
  }

  return (
    <section className={styles.boxBody}>
      <table className={styles.transactionsTable}>
        <thead>
          <tr>
            <th>{transactionsview.tableId}</th>
            <th>{transactionsview.tableTransaction}</th>
            {Boolean(!transactionsview.hidePoints) && <th>{transactionsview.tablePoints}</th>}
            {Boolean(transactionsview.showRankpts) && <th>{transactionsview.tableRankpts}</th>}
          </tr>
        </thead>
        <tbody>
          {transaction.data.slice(0, 5).map(x => (
            <tr key={x.id}>
              <td>{x.id}</td>
              {/* eslint-disable react/no-danger */}
              <td dangerouslySetInnerHTML={{ __html: x.type === 471 ? `${t("TRANSACTIONS.REFUND")} ${x.description}` : x.description }} />
              {/* eslint-enable react/no-danger */}
              {Boolean(!transactionsview.hidePoints) &&
                <td className={cn({ [styles.negative]: x.flag === "DOWN" })}>
                  <span className={styles.pointsWrapper}><Points points={x.points} /></span>
                </td>
              }
              {Boolean(transactionsview.showRankpts) &&
                <td className={cn({ [styles.negative]: x.flag === "DOWN" })}>
                  <span className={styles.pointsWrapper}><Points points={x.rank} /></span>
                </td>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

const LastOrders = (): React$Node => {
  const { content: { accountview }, routes } = useContext(StoreInfoContext);
  const transaction = useData(TransactionsData);

  if (transaction.state !== "LOADED") {
    return null;
  }

  return (
    <div>
      <header className={styles.boxHeader}>
        <h2 className={styles.boxHeading}>{accountview.latestTransactionsHeading}</h2>
        {routes.accountTransactionsView && routes.accountTransactionsView.url &&
          <Button to={routes.accountTransactionsView.url} type="button">
            <span>{accountview.open}</span>
            <ChevronIcon />
          </Button>
        }
      </header>
      <TransactionBody />
    </div>
  );
};

export const HeadlessLastOrders = (): React$Node => {
  const transaction = useData(TransactionsData);

  if (transaction.state !== "LOADED") {
    return null;
  }

  return <TransactionBody />;
};

export default LastOrders;
