/* @flow */

import React from "react";
import cn from "classnames";
import { Input } from "@crossroads/ui-components";
import SearchIcon from "icons/search.svg";
import ClearIcon from "icons/close-small.svg";

import styles from "./styles.scss";

type InputSearchProps = {
  className?: string,
  value: string,
  onChange: string => void,
  placeholderText: string,
  clearButtonAriaLabel: string,
};

const SearchInput = ({
  value,
  onChange,
  className,
  placeholderText,
  clearButtonAriaLabel }: InputSearchProps): React$Node => {
  return (
    <div className={cn(styles.searchInput, className)}>
      <Input
        wrapperClassName={cn(styles.searchInputInput, "awardit-SearchInput")}
        slotLeft={<SearchIcon className={styles.searchIcon} />}
        value={value}
        placeholder={placeholderText}
        onChange={e => onChange(e.target.value)}
      />
      {value &&
        <button
          className={styles.clear}
          aria-label={clearButtonAriaLabel}
          title={clearButtonAriaLabel}
          type="button"
          onClick={() => onChange("")}
        >
          <ClearIcon className={styles.clearIcon} />
        </button>
      }
    </div>
  );
};

export default SearchInput;
